import React from "react";
import { Button } from "../../../../components/Buttons";
import PinInput from "../../../../components/PinInput/PinInput";

export default class ChangePin extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      pin: null,
      valid: true,
      unselected: false,
    };
  }

  validate = () => {
    const { onComplete, pin: currentPin } = this.props;
    const { pin } = this.state;

    if(pin !== currentPin) {
      this.setState({ valid: false })
    }
    else {
      onComplete && onComplete();
    }
  }

  onPinComplete = (pin) => {
    this.setState({ pin, unselected: true }, () => (document.querySelector(".next-button") as HTMLElement).focus());
  }

  onPinIncomplete = () => {
    this.setState({ pin: null, valid: true, unselected: false });
  }

  render() {
    const { pin, valid, unselected } = this.state;
    const { startFlow } = this.props;

    return (
      <div className="ChangePin">
        <div className="Settings__header">1 of 2: Change PIN</div>
        <div className="Settings__subheader">Please enter your current PIN</div>
        <PinInput onComplete={this.onPinComplete} onIncomplete={this.onPinIncomplete} error={!valid} />
        <Button className="next-button" text="Next" unselected={unselected} disabled={!pin} onClick={this.validate} />
        <Button outlined text="Forgotten your PIN?" onClick={startFlow("forgotPin")} />
      </div>
    )
  }
};

import React from "react";
import { ErrorType } from "../Error/Error";
import "./Banner.scss";

const Banner: React.FunctionComponent<any> = () => {
    return (        
        <div className="abroad-alert">
            {/* <Button className="warning-img" svgIcon={SVG.Warning} /> */}
            <span className="warning-msg">{ErrorType.Abroad}</span>
        </div>        
    )
}

export default Banner;

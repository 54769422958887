import React from "react";
import moment from "moment";
import Tile from "../Carousel/Tile";
import { DropdownMenu, DropdownMenuItem } from "@brightcove/studio-components";
import { SVG } from "../../assets/images";
import _ from "lodash";
import "./Grid.scss";

export default class Grid extends React.Component<any, any> {
  sortingTypes = [
    {
      name: 'az',
      label: 'A-Z'
    },
    {
      name: 'latest',
      label: 'Latest'
    },
    {
      name: 'lastchance',
      label: 'Last Chance'
    }
  ]

  constructor(props) {
    super(props);
    this.state = {
      showSorter: true,
      sortBy: 'az',
      items: []
    }
  }

  getOriginalItems = () => {
    return _.get(this.props.component, "collection.items.nodes").slice() || [];
  }

  componentDidMount() {
    const items = this.getOriginalItems();
    if(items && items.length) {
      this.setState({
        items: this.sortItemsBy(this.state.sortBy)
      })
    }
  }

  getSortingLabelByName (name) {
    let filteredSortingTypes = this.sortingTypes.filter((item) => item.name === name);
    return filteredSortingTypes.length ? filteredSortingTypes[0].label : '';
  }

  sortItemsBy (sortBy) {
    let compare;
    let items = this.getOriginalItems();

    switch(sortBy) {
      case "az":
        compare = (a, b) => {
          let nameA = a.name.toLowerCase();
          let nameB = b.name.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          else if (nameA > nameB) {
            return 1;
          }
          return 0;
        }
        break;
      case "latest":
        compare = (a, b) => {
          if (moment(b.createdAt).isBefore(a.createdAt)) {
            return -1;
          }
          else if (moment(b.createdAt).isAfter(a.createdAt)) {
            return 1
          }
          return 0;
        }
        break;
      case "lastchance":
        compare = (a, b) => {
          if(!a.end) {
            return 1;
          }
          else if(!b.end) {
            return -1
          }
          else if(!(a.end || b.end)) {
            return 0;
          }
          else if (moment(a.end).isBefore(b.end)) {
            return -1;
          }
          else if (moment(a.end).isAfter(b.end)) {
            return 1
          }
          return 0;
        }
        break;
    }

    items.sort(compare);

    return items;
  }

  setSortBy = (item) => {
    return () => {
      this.setState({
        sortBy: item.name,
        items: this.sortItemsBy(item.name)
      });
    }
  }

  render() {
    const { showSorter, sortBy, items } = this.state;
    const { component, enableAbroadBanner } = this.props;

    return (
      <div className="Grid">
        <div className="Grid__header">{component && component.header}</div>
        {
          showSorter &&
          <div className="Grid__sorter">
            <div className="Grid__sorter-header">Sort by:</div>
            <DropdownMenu className="Grid__sorter-menu" label={<div className="Grid__sorter-menu-label">{this.getSortingLabelByName(sortBy)}<SVG.Arrow /></div>}>
              {this.sortingTypes.map((item, index) => (
                  <DropdownMenuItem key={'sort-' + item.name} onClick={this.setSortBy(item)}>
                    <span>{item.label}{index === 0 && <SVG.Arrow />}</span>
                  </DropdownMenuItem>
              ))}
            </DropdownMenu>
          </div>
        }
        <div className="Grid__items">
          <div className="Grid__items-content">
            {
              items.map((contentItem, index) => (
              <Tile
                key={index}
                containerType="grid"
                position={index}
                contentItem={contentItem}
                enableAbroadBanner={enableAbroadBanner}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
};

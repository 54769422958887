import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { withRouter } from "react-router";
import { Constants, getUrl, mobileRedirect } from "../../../utilities";
import "./MobileSubMenuItem.scss";
import { Image } from "../../../assets/images";

const more = [
  Image.Download,
  Image.TvGuide,
  Image.SignIn
]

const moreSelected = [
  Image.PinkDownload,
  Image.PinkTvGuide,
  Image.PinkSignIn
]

const MobileSubMenuItem: React.FunctionComponent<any> = ({ parent, click, index, setClick, navItem, history, props }) => {
  const { name, target, image, logo, imageUrls, id, onClick: customOnClick } = navItem;

  const onClick = () => {
    props.appBodyClassName(undefined);
    parent === Constants.Live ? setClick(id) : setClick(name)
    if ([Constants.Downloads].includes(name) || parent === Constants.Live) {
      mobileRedirect(process.env.REACT_APP_BANNER_ID);
    } else if (name === Constants.TvGuide) {
      history.push(getUrl(`${Constants.TvGuideTarget}${Constants.TvGuidePaths[0]}`));
    } else {
      customOnClick ?
      customOnClick() :
      history.push(getUrl(target));
    }
  }

  const getDefImage = (parent, index) => {
    if(parent === Constants.Live) {
      return imageUrls.blackNwhite
    } else if (parent === Constants.Channels) {
      return logo ? logo.unselected : ""
    } else if (parent === Constants.More) {
      return more[index]
    } else {
      return ""
    }
  }

  const getSelectedImage = (parent, index) => {
    if(parent === Constants.Live) {
      return imageUrls.colored
    } else if (parent === Constants.Channels) {
      return logo ? logo.selected : ""
    } else if (parent === Constants.More) {
      return moreSelected[index]
    } else {
      return ""
    }
  }

  const getImage = (parent, index) => {
    return (parent === Constants.Live ? click === id : click === name) ?
      getSelectedImage(parent, index) :
      getDefImage(parent, index)
  }

  const [imageUrl, setImageUrl] = useState(getImage(parent, index));
  useEffect( () => {
    setImageUrl(getImage(parent, index))
  },[click])

  return (
    <div className={classnames("MobileSubMenuItem", { selected: (parent === Constants.Live ? click === id : click === name)} )} 
      onClick={onClick}>
      { image || imageUrls ? <img className={parent === Constants.More ? "" : "MobileSubMenuItem__icon"}
        src={imageUrl} alt={name} /> :<span>{name}</span> }
      { parent === Constants.More && <span>{name}</span> }
    </div>
  )
};

export default withRouter(MobileSubMenuItem);

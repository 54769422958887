import React from "react";
import Footer from "../../components/Footer/Footer";
import SignOut from "./SignOut/SignOut";
import VideoSettings from "./VideoSettings/VideoSettings";
import PinManagement from "./PinManagement/PinMangement";
import classnames from "classnames";
import { withStore } from "../../store";
import { withRouter, Redirect } from "react-router";
import { compose } from "redux";
import querystring from "query-string";
import _ from "lodash";
import { getUrl, mobileRedirect } from "../../utilities";
import "./Settings.scss";

// This is to force the view to re-render if the user clicks on the same option. Normally that isn't the case
// because the child views are already mounted and their state/props haven't changed, so I need to change the
// key instead
let key = _.uniqueId();

class Settings extends React.Component<any, any> {
  sections: any[] = [
    { label: "Video settings", value: "av", component: VideoSettings },
    { label: "PIN management", value: "pin", component: PinManagement },
    { label: "Sign out", value: "sign-out", component: SignOut }
  ];

  constructor(props) {
    super(props);
    this.state = {
      section: this.getSection(props.location),
      showSignInPrompt: false
    }
  }

  getSection = (location) => {
    const search = querystring.parse(location.search);
    const page = search.page;
    return (page && this.sections.filter(section => section.value === page)[0]) || this.sections[0];
  }

  componentDidUpdate(prevProps) {
    const { location, store: { onDesktop } } = this.props;
    const prevSection = this.getSection(prevProps.location);
    const section = this.getSection(this.props.location)
    const prevKey = _.get(prevProps.location, "state.key");
    const key = _.get(location, "state.key");

    if(!onDesktop) {
      mobileRedirect(process.env.REACT_APP_BANNER_ID);
    }

    if((section !== prevSection) || (key !== prevKey)) {
      this.setState({ section });
    }
  }

  onMenuClick = (section) => {
    return () => {
      const { section: currentSection } = this.state;
      const { history } = this.props;
      const url = getUrl(`/settings?page=${section.value}`);

      if(currentSection.value === section.value) {
        key = _.uniqueId();
        history.replace(url, { key })
      }
      else {
        history.push(url, { key });
      }
    }
  }

  toSignOut = () => {
    const signOutSection = this.sections.filter(section => section.value === "sign-out")[0];
    this.onMenuClick(signOutSection)();
  }

  render() {
    const { section: currentSection } = this.state;
    const { store: { signedIn } } = this.props;
    const SectionComponent = currentSection.component;

    return (
      !signedIn ?
      <Redirect to={getUrl("/")} /> :
      <div className="Settings">
        <div className="Settings__menu">
        <div className="Settings__menu-header">Settings</div>
        {
          this.sections.map((section, index) => (
            <div
              key={index}
              className={classnames("Settings__menu-item", { selected: section.value && section.value === currentSection.value })}
              onClick={section.onClick || this.onMenuClick(section)}
            >
              {section.label}
            </div>
          ))
        }
        <Footer menu />
        </div>
        <div className="Settings__content">
        {
          SectionComponent &&
          <SectionComponent
            key={`${currentSection.value}${key}`}
            toSignOut={this.toSignOut}
          />
        }
        </div>
      </div>
    )
  }
};

export default compose<any>(withRouter, withStore)(Settings);

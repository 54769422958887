import React, { useState, useEffect } from "react";
import { Constants, getUrl } from "../../utilities"
import "./TvGuide.scss"
import TvGuideImage from "./TvGuideChannelImage";
import MediaQuery from "react-responsive";
import { Image } from "../../assets/images";
import { withRouter } from "react-router-dom";

const Arrow = [
  Image.ChevronLeft,
  Image.ChevronRight
]

const DisabledArrow = [
  Image.DisChevronLeft,
  Image.DisChevronRight
]


const TvGuideChannelList: React.FunctionComponent<any> = ({channels, selected, setSelected, history}) => {
  const [leftArrow, setLeftArrow ] = useState((selected> 0) ? Arrow[0] : DisabledArrow[0]);
  const [rightArrow, setRightArrow ] = useState((selected < channels.length - 1) ? Arrow[1] : DisabledArrow[1]);

  const leftClick = ( ) => {
    if(selected > 0) {
      if(selected - 1 === 0 ) {
        setLeftArrow(DisabledArrow[0])
      }
      if(selected - 1 < channels.length - 1 ) { 
        setRightArrow(Arrow[1])
      }
      setSelected(selected - 1)
    }
  }

  const rightClick = ( ) => {
    if(selected < channels.length - 1) {
      if(selected + 1 === channels.length - 1 ) {
        setRightArrow(DisabledArrow[1])
      }
      if(selected + 1 > 0 ) {
        setLeftArrow(Arrow[0])
      }
      setSelected(selected + 1)
    }
  }

  useEffect(() => {
    history.push(getUrl(`${Constants.TvGuideTarget}${Constants.TvGuidePaths[selected]}`));
  } , [selected]);

  return (
    <div className="Tv_Guide_Channels_List">
      <MediaQuery minWidth={Constants.MobileWidth + 1}>
        { channels.map( (channel, index ) => <TvGuideImage key={index} 
          channel={channel} index={index} selected={selected} setSelected={setSelected}></TvGuideImage> ) }
      </MediaQuery>
      <MediaQuery maxWidth={Constants.MobileWidth}>
          <img className="Tv_Guide_left_arrow" src={leftArrow} onClick={leftClick} alt=""></img>
          <TvGuideImage isMobile channel={channels[selected]} index={selected} selected={selected} setSelected={setSelected}></TvGuideImage>
          <img className="Tv_Guide_right_arrow" src={rightArrow} onClick={rightClick} alt=""></img>
      </MediaQuery>
    </div>
  )
}

export default withRouter(TvGuideChannelList);

import React from "react";

interface ScrollDetectorProps {
  selector?: string;
  onScroll?: Function;
  continuousUpdates?: Boolean;
  children?: (scrollingUp?: boolean, scrollingDown?: boolean, scrollPosition?: number) => React.ReactNode | React.ReactNode;
};

export default class ScrollDetector extends React.Component<ScrollDetectorProps, any> {
  previousScrollY;

  constructor(props) {
    super(props);
    this.state = {
      scrollDirection: 0
    };
  }

  element = (s?) => {
    let selector = s || this.props.selector;
    return (selector && document.querySelector(selector)) || document;
  }

  scrollOffset = () => {
    const element = this.element();
    return element === document ? window.pageYOffset : (element as HTMLElement).scrollTop;
  }

  componentDidMount() {
    this.element().addEventListener("scroll", this.onWindowScroll);
    this.previousScrollY = window.pageYOffset;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.continuousUpdates === true ||
          (nextState.scrollDirection !== this.state.scrollDirection) ||
          (nextProps.selector !== this.props.selector);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.selector !== this.props.selector) {
      this.element(prevProps.selector).removeEventListener("scroll", this.onWindowScroll);
      this.element().addEventListener("scroll", this.onWindowScroll);
    }
  }

  componentWillUnmount() {
    this.element().removeEventListener("scroll", this.onWindowScroll);
  }

  onWindowScroll = () => {
    const { onScroll } = this.props;
    const scrollOffset = this.scrollOffset();
    const scrollDirection = scrollOffset === 0 || scrollOffset === this.previousScrollY ? 0
      : scrollOffset > this.previousScrollY ? 1
      : -1;

    this.setState({ scrollDirection }, () => {
      this.previousScrollY = scrollOffset;
      onScroll && onScroll();
    });
  }

  render() {
    const { scrollDirection } = this.state;
    const { children } = this.props;
    return typeof children === "function" ?
      children(scrollDirection < 0, scrollDirection > 0, this.scrollOffset()) :
      children || null;
  }
};

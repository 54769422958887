import React, { useState, useEffect } from "react";
import classnames from "classnames";

const Button: React.FunctionComponent<any> = ({ unselected, imageClass,icon, svgIcon: SVG, text, className, onClick, ...props }) => {
  const [selected, setSelected] = useState(false);
  
   useEffect(() => {
    setSelected(false);
  }, [unselected]);

  const onclick = () => {
    setSelected(true);
    onClick();
  };
  return (
    <button className={classnames("Button", className, { selected: selected, ...props })} onClick={onclick}>
      {icon && <img  className={imageClass} src={icon} alt="icon"/>}{SVG && <SVG />}<span>{text}</span>
    </button>
  )
}

export default Button;

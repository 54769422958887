import React from "react";
import Hero from "../Hero/Hero";
import Carousel from "../Carousel/Carousel";
import Grid from "../Grid/Grid";
import Live from "../Live/Live";
const ComponentList: React.FunctionComponent<any> = ({ components, extraProps, enableAbroadBanner }) => {

  return (
    <React.Fragment>
      {/* { isEnable && <Banner/>} */}
      {
        components.map((component, index) => {
          const { __typename } = component;
          const id = component.header ? `${component.header.replace(/ /g, "")}-${index}` : index;
          const key = `${__typename}-${id}`;
          const position = index + 1;

          switch(__typename) {
            case "Live":
              return <Live key={key} component={component} { ...extraProps } enableAbroadBanner={enableAbroadBanner}></Live>
            case "Hero":
              return <Hero key={key} position={position} component={component} { ...extraProps } enableAbroadBanner={enableAbroadBanner}/>
            case "Carousel":
            case "ContinueWatching":
              return <Carousel key={key} position={position} component={component} { ...extraProps } enableAbroadBanner={enableAbroadBanner}/>
            case "Grid":
              return <Grid key={key} position={position} component={component} { ...extraProps } enableAbroadBanner={enableAbroadBanner} />
            default:
              return null;
          }
        })
      }
    </React.Fragment>
  )
};

export default ComponentList;

import React from "react";
import ReactModal from "react-modal";
import { SVG } from "../../assets/images";
import "./Modal.scss";
import { withStore, StoreProps } from "../../store";
import { StoreState } from "../../store/types";

interface ModalProps {
  children: React.ReactNode | ((boolean) => React.ReactNode)
  onClose?: Function
  shouldCloseOnEsc? : boolean
  shouldCloseOnOverlayClick?: boolean
  shouldShowCloseButton?: boolean
  store: StoreState
};

class Modal extends React.Component<ModalProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { onClose } = this.props;
    if(prevState.isOpen && !this.state.isOpen) {
      onClose && onClose();
    }
  }

  onRequestClose = () => {
    this.setState({ isOpen: false });
  }

  render() {
    const { children, shouldShowCloseButton, ...props } = this.props;
    // const { store : { isAbroad } } = props;
    const isAbroad = props.store.isAbroad;
    const { isOpen } = this.state;
    return (
      <ReactModal
        {...props}
        isOpen={ isAbroad ? false : isOpen }
        onRequestClose={this.onRequestClose}
        overlayClassName={ isAbroad ? "ModwithStoreal__overlay" : "Modal__overlay" }
        className="Modal__content"
        appElement={document.querySelector("#root") as HTMLElement}
      >
        <React.Fragment>
          { shouldShowCloseButton &&
            <div className="Modal__close" onClick={this.onRequestClose}><SVG.Close /></div>
          }
          { typeof children === "function" ?
          (children as Function)(this.onRequestClose) :
          children
          }
        </React.Fragment>
      </ReactModal>
    )
  }
};

export default  withStore(Modal);
import React, { useState } from "react";
import SubMenuTab from "./SubMenuTab";
import LiveSubMenuTab from "./LiveSubMenuTab";
import MobileSubMenu from "./MobileSubMenu/MobileSubMenu";
import MediaQuery from "react-responsive";
import classnames from "classnames";
import { Constants, lockScroll, unlockScroll, AuthorizeLiveplayer } from "../../utilities";
import { SignInModal, UpgradeModal, TrialProgressModal } from "../../components/Modals";
import { withStore } from "../../store";
import "./SubMenu.scss";


const SubMenu: React.FunctionComponent<any> = ({ open, navItem, onClose, props, playLiveVideo, store: { isAbroad, country } }) => {
  const [showPrompt, setShowPrompt] = useState("");
  if(open && window.innerWidth <= Constants.MobileWidth) lockScroll();
  else unlockScroll();


  const handleLiveClick = async(channel) => {
    channel.now.extId = channel.now.houseid;
    channel.now.callSchedule = true;
    channel.now.channelId = channel.id;
    channel.now.channelTitle = channel.title;
    await AuthorizeLiveplayer(
      isAbroad,
      props.enableAbroadBanner,
      setShowPrompt,
      country,
      channel.title,
      channel.now,
      playLiveVideo
    );
    onClose();
  }

  return (
      <div className={classnames( "SubMenu", { open, live: navItem && navItem.name === Constants.Live && open })}>
        <MediaQuery minWidth={Constants.MobileWidth + 1} onChange={onClose}>
          { navItem && navItem.name === Constants.Live && <LiveSubMenuTab props={props} handleLiveClick={handleLiveClick} navItem={navItem} /> }
          { navItem && navItem.name !== Constants.Live && <div className="SubMenu__content">
            { navItem.children && navItem.children.map((navItem, index) =>
              <SubMenuTab props={props} key={index} navItem={navItem} />
            )}
          </div> }
        </MediaQuery>
        <MediaQuery maxWidth={Constants.MobileWidth}>
          <MobileSubMenu props={props} navItem={navItem} onClose={onClose} />
        </MediaQuery>
        { showPrompt === Constants.Prompts[0] && <SignInModal onClose={() => {setShowPrompt("")}} />} 
        { showPrompt === Constants.Prompts[2] && <TrialProgressModal onClose={() => {setShowPrompt("")}} /> }
        { showPrompt === Constants.Prompts[1] && <UpgradeModal onClose={() => setShowPrompt("")} /> }
      </div>
  )
};

export default withStore(SubMenu) ;

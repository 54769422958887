import React from "react";
import AboutMalaysia from "./AboutMalaysia";
import AboutSingapore from "./AboutSingapore";
import { withStore } from "../../../store";
import { Analytics } from "../../../utilities";
import "../Basic.scss";

class About extends React.Component<any, any> {
  componentDidMount() {
    Analytics.pageViewBasic();
  }
  render() {
    const { store: { country } } = this.props;
    return country === "MY" ?
    <AboutMalaysia /> : <AboutSingapore />
  }
}

export default withStore(About);

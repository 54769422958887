import { createStore as createReduxStore } from "redux";
import { StoreState, GenericAction, StoreProps } from "./types";
import reducers, { defaultState } from "./reducers";
import withStore from "./mappers";

export default function createStore(initialState = {}) {
  let state = { ...defaultState(), ...initialState };
  return createReduxStore<StoreState, GenericAction, any, any>(reducers, state);
};

export { withStore };
export type StoreProps = StoreProps;

export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const UPDATE_MULTIPLE_SETTINGS = "UPDATE_MULTIPLE_SETTINGS";
export const SIGN_OUT = "SIGN_OUT";
export const SET_COUNTRY = "SET_COUNTRY";
export const PLAY_VIDEO = "PLAY_VIDEO";
export const DISMISS_VIDEO = "DISMISS_VIDEO";
export const PLAY_LIVE_VIDEO = "PLAY_LIVE_VIDEO";
export const DISMISS_LIVE_VIDEO = "DISMISS_LIVE_VIDEO";
export const SIGN_IN = "SIGN_IN";
export const SET_DESKTOP_FLAG = "SET_DESKTOP_FLAG";
export const SET_ISABROAD_FLAG = "SET_ISABROAD_FLAG";
export const SET_SUPPORTED_SUBTITLE_LANGUAGES_FLAG = "SET_SUPPORTED_SUBTITLE_LANGUAGES_FLAG";
export const SET_SUPPORTED_AUDIO_LANGUAGES_FLAG = "SET_SUPPORTED_AUDIO_LANGUAGES_FLAG";

export const updateSettings = (key, value) => ({
  type: UPDATE_SETTINGS,
  data: { key, value }
});

export const updateMultipleSettings = (data) => ({
  type: UPDATE_MULTIPLE_SETTINGS,
  data
});

export const signOut = () => ({
  type: SIGN_OUT
});

export const setCountry = (data) => ({
  type: SET_COUNTRY,
  data
});

export const playVideo = (data) => ({
  type: PLAY_VIDEO,
  data
});

export const playLiveVideo = (data) => ({
  type: PLAY_LIVE_VIDEO,
  data
});

export const dismissVideo = () => ({
  type: DISMISS_VIDEO
});

export const dismissLiveVideo = () => ({
  type: DISMISS_LIVE_VIDEO
});

export const signIn = (accessToken, tokenType, expiresIn) => ({
  type: SIGN_IN,
  data: { accessToken, tokenType, expiresIn }
});

export const setDesktopFlag = (data) => ({
  type: SET_DESKTOP_FLAG,
  data
});

export const setIsAbroadFlag = (data) => ({
  type: SET_ISABROAD_FLAG,
  data
});

export const supportedsubtitlelanguages = (data) => ({
  type: SET_SUPPORTED_SUBTITLE_LANGUAGES_FLAG,
  data
});

export const supportedaudiolanguages = (data) => ({
  type: SET_SUPPORTED_AUDIO_LANGUAGES_FLAG,
  data
});

import React, { useState, useRef, useEffect } from "react";
import ContentFetch from "../../components/Fetch/ContentFetch";
import { Tabs, TabList, TabPanel, SmallTab } from "@brightcove/studio-components";
import List from "../../components/List/List";
import MultiLineDiv from "../../components/MultiLineDiv/MultiLineDiv";
import { ReactImage } from "../../components/ReactImage";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import { getUrl, Analytics } from "../../utilities";
import { compose } from "redux";
import { withStore } from "../../store";
import Banner from "../../components/Banner/Banner";
import "./Series.scss";

const Series: React.FunctionComponent<any> = ({ match: { params }, history, store: { isAbroad} }) => {
  const [isEnable, setEnable] = useState(false);
  const bannerRef = useRef();
  const { seriesPath, seasonPath } = params;
  const seasonNumber = seasonPath && Number(seasonPath.replace(/.+-s(\d+)$/,"$1"));

  // useEffect(() => {
  //   console.log('useeffect alertRef.current => ', alertRef.current);
  //   if (alertRef.current) {
  //     alertRef.current.scrollTo(0, 0);
  //   }
  // }, [isEnable]);

  const getCurrentSeason = (series, seasonNumber) => {
    return series.seasons.filter(season => season.seasonNumber === seasonNumber)[0] || series.seasons[0];
  }

  const changeSeason = (series, seasonNumber) => {
    const url = getUrl(`/brand/${seriesPath}/${seriesPath}-s${seasonNumber}`)
    const season = getCurrentSeason(series, seasonNumber);
    Analytics.pageViewSeries(series, season);
    history.push(url);
  }

  const reportSelection = (series) => {
    const season = getCurrentSeason(series, seasonNumber);
    if (series && season) {
      Analytics.pageViewSeries(series, season);
    }
  }

  const enableAbroadBanner = () => {
       console.log('LOG INVOKED FROM CHILD COMPONENT LISTITEMS');
    const isBannerElement = document.querySelector<HTMLElement>(".app-body .Series .abroad-alert");
    console.log("isBannerElement=> ", isBannerElement)

    if(isAbroad){
      if(!isBannerElement){
        setEnable(isAbroad);
        setTimeout(() => { setEnable(!isAbroad) }, 3000);
      }   
      window.scrollTo(0, 0);
    }
  
  }

  return (
    <ContentFetch pathname={seriesPath} key={`series:${seriesPath}`} onComplete={reportSelection}>
      {(errors, series) => {
        let season, seasonsText, episodesText, image;

        if(series) {
          season = getCurrentSeason(series, seasonNumber);
          seasonsText = `${series.seasonCount} ${series.seasonCount > 1 ? "Seasons" : "Season"}`;
          episodesText = `${series.episodeCount} ${series.episodeCount > 1 ? "Episodes" : "Episode"}`;
          image = series.slide.image;
        }

        return (
          (!series || errors) ?
          null :

          <div className="Series">
            { isEnable && <Banner className="bannerRef"/>}
            <Helmet>
              <title>{`BBC Player | ${series.name}`}</title>
            </Helmet>
            <div className="Series__detail">
              <div className="Series__metadata">
                <div className="Series__title">{series.name}</div>
                <div className="Series__subtitle">{`${seasonsText} | ${episodesText}`}</div>
                <MultiLineDiv className="Series__description" maxLines="2">{series.description}</MultiLineDiv>
                <div className="Series__watchlist-channel">
                  {/* <div className="Series__remove"><SVG.Close />Remove from watchlist</div> */}
                  { series.channelImage && <div className="Series__channel-logo"><img alt={series.channel} src={series.channelImage.url} /></div>}
                </div>
              </div>
              <ReactImage className="Series__poster" src={image && image.url} alt="series poster" large />
            </div>
            { season && <div className="Series__seasons">
              <Tabs selectedId={season.seasonNumber} onSelect={number => changeSeason(series, number)}>
                <TabList className="Series__seasons-tablist">
                  {
                    [
                      <SmallTab className="Series__seasons-tablist-head" key="season-head">Season</SmallTab>,
                      ...series.seasons.map(season => (
                        <SmallTab className="Series__seasons-tablist-tab" key={season.seasonNumber} tabId={season.seasonNumber}>
                          {season.seasonNumber}
                        </SmallTab>
                      ))
                    ]
                  }
                </TabList>
                <TabPanel className="Series__tab-panel" render={id => (
                  <div className="Series__episodes_list">
                    <List season={season} series={series} enableAbroadBanner = {enableAbroadBanner}/>
                  </div>
                )} />
              </Tabs>
            </div>
            }
          </div>
        )
      }}
    </ContentFetch>
  )
};

export default compose<any>(withRouter, withStore)(Series);

import React from "react";
import { Button } from "../../../../components/Buttons";
import { PlainInput } from "@brightcove/studio-components";
import SettingsError from "../../../../components/SettingsError/SettingsError";

export default class CheckSecurityQuestion extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      answer: "",
      valid: true,
      unselected: false,
    };
  }

  componentDidMount() {
    const inputContainer = document.getElementById("securityanswer");
    if (inputContainer) {
      inputContainer.focus()
    }
  }

  onSelect = (question) => {
    this.setState({ question })
  }

  onInput = (answer) => {
    this.setState({
      answer,
      valid: true,
      unselected: false
    })
  }

  validate = () => {
    const { securityAnswer, onComplete } = this.props;
    const { answer } = this.state;

    if(answer !== securityAnswer) {
      this.setState({ valid: false, unselected: true })
    }
    else {
      this.setState({ valid: true }, onComplete);
    }
  }

  render() {
    const { securityQuestion, toSignOut } = this.props;
    const { answer, valid, unselected } = this.state;

    return (
      <div className="CheckSecurityQuestion">
        <div className="Settings__header">Forgotten PIN</div>
        <div className="Settings__subheader">Please answer the security question below to reset your PIN</div>
        <div className="CheckSecurityQuestion__question">
          <div>{securityQuestion}</div>
          <PlainInput id={"securityanswer"} value={answer} onChange={this.onInput} />
          { !valid && <SettingsError /> }
        </div>
        <div className="CheckSecurityQuestion__actions">
          <Button className="next-button" text="Next" unselected={unselected} disabled={!answer} onClick={this.validate} />
          <div className="CheckSecurityQuestion__forgot">
            <div>Forgotten answer?</div>
            <div onClick={toSignOut}>To reset please Sign Out</div>
          </div>
        </div>
      </div>
    )
  }
};

import React from "react";
import "./SettingsError.scss";

const SettingsError: React.FunctionComponent<any> = () => {
  return (
    <div className="SettingsError">
      <div className="SettingsError__arrow" />
      <div className="SettingsError__message">
        <div>Sorry that doesn't look right</div>
        <div>Please try entering again</div>
      </div>
    </div>
  )
};
export default SettingsError;

import React from "react";
import { withRouter, Link } from "react-router-dom";
import { SVG } from "../../assets/images";
import Search from "../Search/Search";
import SearchResults from "../Search/SearchResults";
import Menu from "../Menu/Menu";
import SubMenu from "../SubMenu/SubMenu";
import ScrollDetector from "../ScrollDetector/ScrollDetector";
import { Constants, getProviderInfo, getUrl } from "../../utilities";
import { Fetch } from "../Fetch";
import { withStore } from "../../store";
import _ from "lodash";
import "./Header.scss";

const defaultState = {
  subMenuOpen: false,
  subMenuNavigation: null,
  searchQuery: undefined,
  hoverreset: true,
};

const _PartnerLogo = ({ store: { signedIn, country }}) => {
  const { image } = getProviderInfo(country);
  return (
    <div className="Header__partner-logo">{ image && <img src={image} alt="provider" /> }</div>
  )
};
const PartnerLogo = withStore(_PartnerLogo);

class Header extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidUpdate(prevProps) {
    if(prevProps.location !== this.props.location) {
      this.setState(defaultState)
      this.props.appBodyClassName(undefined);
    }
  }

  toggleSubMenu = (navItem) => {
    const { subMenuNavigation } = this.state;
    if(!navItem || !navItem.name || (subMenuNavigation && subMenuNavigation.id === navItem.id)) {
      this.setState({
        subMenuOpen: false,
        subMenuNavigation: null
      })
      this.props.appBodyClassName(undefined);
    }
    else {
      this.setState({
        subMenuOpen: false,
        subMenuNavigation: null
      });
      this.props.appBodyClassName(undefined);
      setTimeout(() => {
        this.setState({
          subMenuOpen: true,
          subMenuNavigation: navItem
        })
        setTimeout(() => {
          this.props.appBodyClassName(navItem && navItem.name === Constants.Live 
            ? Constants.LiveSubMenu : Constants.SubMenu);
        }, 100);
      }, 500);

    }
  }

  formatData = (res) => {
    return _.get(res, "data.navigationItems.nodes") && {
      name: "Menu",
      target: getUrl("/"),
      children: _.get(res, "data.navigationItems.nodes")
    }
  }

  onSearchFocus = () => {
    this.setState({ subMenuOpen: false, subMenuNavigation: null })
    this.props.appBodyClassName(undefined);
    document.body.classList.add("searching");
  }

  onSearchBlur = () => {
    this.setState({searchQuery : undefined});
    document.body.classList.remove("searching");
    document.querySelector(".app-body")!.classList.remove("displayNone");
  }

  onSearch = (value) => {
    this.setState({searchQuery : value});
    document.querySelector(".app-body")!.classList.add("displayNone");
  }

  onWindowScroll = () => {
    if(window.innerWidth > Constants.MobileWidth) {
      this.setState({ subMenuOpen: false, subMenuNavigation: null });
      this.props.appBodyClassName(undefined);
    }
  }

  onClickLogo = () => {
    this.setState({ hoverreset: false });
    setTimeout(() => {
      const { history } = this.props;
      history.push(`${getUrl('/')}/`);
      this.setState({ hoverreset: true });
    }, 500);
  }

  render() {
    const { subMenuOpen, subMenuNavigation, searchQuery, hoverreset } = this.state;
    let supportedAudioLanguages = []
    let supportedSubtitleLanguages = []

    return (
      <Fetch url={`${process.env.REACT_APP_MIDDLEWARE_URL}/navigation`}>
        {(loading, error, data) => {
          const navItem = this.formatData(data);
          if (data) {
            supportedAudioLanguages = data.supportedAudioLanguages
            supportedSubtitleLanguages = data.supportedSubtitleLanguages
          }
          return (
            <React.Fragment>
              <ScrollDetector onScroll={this.onWindowScroll} />
              <div className="Header">
                <div className="Header__content">
                  <div className={hoverreset ? 'Header__logo' : 'Header__logo hover'} onClick={this.onClickLogo}><SVG.Logo /></div>
                  <PartnerLogo />
                  {navItem && <Menu className="Header__menu" supportedSubtitleLanguages={supportedSubtitleLanguages} supportedAudioLanguages={supportedAudioLanguages} navItem={navItem} onSubMenuToggle={this.toggleSubMenu} enableAbroadBanner={this.props.enableAbroadBanner} />}
                  <Search className="Header__search" onFocus={this.onSearchFocus} onBlur={this.onSearchBlur} onSearch={this.onSearch} />
                </div>
              </div>
              { searchQuery && <SearchResults query={searchQuery} /> }
              <SubMenu open={subMenuOpen} props={this.props} navItem={subMenuNavigation} onClose={this.toggleSubMenu} />
            </React.Fragment>
          )
        }}
      </Fetch>
    )
  }
};
export default withRouter(Header);

import React, { useEffect } from "react";
import ContentFetch from "../../components/Fetch/ContentFetch";
import ComponentList from "../../components/ComponentList/ComponentList";
import { Analytics } from "../../utilities";
import { Helmet } from "react-helmet";


const Category: React.FunctionComponent<any> = ({ enableAbroadBanner }) => {

  return (
    <ContentFetch onComplete={screen => Analytics.pageViewCategory(screen.name)}>
      {(error, screen) => (
        (!screen || error) ?
        null :
        <div>
          <Helmet>
            <title>{`BBC Player | ${screen.name}`}</title>
          </Helmet>
          <ComponentList components={screen.components} extraProps={{ category: screen.name }} enableAbroadBanner={enableAbroadBanner} />
        </div>
      )}
    </ContentFetch>
  )
};

export default Category;

import React from "react";
import {
  getQueryParams,
  validateAuthBrokerNonce,
  Constants,
  User,
  evergentRequest,
  getProviderInfo,
  getStorageItem,
  setStorageItem,
  Analytics
} from "../../utilities";
import { LoadingOverlay } from "../../components/Overlays";
import ErrorPortal from "../../components/Error/Error";
import { withStore } from "../../store";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

class Authentication extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      showError: false
    }
  }

  componentDidMount() {
    const { history } = this.props;
    const type = window.location.hash;
    switch(type) {
      case "#signin":
        this.completeSignIn();
        break;
      case "#subscribe":
        this.completeSubscribe();
        break;
      default:
        this.showError(() => ErrorPortal.error(undefined, () => history.replace(Constants.HomePath), "OK, got it"))
    }
  }

  showError = (errorFn) => {
    // Without the timeout the ReactModal unmount method doesn't seem to be called and the modal open class
    // on the body is never removed
    setTimeout(() => this.setState({ showError: true }, errorFn), 0);
  }

  completeSignIn = async () => {
    const { history, signIn } = this.props;
    const {
      responseMessage,
      responseCode,
      state: nonce,
      code: authCode,
      r: redirect = Constants.HomePath,
      country
    } = getQueryParams(window.location.search);
    const { startSignIn, provider } = getProviderInfo(country);
    const operator = getStorageItem("operator", true);
    const validNonce = validateAuthBrokerNonce(nonce);
    const success = responseMessage === "Success" && authCode && validNonce;
    let error;

    if(!success) {
      if(!validNonce) {
        error = () => ErrorPortal.error(undefined, () => history.replace(redirect), "OK, got it");
      }
      else if(responseCode === "503") {
        error = () => ErrorPortal.error(undefined, startSignIn);
      }
      else {
        error = () => ErrorPortal.error(String(responseMessage), () => history.replace(redirect), "OK, got it");
      }
      Analytics.userActionSignInError(responseCode, responseMessage);
    }
    else {
      const reqBody = {
        GetOAuthAccessTokenReqMessage: {
          authCode,
          grantType: "authorization_code",
          provider,
          operator,
        }
      }
      const message = await evergentRequest(Constants.GetTokenUrl, reqBody, "GetOAuthAccessTokenRespMessage");

      if(message) {
        const { accessToken, tokenType, expiresIn } = message;
        signIn(accessToken, tokenType, expiresIn);
        Analytics.userActionSignedIn();
        history.replace(redirect);
      }
      else {
        error = () => ErrorPortal.error(undefined, startSignIn);
      }
    }

    error && this.showError(error);
  }

  completeSubscribe = async () => {
    const { history } = this.props;
    const { responseMessage, responseCode, r: redirect = Constants.HomePath } = getQueryParams(window.location.search);
    const decideLater = responseMessage === "decide_later" && ["3000", "3001"].includes(String(responseCode));
    const success = responseMessage === "SUCCESS" || responseCode === "0";
    let error;

    if(decideLater) {
      history.replace(redirect);
    }
    else if(success) {
      const { history, signIn, signOut } = this.props;
      const message = await User.refreshToken(signIn, signOut, true, true);

      if(message) {
        let subType;
        if(User.hasFreeTrial() && !(await User.hasExpiredFreeTrial()) && User.provider() === "TMNET")
          subType = "trial";
        else if(User.hasActiveSubscription())
          subType = "sub";
        setStorageItem("st", subType, false, false, true);
        history.replace(redirect);
      }
      else {
        error = () => ErrorPortal.error(undefined, () => history.replace(redirect), "OK, got it")
      }
    }
    else {
      error = () => ErrorPortal.error(undefined, () => history.replace(redirect), "OK, got it")
    }

    error && this.showError(error);
  }

  render() {
    const { showError } = this.state;
    const country = getStorageItem("country", true);
    const operator = getStorageItem("operator", true);
    let provider = 'Starhub'
    if (country === "MY") {
      provider = (operator === 'tm') ? 'Unifi' : 'Astro'
    } else if (operator) {
      provider = (operator === 'singtel') ? 'Singtel' : 'Starhub'
    }
    
    return (
      showError ?
      null :
      <LoadingOverlay message={`Please hold while we connect you with ${provider}`} />
    )
  }
};

export default compose<any>(withRouter, withStore)(Authentication);

import React from "react";
import { withAuthorization } from "../../utilities";
import { Image } from "../../assets/images";
import classnames from "classnames";
import "./ReactImage.scss";

class ReactImage extends React.Component<any, any> {
  image;

  constructor(props) {
    super(props);
    this.state = {
      useFallback: !props.src
    }
  }

  render() {
    const { src, alt, large, className, ...props } = this.props;
    const { useFallback } = this.state;
    const fallback = large ? Image.LargeFallback : Image.SmallFallback;

    return (
      <img
        {...props}
        alt={alt}
        ref={node => this.image = node}
        className={classnames("re", className)}
        src={useFallback ? fallback : src}
        onError={() => this.setState({ useFallback: true })}
      />
    )
  }
}

const PlayImage = withAuthorization(ReactImage);

export {
  ReactImage,
  PlayImage
};

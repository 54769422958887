import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { ReactImage } from "../../../ReactImage";
import { Image } from "../../../../assets/images";
import { Analytics } from "../../../../utilities";
import HeaderBar from "../HeaderBar/HeaderBar";
import "react-circular-progressbar/dist/styles.css";
import "./NextEpisode.scss";

export default class NextEpisode extends React.Component<any, any> {
  countdownStart = 10;
  intervalDuration = 250;
  interval;

  constructor(props) {
    super(props);
    this.state = {
      countdownRemaining: this.countdownStart
    }
  };

  componentDidMount() {
    this.startCountdown()
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  startCountdown = () => {
    const { contentItem } = this.props;

    this.interval = setInterval(() => {
      const countdownRemaining = this.state.countdownRemaining - (this.intervalDuration/1000);

      if(countdownRemaining <= 0) {
        Analytics.userActionAdvancedToNextEpisode(contentItem);
        this.onAdvance();
      }
      else {
        this.setState({
          countdownRemaining: Math.max(countdownRemaining, 0)
        })
      }
    }, this.intervalDuration);
  }

  onClose = () => {
    const { onClose } = this.props;
    clearInterval(this.interval);
    onClose && onClose();
  }

  onAdvance = () => {
    const { onAdvance } = this.props;
    clearInterval(this.interval);
    onAdvance && onAdvance();
  }

  render() {
    const { countdownRemaining } = this.state;
    const { contentItem } = this.props;
    const { next: nextContentItem } = contentItem;
    const image = nextContentItem.tile.image;
    const countdownValue = (countdownRemaining / this.countdownStart) * 100;

    return (
      <React.Fragment>
        <HeaderBar contentItem={contentItem} onClose={this.onClose} />
        <div className="NextEpisode">
          <div className="NextEpisode__header">Next episode starts in...</div>
          <div className="CountdownTimer">
            <CircularProgressbar
              counterClockwise={true}
              strokeWidth={6}
              value={countdownValue}
              text={String(Math.round(countdownRemaining))}
            />
            <div className="CountdownTimer-cancel" onClick={this.onClose}>Cancel</div>
          </div>
          <div className="NextEpisode__item">
            <div className="NextEpisode__image" onClick={this.onAdvance}>
              <ReactImage src={image && image.url} />
              <img src={Image.Play} alt="play" />
            </div>
            <div className="NextEpisode__meta">
              <div className="NextEpisode__meta-series"></div>
              <div className="NextEpisode__meta-episode">{`${nextContentItem.episodeNumber}.${nextContentItem.name}`}</div>
              <div className="NextEpisode__meta-description">{nextContentItem.description}</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
};

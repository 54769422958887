import React from "react";
import classnames from "classnames";
import "./ProgressBar.scss";

const ProgressBar: React.FunctionComponent<any> = ({ className, progress })=> {
  return (
    <div className={classnames("ProgressBar", className)}>
      <div style={{ width: `${progress * 100}%` }} />
    </div>
  )
};

export default ProgressBar;

import React from "react";
import ReactModal from "react-modal";
import { SVG } from "../../assets/images";
import classnames from "classnames";
import "./Overlay.scss";

const Overlay: React.FunctionComponent<any> = ({ children, message, className }) => {
  return (
    <ReactModal
      isOpen={true}
      overlayClassName={classnames("Overlay__overlay", className)}
      className="Overlay__content"
      appElement={document.querySelector("#root") as HTMLElement}
    >
      <div className="Overlay__logo"><SVG.Logo /></div>
      <div className="Overlay__message">{message}</div>
      {children}
    </ReactModal>
  )
};

export default Overlay;

import React from "react";
import ContentFetch from "../../components/Fetch/ContentFetch";
import { Helmet } from "react-helmet";
import _ from "lodash";
import MultiLineDiv from "../../components/MultiLineDiv/MultiLineDiv";
import Border from "../../components/Border/Border";
import ComponentList from "../../components/ComponentList/ComponentList";
import { Analytics } from "../../utilities";
import "./Channel.scss";

const Channel: React.FunctionComponent<any> = ({enableAbroadBanner}) => {
  return (
    <ContentFetch onComplete={screen => Analytics.pageViewChannel(screen.name)}>
      {(error, screen) => (
        (!screen || error) ?
        null :
        <div>
          <Helmet>
            <title>{`BBC Player | ${screen.name}`}</title>
          </Helmet>
          {
            screen &&
            <React.Fragment>
              <div className="Channel__meta">
                <div className="Channel__meta-name">{screen.name}</div>
                <MultiLineDiv className="Channel__meta-description">{screen.description}</MultiLineDiv>
                <div className="Channel__meta-image"><img src={_.get(screen, "tile.image.url")} alt={_.get(screen, "name")} /></div>
                <Border />
              </div>
              <ComponentList components={screen.components} enableAbroadBanner={enableAbroadBanner} extraProps={{ category: screen.name }}/>
            </React.Fragment>
          }
        </div>
      )}
    </ContentFetch>
  )
}

export default Channel;

import {
  setCookie,
  getCookie,
  removeCookie,
  removeStorageItem,
  setStorageItem,
  Constants,
  getStorageItem,
  evergentRequest
} from "./index";
import jwt_decode from "jwt-decode";
import moment from "moment";
import _ from "lodash";

class User {
  request;
  operator = () => getStorageItem("operator", true);
  token = () => getCookie("eat");
  decodedToken = () => this.token() && jwt_decode(this.token());
  entitlements = () => this.decodedToken() && this.decodedToken().entitlements;
  authenticated = () => getCookie("eas") === "true";
  provider = () => this.decodedToken() && this.decodedToken().op;
  id = () => this.decodedToken() && this.decodedToken().uid;

  providerCountry = () => {
    const provider = this.provider();
    return provider ?
      ["TMNET", "HYPP TV","BBC_ASTRO"].includes(provider) ? "MY" : "SG"
      : null;
  }

  authHeader = () => {
    const token = this.token();
    const tokenType = getCookie("ett");
    return (token && tokenType) && `${tokenType} ${token}`;
  }

  clearData = () => {
    removeCookie("eat");
    removeCookie("eei");
    removeCookie("ett");
    removeCookie("eas");
    removeStorageItem("authbroker-authorize");
    removeStorageItem("reminders");
    removeStorageItem("ratings");
    removeStorageItem("utypes");
    removeStorageItem("hidePLM");
    removeStorageItem("operator");
    removeStorageItem("device_id");
    removeStorageItem("smr", true);
  }

  // TRIAL

  hasFreeTrial = () => {
    const entitlements = this.entitlements();
    return (entitlements && entitlements.filter(et => et.productID === "TM_FREE_TRIAL").length > 0) || false;
  }

  hasExpiredFreeTrial = async () => {
    if(!this.authenticated())
      return null;
    else if(this.hasActiveSubscription() || this.remainingFreeTrialDays()) {
      return false;
    }
    else {
      let remainingMinutes = this.remainingFreeTrialMinutes();
      if(remainingMinutes === undefined) {
        const upgradeTypes = await this.getUpgradeTypes();
        return !upgradeTypes.trial;
      }
      else {
        return remainingMinutes === 0;
      }
    }
  }

  remainingFreeTrialTime = () => {
    const entitlements = this.entitlements();
    if(!this.hasFreeTrial()) return {};

    const trial = entitlements.filter(et => et.productID === "TM_FREE_TRIAL")[0];
    const trialExpiration = moment(trial.expiryDate);
    const currentDay = Date.now();

    return {
      days: Math.max(Math.round(trialExpiration.diff(currentDay, "days", true)), 0),
      hours: Math.max(trialExpiration.diff(currentDay, "hours"), 0),
      minutes: Math.max(trialExpiration.diff(currentDay, "minutes"), 0)
    };
  }

  remainingFreeTrialDays = () => {
    return this.remainingFreeTrialTime().days;
  }

  remainingFreeTrialHours = () => {
    return this.remainingFreeTrialTime().hours;
  }

  remainingFreeTrialMinutes = () => {
    return this.remainingFreeTrialTime().minutes;
  }

  initFreeTrialReminders = () => {
    const reminders = [7,6,5,4,3,2,1,0];
    setStorageItem("reminders", reminders, false, true);
    return reminders;
  }

  private getFreeTrialReminders = () => {
    const reminders = getStorageItem("reminders", false, true);
    return reminders || this.initFreeTrialReminders();
  }

  getCurrentFreeTrialReminder = () => {
    const remaining = this.remainingFreeTrialDays();
    if(remaining !== undefined) {
      const reminders = this.getFreeTrialReminders();
      return reminders.filter(reminder => reminder === remaining)[0];
    }
    return undefined;
  }

  removeFreeTrialReminder = (day) => {
    let reminders = this.getFreeTrialReminders().filter(reminder => reminder < day);
    setStorageItem("reminders", reminders, false, true);
  }

  // SUBSCRIPTION

  hasActiveSubscription = () => {
    const entitlements = this.entitlements();
    if(!entitlements || !entitlements.length) return false;
    return !this.hasFreeTrial();
  }

  hasAuthorizedPackage = async (contentItem) => {
    if(!(this.hasFreeTrial() || this.hasActiveSubscription()))
      return false;
    else if(this.providerCountry() === "MY" && this.provider() !== "BBC_ASTRO")
      return true;
    else {
      const response = await fetch(Constants.PlaybackAuthorizationUrl, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "Authorization": this.authHeader()
        },
        body: JSON.stringify({ channel: contentItem.channel })
      });
      try {
        const data = await response.json();
        return data.success;
      } catch(err) {
        return false
      }
    }
  }

  getUpgradeTypes = async () => {
    let types = getStorageItem("utypes", true, true);
    if(types) return types;

    const body = { GetAvailableUpgradeRequestMessage: { operator: this.operator() } };
    const message = await evergentRequest(Constants.GetUpgradeTypesUrl, body, "GetAvailableUpgradeResponseMessage");
    if(message) {
      types = {
        subscription: message.subscription === "true",
        trial: message.trial === "true",
      }
      setStorageItem("utypes", types, true, true);
      return types;
    }
    return {};
  }

  // TOKEN

  setTokens = (accessToken, tokenType, expiresIn) => {
    setCookie("eat", accessToken);
    setCookie("eei", Date.now() + (Number(expiresIn) * 1000) - (60 * 1000));
    setCookie("ett", tokenType);
    setCookie("eas", "true");
    removeStorageItem("authbroker-authorize");
  }

  hasExpiredToken = () => {
    const currentDate = new Date().getTime();
    const expiration = Number(getCookie("eei"));
    return expiration <= currentDate;
  }

  refreshToken = (signIn, signOut, catchError = true, force?) => {
    if(this.authenticated() && !this.hasExpiredToken() && !force)
      return Promise.resolve(true);

    if(this.request)
      return this.request;
    
    this.request = fetch(Constants.TokenRefreshUrl, {
      credentials: "include",
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        RefreshTokenRequestMessage: {
          grantType: "refresh_token",
          operator: this.operator()
        }
      })
    }).then(async response => {
      try {
        const data = await response.json();
        const status = _.get(data, "RefreshTokenResponseMessage.responseMessage");
        const accessToken = _.get(data, "RefreshTokenResponseMessage.accessToken");
        const tokenType = _.get(data, "RefreshTokenResponseMessage.tokenType");
        const expiresIn = _.get(data, "RefreshTokenResponseMessage.expiresIn");

        if(status === "SUCCESS") {
          removeStorageItem("utypes");
          signIn(accessToken, tokenType, expiresIn);
          this.request = undefined;
          return true;
        }
        throw new Error("Invalid refresh token");
      } catch(err) {
        throw err;
      }
    }).catch(err => {
      signOut();
      this.request = undefined;

      if(catchError) {
        return false;
      }
      else {
        throw err;
      }
    })

    return this.request;
  }
}

export default new User();

import React from "react";
import SettingsError from "../SettingsError/SettingsError";
import "./PinInput.scss";

export default class PinInput extends React.Component<any, any> {
  firstInput;

  constructor(props) {
    super(props);
    this.state = {
      pin: ["", "", "", ""]
    };
  }

  componentDidUpdate(_, prevState) {
    const { onIncomplete } = this.props;
    if(!prevState.pin.includes("") && this.state.pin.includes("")) {
      onIncomplete && onIncomplete();
    }
  }

  componentDidMount() {
    this.firstInput && this.firstInput.focus();
  }

  onFocus = () => {
    const { error } = this.props;
    if(error) {
      this.setState({
        pin: ["", "", "", ""]
      });
    }
  }

  truncateDigit = (d) => {
    const digit = String(d);
    if(digit === "")
      return digit;
    else if(!/^[0-9]+$/.test(digit))
      return "";
    return digit.slice(digit.length - 1);
  }

  setDigit = (position, event) => {
    const target = event.target;
    const digit = event.target.value;
    const { onComplete } = this.props;
    const pin = this.state.pin.slice(0);
    pin[position] = this.truncateDigit(digit);

    if (pin[position]) {
      this.setState({ pin }, () => {
        const nextInput = target && target.nextElementSibling;
        nextInput && nextInput.focus();
  
        if(!pin.includes("")) {
          onComplete && onComplete(pin.join(""));
        }
      })
    }
  }

  render() {
    const { error } = this.props;
    const { pin } = this.state;
    return (
      <div className="PinInput">
          { pin.map((digit, index)=> (
            <input
              ref={node => { if(index === 0) this.firstInput = node }}
              key={index}
              min={0}
              step={1}
              value={digit}
              onChange={(value) => this.setDigit(index, value)}
              onFocus={this.onFocus}
            />
          ))}
          { error && <SettingsError /> }
        </div>
    )
  }
};

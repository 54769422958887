import React from "react";
import MenuTab from "./MenuTab";
import { Image } from "../../assets/images";
import classnames from "classnames";
import MediaQuery from 'react-responsive'
import { withRouter } from "react-router";
import { Constants, mobileRedirect, getUrl } from "../../utilities";
import { withStore } from "../../store";
import { SignInModal } from "../../components/Modals";
import { compose } from "redux";
import "./Menu.scss";

class Menu extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      navItem: this.getMenuItems(props.navItem),
      showSignIn: false,
      isMobile: window.innerWidth <= Constants.MobileWidth,
    }
  }
  onSignIn = async () => {
    const { store: { country, onDesktop, isAbroad } } = this.props;
    if (isAbroad) {
      const isBannerElement = document.querySelector<HTMLElement>(".abroad-alert");
      console.log('isBannerElement => ', isBannerElement);
      if (!isBannerElement) {
        this.props.enableAbroadBanner();
      }
    }
    if (!onDesktop)
      mobileRedirect(process.env.REACT_APP_BANNER_ID);
    else
      this.setState({ showSignIn: true });
  }

  onSettings = () => {
    const { history, store: { onDesktop } } = this.props;

    if (!onDesktop) {
      mobileRedirect(process.env.REACT_APP_BANNER_ID);
    }
    else {
      history.push(getUrl("/settings"));
    }
  }

  getMenuItems = (baseNavItem) => {
    const { store: { signedIn } } = this.props;
    const authBasedChildren = (window.innerWidth <= Constants.MobileWidth) ?
      [{
        name: Constants.More,
        children: [{ name: Constants.Downloads, image: { url: Image.Download } },
        { name: Constants.TvGuide, image: { url: Image.TvGuide } },
        { name: "Sign In", onClick: this.onSignIn, image: { url: Image.SignIn } }]
      }]
      : signedIn ?
        [
          // { name: "Watchlist", target: "/watchlist" },
          { name: "Settings", onClick: this.onSettings, image: { url: Image.Settings }, showImage: true, target: 'settings' }
        ] :
        [{ name: "Sign In", onClick: this.onSignIn }];

    return {
      ...baseNavItem,
      children: [
        ...baseNavItem.children || [],
        ...authBasedChildren
      ]
    };
  }

  componentDidMount() {
    if (!this.props.store.subtitlelanguages) {
      this.props.supportedsubtitlelanguages(this.props.supportedSubtitleLanguages)
    }
    if (!this.props.store.audiolanguages) {
      let audioOptions = this.props.supportedAudioLanguages || []
      if (audioOptions.length == 0) {
        audioOptions = [{ "label": "English", "iso1": "en", "iso2": "eng" }];
      }
      this.props.supportedaudiolanguages(audioOptions)
    }
  }

  componentDidUpdate(prevProps) {
    if ((!prevProps.navItem && this.props.navItem) || (prevProps.store.signedIn !== this.props.store.signedIn)
      || this.state.isMobile !== (window.innerWidth <= Constants.MobileWidth)) {
      this.setState({
        navItem: this.getMenuItems(this.props.navItem),
        isMobile: (window.innerWidth <= Constants.MobileWidth)
      })
    }
    else if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ showSignIn: false })
    }
  }

  render() {
    const { navItem, showSignIn } = this.state;
    const { onSubMenuToggle, className } = this.props;
    return (
      <div className={classnames("Menu", className)}>
        <MediaQuery minWidth={Constants.MobileWidth + 1}>
          {navItem.children.map((navItem, index) =>
            <MenuTab
              key={index}
              navItem={navItem}
              toggle={onSubMenuToggle}
            />
          )}
        </MediaQuery>
        <MediaQuery maxWidth={Constants.MobileWidth}>
          <MenuTab
            navItem={navItem}
            toggle={onSubMenuToggle}
            hideToggle
          />
        </MediaQuery>
        {showSignIn && <SignInModal onClose={() => this.setState({ showSignIn: false })} />}
      </div>
    )
  }
};

export default compose<any>(withRouter, withStore)(Menu);

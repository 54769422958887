import React from "react";
import { Button } from "../../../../components/Buttons";
import PinInput from "../../../../components/PinInput/PinInput";

export default class ChoosePin extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      pin: null
    };
  }

  onNext = () => {
    const { onComplete } = this.props;
    const { pin } = this.state;
    onComplete && onComplete({ pin });
  }

  onPinComplete = (pin) => {
    this.setState({ pin }, () => (document.querySelector(".next-button") as HTMLElement).focus());
  }

  onPinIncomplete = () => {
    this.setState({ pin: null });
  }

  render() {
    const { pin } = this.state;

    return (
      <div className="ChoosePin">
        <div className="Settings__header">1 of 4: Choose PIN</div>
        <div className="Settings__subheader">The Parental Guidance Lock helps you control what people can and can't watch on your device.</div>
        <PinInput onComplete={this.onPinComplete} onIncomplete={this.onPinIncomplete} />
        <Button className="next-button" text="Next" disabled={!pin} onClick={this.onNext} />
      </div>
    )
  }
};

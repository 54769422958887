import React from "react";
import { ExpandableSection } from "@brightcove/studio-components";
import { removeStorageItem } from "../../../utilities";
import "./AbroadDropdown.scss";

const AbroadDropdown: React.FunctionComponent<any> = () => {
  // If the user is abroad any saved ratings might be invalid, so they should be removed just in case.
  removeStorageItem("ratings");

  return (
    <ExpandableSection title="Need help?" defaultCollapsed className="AbroadDropdown">
      <p>If BBC Player is available where you are, it’s likely that the site is recognising your IP address as being outside that country or that you are using a public VPN. </p>
      <p>Please try the following to resolve the issue: </p>
      <ul>
        <li>Close and reopen your browser.</li>
        <li>Restart your Wi-Fi router. </li>
        <li>If you’re using a public Wi-Fi network, this may route your connection overseas. Please try again using a different network. </li>
        <li>Disable any web accelerator or a VPN you are using. If we detect you’re using a VPN you will not be able to view programmes, as we cannot confirm your location.</li>
        <li>Confirm with your internet service provider that your IP address is registered in the correct country.</li>
      </ul>
    </ExpandableSection>
  )
};

export default AbroadDropdown;

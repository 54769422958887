import React from "react"; 
import { SVG } from "../../assets/images";
import "./AnimatedLiveButton.scss";

const AnimatedLiveButton: React.FunctionComponent<any> = () => {
  return (
    <SVG.Live className="AnimatedLiveButton"></SVG.Live>
  )
}

export default AnimatedLiveButton;

import React from "react";
import { withStore } from "../../../store";
import { withRouter } from "react-router";
import { compose } from "redux";
import { setStorageItem, removeStorageItem, getUrl } from "../../../utilities";
import Modal from "../Modal";
import { Button, LockButton } from "../../Buttons";
import Checkbox from "../../Checkbox/Checkbox";
import "./ParentalLockModal.scss";

const ParentalLockModal: React.FunctionComponent<any> = ({ history, onContinue, isRestricted, onClose }) => {
  const hideToggle = (on) => {
    if(on)
      setStorageItem("hidePLM", "true");
    else
      removeStorageItem("hidePLM");
  }
  const enableLock = () => (
    history.push(getUrl("/settings?page=pin"))
  )
  let message, showHideOption, continueText;

  if(isRestricted) {
    message = "All restricted content requires you to activate PG lock to continue watching. Would you like to turn on your PG lock now?";
    showHideOption = false;
    continueText = "No, thanks";
  }
  else {
    message = "This programme may not be suitable for children. Would you like to set a PIN to manage who can and cannot watch rated content on this device?";
    showHideOption = true;
    continueText = "No, continue to video";
  }

  return (
    <Modal onClose={onClose} shouldShowCloseButton={true}>
      <div className="ParentalLockModal">
        <div className="ParentalLockModal__title">Turn on parental guidance lock?</div>
        <div className="ParentalLockModal__message">{message}</div>
        { showHideOption &&
          <div className="ParentalLockModal__hide">
            <Checkbox onClick={hideToggle} /><span>Don't ask me this again</span>
          </div>
        }
        <Button text={continueText} onClick={onContinue} />
        <LockButton text="Turn on PG lock" onClick={enableLock} />
      </div>
    </Modal>
  )
};

export default compose<any>(withRouter, withStore)(ParentalLockModal);

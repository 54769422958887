import React from "react";
import { startSubscriptionFlow, User, Constants, Analytics } from "../../utilities";
import Button from "./Button";

const SubscribeButton: React.FunctionComponent<any> = ({ trial, upgrade, ...props }) => {
  const onClick = async () => {
    const pathname = window.location.pathname;

    if (upgrade) {
      const operator = User.provider()
      if (operator && operator.includes("SINGTEL")) {
        window.location.href = Constants.SingtelUpgradeUrl;
      } else if(operator && operator.includes("BBC_ASTRO")){
        window.location.href = Constants.AstroUpgradeUrl;
      } else if(operator && operator.includes("HYPPTV")){
        window.location.href = Constants.UnifiUpgradeUrl;
      } else {
        window.location.href = Constants.StarhubUpgradeUrl;
      }
    }
    else if(trial !== undefined) {
      if(trial === false) {
        const reminder = User.getCurrentFreeTrialReminder();
        Analytics.userActionSubscribedFromTrial(reminder);
        window.location.href = `${Constants.UnifiSubscribeUrl}?o=a`;
      } else {
        startSubscriptionFlow(trial, pathname);
      }
    }
    else {
      const upgradeTypes = await User.getUpgradeTypes();
      startSubscriptionFlow(upgradeTypes.trial, pathname);
    }
  }
  return <Button {...props} onClick={onClick} />
}

export default SubscribeButton;

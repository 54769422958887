import React from "react";
import { Button } from "../../../../components/Buttons";
import PinInput from "../../../../components/PinInput/PinInput";

export default class AddPin extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      pin: null
    };
  }

  onNext = () => {
    const { onComplete } = this.props;
    const { pin } = this.state;
    onComplete && onComplete({ pin });
  }

  onPinComplete = (pin) => {
    this.setState({ pin }, () => (document.querySelector(".confirm-button") as HTMLElement).focus());
  }


  onPinIncomplete = () => {
    this.setState({ pin: null });
  }

  showConfirmation = () => {
    this.setState({ showConfirmation: true });
  }

  render() {
    const { pin } = this.state;

    return (
      <div className="AddPin">
        <div className="Settings__header">Forgotten PIN</div>
        <div className="Settings__subheader">Now please add your new PIN</div>
        <PinInput onComplete={this.onPinComplete} onIncomplete={this.onPinIncomplete} />
        <Button className="confirm-button" text="Confirm and complete" disabled={!pin} onClick={this.onNext} />
      </div>
    )
  }
};

import React from "react";
import classnames from "classnames";
import { Input } from "@brightcove/studio-components";
import { SVG } from "../../assets/images";
import { Constants } from "../../utilities";
import "./SearchBar.scss";

const SearchBar: React.FunctionComponent<any> = ({ focused, value, onChange, onFocus, onBlur, onClose, onManualSearch }) => {
  let clickTimeout;

  const onIconClick = () => {
    if(focused) {
      clearTimeout(clickTimeout);
      onManualSearch();
    }
    else {
      (document.querySelector(".SearchBar__input > input") as HTMLElement).focus()
    }
  }

  const handleBlur = () => {
    if(window.innerWidth > Constants.MobileWidth) {
      clearTimeout(clickTimeout);
      clickTimeout = setTimeout(onBlur, 250);
    }
  }

  const onKeyPress = (evt) => {
    if(evt.key === "Enter") {
      onManualSearch();
    }
  }

  const handleChange = (val) => {
    // Prevents leading spaces and double spaces
    onChange(val.replace(/^[ ]+/, "").replace(/[ ]{2,}/g, " "));
  }

  return (
    <div className={classnames("SearchBar", { focused })} onKeyPress={onKeyPress}>
      <Input
        className="SearchBar__input"
        value={value}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={handleBlur}
        placeholder="Search Player"
      />
      <div className="SearchBar__icon" onClick={onIconClick}><SVG.Search /></div>
      <div className="SearchBar__close" onClick={onClose}><SVG.Close /></div>
    </div>
  );
};

export default SearchBar;

import React from "react";
import _ from "lodash";
import { Image } from "../../../assets/images";
import { Constants } from "../../../utilities";
import MobileSubMenuItem from "./MobileSubMenuItem";
import classnames from "classnames";
import "./MobileSubMenu.scss";



export default class MobileSubMenu extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      navStack: [props.navItem || {}],
      click: undefined
    }
  }

  componentDidUpdate(prevProps) {
    if(!prevProps.navItem && this.props.navItem) {
      this.setState({ navStack: [this.props.navItem] });
    }
  }
  onClose = () => {
    const { onClose } = this.props;
    onClose && onClose();
  }

  onBack = () => {
    this.setState({
      navStack: _.dropRight(this.state.navStack, 1)
    })
  }

  onNavigate = (navItem) => {
    this.setState({
      navStack: _.concat(this.state.navStack, navItem)
    })
  }

  render() {
    const { navStack } = this.state;
    const navItem = navStack[navStack.length - 1];
    const isRoot = navStack.length === 1;
    const allImages = navItem.children && _.findIndex(navItem.children, (item) => item.image === undefined || item.image === null) === -1;


    return (
      <div className="MobileSubMenu">
        <div className="MobileSubMenu__header">
        <div className="MobileSubMenu__header-close" onClick={this.onClose}><img src={Image.Close} alt="close" /></div>
          <div className="MobileSubMenu__header-title" onClick={this.onClose}>Close</div>
        </div>
        <div className={classnames("MobileSubMenu__contents", { images: allImages && !isRoot })}>
        { navItem.children && navItem.children.map((child, i) => (
          <div className="MobileSubMenu__card" key={i}>
            <div className="MobileSubMenu__card__title">{child.name}</div>
            <div className={![Constants.Channels, Constants.Categories].includes(child.name) ? "MobileSubMenu__card__body Center" : "MobileSubMenu__card__body"}>
              { child.children && child.children.map((item, index) => (
                <MobileSubMenuItem key={index} parent={child.name} click={this.state.click} 
                  setClick={(value) => this.setState({click:value})} index={index} navItem={item} 
                  onNavigate={this.onNavigate} props={this.props.props} />
              ))}
            </div>
          </div>
        ))}
        </div>
      </div>
    )
  }
}

import React from "react";
import Modal from "../Modal";
import { Button, SubscribeButton } from "../../Buttons";
import "./UpgradeModal.scss";

const UpgradeModal: React.FunctionComponent<any> = ({ onClose }) => {
  return (
    <Modal onClose={onClose}>
      {(onClose) => (
        <div className="UpgradeModal">
        <div className="UpgradeModal__header">Upgrade to get this content</div>
          <div className="UpgradeModal__message">You currently don't have the right pack to watch this content</div>
          <SubscribeButton text="Upgrade now" upgrade={true} />
          <Button text="Upgrade later" white onClick={onClose} />
        </div>
      )}
    </Modal>
  )
};

export default UpgradeModal;

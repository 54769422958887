import React from "react";
import { Button, ToggleButton } from "../../../components/Buttons";
import { withStore } from "../../../store";
import { Browser, Analytics } from "../../../utilities";
import classnames from "classnames";
import "./VideoSettings.scss";

const QualityOptions = [
  { label: "Standard (SD)", value: "SD" },
  { label: "Best (HD)", value: "HD" }
];

class VideoSettings extends React.Component<any, any> {
  componentDidMount() {
    Analytics.pageViewSettings();
  }

  render() {
    const { updateSettings, updateMultipleSettings, store: { settings } } = this.props;
    const {
      autoplay,
      quality,
      subtitles,
      language,
      audio
    } = settings;
    const LanguageOptions = this.props.store.subtitlelanguages || [];
    let audioOptions = this.props.store.audiolanguages || [] ;

    return (
      <div className="VideoSettings">
        <div className="VideoSettings__header">Video</div>

        <div className="VideoSettings__label autoplay">Autoplay</div>
        <div className="VideoSettings__content autoplay">
          <ToggleButton on={autoplay} onClick={(on) => updateSettings("autoplay", on)} />
          <div>After you finish watching an episode, the next one plays automatically.</div>
        </div>

        {!Browser.isSafari() &&
          <React.Fragment>
            <div className="VideoSettings__label quality">Playback quality</div>
            <div className="VideoSettings__content quality">
              {QualityOptions.map(option => (
                <Button
                  key={option.value}
                  text={option.label}
                  outlined
                  selected={quality === option.value}
                  onClick={() => updateSettings("quality", option.value)}
                />
              ))}
            </div>
          </React.Fragment>
        }
        <React.Fragment>
          <div className="VideoSettings__label audio">Audio language</div>
          <div className="VideoSettings__content  audio">
            {audioOptions.map(option => {
              return (
                <Button
                  key={option.iso1}
                  text={option.label}
                  outlined
                  selected={audio === option.iso1}
                  onClick={() => updateSettings("audio", option.iso1)}
                />
              )
            })}
          </div>
          <React.Fragment>
            <div className="VideoSettings__content  audio audio-menu"></div>
            <div className="VideoSettings__content  audio audio-menu"></div>
          </React.Fragment>
        </React.Fragment>

        <div className="VideoSettings__header">Subtitles</div>

        <div className="VideoSettings__label subtitles">Subtitles</div>
        <div className={classnames("VideoSettings__content subtitles", LanguageOptions.length > 0 ? '' : "disable_toggle")}>
          <ToggleButton className="disable_toggle" on={subtitles && LanguageOptions.length > 0} onClick={(on) => {
            updateSettings("subtitles", on);
            updateSettings("language", "")
            if (on) {
              // if en is configured in AC, select en on toggle on
              LanguageOptions.forEach((option) => {
                if (option.iso1 == "en") {
                  updateSettings("language", "en")
                }
              })

            }
          }} />
        </div>

        {subtitles && LanguageOptions.length > 0 &&
          <React.Fragment>
            <div className="VideoSettings__label language">Language</div>
            <div className="VideoSettings__content language">
              {LanguageOptions.map(option => {
                return (
                  <Button
                    key={option.iso1}
                    text={option.label}
                    outlined
                    selected={language === option.iso1}
                    onClick={() => updateSettings("language", option.iso1)}
                  />
                )
              })}
            </div>
          </React.Fragment>
        }
      </div>
    )
  }
};

export default withStore(VideoSettings);

import React from "react";
import FAQMalaysia from "./FAQMalaysia";
import FAQSingapore from "./FAQSingapore";
import { withStore } from "../../../store";
import { Analytics } from "../../../utilities";
import "../Basic.scss";

class FAQ extends React.Component<any, any> {
  componentDidMount() {
    Analytics.pageViewBasic();
  }
  render() {
    const { store: { country } } = this.props;
    return country === "MY" ?
      <FAQMalaysia /> : <FAQSingapore />
  }
}

export default withStore(FAQ);

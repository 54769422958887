import React from "react";
import Button from "./Button";
import { SVG } from "../../assets/images";
import classnames from "classnames";

const CheckButton: React.FunctionComponent<any> = ({ ...props }) => {
  return <Button { ...props } className={classnames("check", props.className)} svgIcon={SVG.Check} />
}

export default CheckButton;

import React from "react";
import { SVG } from "../../../assets/images";
import Modal from "../Modal";
import { Button } from "../../Buttons";
import { Analytics } from "../../../utilities";
import "./SettingsSuccessModal.scss";

export default class SettingsSuccessModal extends React.Component<any, any> {
  componentDidMount() {
    Analytics.pageViewPinSuccess();
  }

  render() {
    const { onClose } = this.props;
    return (
      <Modal shouldCloseOnEsc={false} shouldCloseOnOverlayClick={false} onClose={onClose} >
        <div className="SettingsSuccessModal">
          <SVG.Check /><span>Your Settings have successfully been updated</span>
          <Button text="Continue" onClick={onClose} />
        </div>
      </Modal>
    )
  }
};

import React from "react";
import Carousel from "../../../Carousel/Carousel";
import HeaderBar from "../HeaderBar/HeaderBar";
import "./Recommendations.scss";

const Recommendations: React.FunctionComponent<any> = ({ contentItem, onClose, onClick }) => {
  return (
    <React.Fragment>
      <HeaderBar contentItem={contentItem} onClose={onClose} />
      <Carousel player component={contentItem.recommendations} onClick={onClick} />
    </React.Fragment>
  )
};

export default Recommendations;

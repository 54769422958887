import React, { useEffect } from "react";
import { getAvailability, withAuthorization } from "../../../utilities";
import MultiLineDiv from "../../MultiLineDiv/MultiLineDiv";
import { PlayButton } from "../../Buttons";
import { PlayImage } from "../../ReactImage";
import ProgressBar from "../../ProgressBar/ProgressBar";
import { withStore } from "../../../store";
import classnames from "classnames";
import { Image } from "../../../assets/images";
import "./ListItem.scss";

const AuthorizedMultiLineDiv = withAuthorization(MultiLineDiv);

const ListItem: React.FunctionComponent<any> = ({ contentItem, store, setIsAbroadFlag, enableAbroadBanner }) => {
  const { settings: { quality }, country, isAbroad } = store;
  const {
    progress = -1,
    tile,
    name,
    duration,
    description,
    certification,
  } = contentItem;
  const { image } = tile;
  const meta = getAvailability(contentItem);
  const inProgress = progress !== null && progress >= 0;

  //  useEffect(() => {
  //   console.log('**************** INSIDE USEEFFECT OF LISTITEM ****************');
  //   const isBannerElement = document.querySelector<HTMLElement>(".app-body .abroad-alert");
  //   if(isBannerElement){
  //     console.log("FOUND BANNER COMPONENT");
  //     //isBannerElement.remove();
  //     isBannerElement.style.display = "none";
  //     console.log('DELETED BANNER COMPONENT!!!');
  //   }
  // }, []);

 

  // const onclickHandler = async () => {
  //   // if(!["MY", "SG"].includes(country)? true : false){
  //   //   await setIsAbroadFlag(true);
  //   //   console.log("Abroad check passed");
  //   // }else{
  //   //   console.log("Abroad check failed");
  //   //   await setIsAbroadFlag(false);
  //   // }
  //   if(isAbroad){
  //     enableAbroadBanner();
  //   }
  //   // console.log("GETTING STORE VALUE FROM LISTITEM COMPONENT => ", store);
  // }

  // const countryCheck = !["MY", "SG"].includes(country)? true : false;

  return (
    <div className={classnames("ListItem", { "in-progress": inProgress })}>
      <div className="ListItem__image">
        <PlayButton small className="ListItem__play-button" contentItem={contentItem} enableAbroadBanner={enableAbroadBanner} />
        {image && image !== undefined && image.url !== null && (
          <PlayImage alt="list"
            contentItem={contentItem}
            src={ image.url }
            // src={ countryCheck ? Image.SmallFallback : image.url}
            onClick={enableAbroadBanner}            
          />
        )}       
         {/* <PlayImage alt="list" src={image && image.url} contentItem={contentItem} onClick={onclickHandler}/> */}
        { inProgress && <ProgressBar progress={progress} /> }
      </div>
      <AuthorizedMultiLineDiv className="ListItem__header" maxLines="3" contentItem={contentItem}>
        {name}
      </AuthorizedMultiLineDiv>
      <div className="ListItem__subheader">
        <div className="ListItem__duration">{Math.floor(duration / 60) + " min"}</div>
        { certification && <div className="ListItem__rating">{certification.name}</div> }
        <div className="ListItem__hd">{quality}</div>
      </div>
      <MultiLineDiv className="ListItem__description" maxLines="3">{description}</MultiLineDiv>
      <div className="ListItem__meta">{meta}</div>
    </div>
  )
}

export default withStore(ListItem);

import React from "react";
import { Fetch } from "../../../components/Fetch";
import { Button } from "../../../components/Buttons";
import { withStore } from "../../../store";
import { Constants, Analytics, getStorageItem } from "../../../utilities";
import "./SignOut.scss";

const SignOut: React.FunctionComponent<any> = ({ signOut }) => {
  const operator = getStorageItem("operator", true);
  Analytics.pageViewSettings();
  return (
    <div className="SignOut">
      <div className="Settings__header">
        By signing out of your account, your rating level and PG PIN will be reset.
        To continue, click the button below.
      </div>
      <div className="Settings__subheader">To watch content you will have to sign back in again</div>
      <Fetch
          url={Constants.SignOutUrl}
          method="POST"
          body={{ AuthLogoutUserRequestMessage: { operator : operator} }}
          includeCredentials
          onSuccess={signOut}
          manual
        >
          {(loading, errors, data, status, fetch) => (
            <Button text="Sign me out" className="signout" onClick={fetch} />
          )}
        </Fetch>
    </div>
  )
};

export default withStore(SignOut);

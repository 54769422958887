import React from "react";
import { SVG } from "../../../../assets/images";
import "./HeaderBar.scss";

const HeaderBar: React.FunctionComponent<any> = ({ contentItem, onClose }) => {
  return (
    <div className="PlayerHeaderBar">
      <div className="PlayerHeaderBar__header">
        <div className="PlayerHeaderBar__title" tabIndex={0}>{ contentItem.openLivePlayer ? contentItem.name : contentItem.series && contentItem.series.name}</div>
        { onClose &&
          <div className="PlayerHeaderBar__close-button" onClick={onClose} tabIndex={0}><SVG.Close /></div>
        }
      </div>
      <div className="PlayerHeaderBar__description" tabIndex={0}>
        { contentItem.openLivePlayer && `Series ${contentItem.seriesNumber}: ${contentItem.episodeNumber}. ${contentItem.episodeTitle}`}
        { !contentItem.openLivePlayer &&`Series ${contentItem.seasonNumber}: ${contentItem.episodeNumber}. ${contentItem.name}`}
      </div>
    </div>
  )
};

export default HeaderBar;

import React, {useState} from "react";
import ContentFetch from "../../components/Fetch/ContentFetch";
import { Helmet } from "react-helmet";
import TvGuideSchedule from "./TvGuideSchedule";
import TvGuideChannelList from "./TvGuideChannelList";
import { SignInModal, UpgradeModal, TrialProgressModal } from "../../components/Modals";
import { withStore } from "../../store";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { Analytics } from "../../utilities";
import "./TvGuide.scss"
import { Constants, AuthorizeLiveplayer } from "../../utilities";

const TvGuide: React.FunctionComponent<any> = (props) => {
  let path = props.location.pathname.split('/');
  path = path[path.length-1];
  let channelIndex = Constants.TvGuidePaths.indexOf(path)
  const [selected, setSelected] = useState (channelIndex > -1 ? channelIndex : 0);
  const [showPrompt, setshowPrompt] = useState("");

  const handleLiveClick = async (channel) => {
    AuthorizeLiveplayer(
      props.isAbroad,
      props.enableAbroadBanner,
      setshowPrompt,
      props.store.country,
      Constants.LiveChannels[selected],
      channel,
      props.playLiveVideo
      );
  }

  return (
    <ContentFetch onComplete={Analytics.pageViewTvGuide}>
      { (error, channels) => (
        (!channels || error) ?
        null :
        <div>
          <Helmet>
            <title>{`BBC Player | Tv Guide`}</title>
          </Helmet>
          { channels &&
            <React.Fragment>
              <div className="Tv_Guide">
                <div className="Tv_Guide_Header"><span>TV Guide</span></div>
                <TvGuideChannelList channels={channels} selected={selected} setSelected={setSelected}></TvGuideChannelList>
                <TvGuideSchedule handleLiveClick={handleLiveClick} channelIndex={selected} channel={channels[selected]}></TvGuideSchedule>
              </div>
              { showPrompt === Constants.Prompts[0] && <SignInModal onClose={() => {setshowPrompt("")}} /> }
              { showPrompt === Constants.Prompts[2] && <TrialProgressModal onClose={() => {setshowPrompt("")}} /> }
              { showPrompt === Constants.Prompts[1] && <UpgradeModal onClose={() => setshowPrompt("")} /> }
            </React.Fragment> }
        </div>
      ) }
    </ContentFetch>
  )
}

export default compose<any>(withRouter, withStore)(TvGuide);

import React, { useState, useEffect } from "react";
import { SVG } from "../../assets/images";
import classnames from "classnames";
import "./ImageWordButton.scss";

const ImageWordButton: React.FunctionComponent<any> = ({ unselect, click, border, schedule, className }) => {
  const [ selected, setSelected ] = useState(false) ;

  useEffect(() => {
    if(unselect) {
      setSelected(false);
    }
  }, [unselect]);

  const onclick = () => {
    setSelected(true);
    click && click();
  }
    
  return (
    <button 
      className={classnames( `ImageWordButton ${className}`, { selected: selected, border: border })} 
      onClick={onclick}>
        { !schedule && <SVG.PlayButton className={selected ? "selected" : ""}></SVG.PlayButton> }
        { schedule && <SVG.Calender className={selected ? "selected" : ""}></SVG.Calender> }
        <span>{schedule ? `${schedule} schedule` : "Watch Live"}</span>
    </button>
  )
}

export default ImageWordButton;

import React from "react";
import MultiLineDiv from "../MultiLineDiv/MultiLineDiv";
import { PlayButton, Button } from "../Buttons";
import { ReactImage } from "../ReactImage";
import Border from "../Border/Border";
import { getContentItemMetadata } from "../../utilities";
import _ from "lodash";
import "./Hero.scss";
import { withRouter } from "react-router-dom";

const Hero: React.FunctionComponent<any> = ({ component, history, category, position, enableAbroadBanner }) => {
  const contentItem = _.get(component, "collection.items.nodes[0]");
  const { slide, firstEpisode } = contentItem;
  const { header, subHeader, description, episodesUrl } = getContentItemMetadata(contentItem, "Hero");
  const image = slide.image;
  const contentItemToPlay = firstEpisode || contentItem;
  const headerLines = category ? 2 : 3;
  const descriptionCharacters = 90;

  return (
    <div className="Hero">
      { (category && position === 1) && <div className="Hero__meta-category mobileonly">{category}</div> }
      
      <div className="Hero__meta">
        { (category && category !== "Home" && position === 1) && <div className="Hero__meta-category non-mobileonly">{category}</div> }
        <MultiLineDiv className="Hero__meta-header" maxLines={headerLines}>{header}</MultiLineDiv>
        <MultiLineDiv maxLines={1} className="Hero__meta-subheader">{subHeader}</MultiLineDiv>
        <MultiLineDiv className="Hero__meta-description" maxCharacters={descriptionCharacters}>{description}</MultiLineDiv>
        <div className="Hero__meta-buttons">
          <PlayButton contentItem={contentItemToPlay} enableAbroadBanner={enableAbroadBanner}/>
          { episodesUrl &&
            <Button text="View Episodes" outlined onClick={() => {
              console.log("episodesUrl ===> ", episodesUrl);
              history.push(episodesUrl)
            } } />
          }
        </div>
      </div>
      <div className="Hero__image">
        <div className="Hero__image-hori-gradient" />
        <ReactImage large src={image && image.url} alt="hero" />
      </div>
      <Border />
    </div>
  )
};

export default withRouter(Hero);

import React from "react";
import Button from "./Button";
import { SVG } from "../../assets/images";
import classnames from "classnames";

const LockButton: React.FunctionComponent<any> = ({ ...props }) => {
  return <Button { ...props } className={classnames("lock", props.className)} svgIcon={SVG.Lock} />
}

export default LockButton;

import React from "react";
import { Button } from "../../../Buttons";
import { SVG } from "../../../../assets/images";
import "./ContentRatingOverlay.scss";

const ContentRatingOverlay: React.FunctionComponent<any> = ({ contentItem, player, goBack, autoplayed }) => {
  const certification = contentItem.certification;
  const shouldDisplay = !autoplayed && (certification && certification.age);

  const onContinue = () => {
    const overlayEl = document.querySelector(".vjs-content-rating");
    overlayEl && overlayEl.classList.add("nodisplay");
    if(contentItem.progress) {
      player.currentTime(contentItem.progress * player.duration());
    }
    player.play().catch(err => {});
  };
  const onClose = () => {
    goBack();
  };

  if(!shouldDisplay) {
    onContinue();
  }

  return (
    shouldDisplay ?
      <div className="ContentRatingOverlay">
        <div className="ContentRatingOverlay__poster">
          <img src={player.mediainfo.poster} alt="poster" />
        </div>
        <div className="ContentRatingOverlay__tint" />
        <div className="ContentRatingOverlay__close" onClick={onClose}><SVG.Close /></div>
        <div className="ContentRatingOverlay__rating-info">
          <div className="ContentRatingOverlay__rating-name">{certification.name}</div>
          <div className="ContentRatingOverlay__rating-description">{certification.description}</div>
        </div>
        <Button text="Continue" onClick={onContinue} />
        <Button text="Go back" outlined onClick={onClose} />
      </div>
      : null
  )
};

export default ContentRatingOverlay;

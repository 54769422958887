import React from "react";
import MultiLineDiv from "../MultiLineDiv/MultiLineDiv";
import { getContentItemMetadata, Analytics, getFullUrl } from "../../utilities";
import { ReactImage } from "../ReactImage";
import "./Tile.scss";

const RecommendationTile: React.FunctionComponent<any> = ({ contentItem, onClick, ...props }) => {
  const { containerTitle, position } = props;
  const { tile } = contentItem;
  const { name } = contentItem;
  const { image } = tile;
  const { header, meta1, meta2, url } = getContentItemMetadata(contentItem, "Tile");
  const onClickFn = () => {
    Analytics.userActionSelectContentFromRail(containerTitle, position, getFullUrl(url));
    Analytics.userActionSelectRecommendationFromRail(position, name || header)
    onClick(url);
  };

  return (
    <div className="Tile">
      <div className="Tile__image">
        <ReactImage src={image && image.url} onClick={onClickFn} />
      </div>
      <MultiLineDiv className="Tile__header" maxLines={3} onClick={onClickFn}>{header}</MultiLineDiv>
      <div className="Tile__meta1">{meta1}</div>
      <div className="Tile__meta2">{meta2}</div>
    </div>
  )
}

export default RecommendationTile;

import {
  UPDATE_SETTINGS,
  UPDATE_MULTIPLE_SETTINGS,
  SIGN_OUT,
  SET_COUNTRY,
  PLAY_VIDEO,
  DISMISS_VIDEO,
  PLAY_LIVE_VIDEO,
  DISMISS_LIVE_VIDEO,
  SIGN_IN,
  SET_DESKTOP_FLAG,
  SET_ISABROAD_FLAG,
  SET_SUPPORTED_SUBTITLE_LANGUAGES_FLAG,
  SET_SUPPORTED_AUDIO_LANGUAGES_FLAG
} from "./actions";
import { StoreState, GenericAction } from "./types";
import { User, getStorageItem, setStorageItem, Analytics } from "../utilities";

export const defaultState = () => {
  const state = {
    signedIn: User.authenticated(),
    country: undefined,
    isAbroad: false,
    settings: {
      autoplay: true,
      quality: "HD",
      subtitles: false,
      language: "en",
      audio: "en",
      pin: null,
      rating: null,
      securityQuestion: null,
      securityAnswer: null
    },
    video: undefined,
    onDesktop: true,
    audiolanguages: undefined,
    subtitlelanguages: undefined
  };

  let existingSettings = getStorageItem("settings", true, true);
  if(existingSettings) {
    state.settings = { ...state.settings, ...existingSettings };
  }
  else {
    setStorageItem("settings", state.settings, true, true);
  }

  if(state.signedIn) {
    Analytics.userStatusLoggedIn(User.id())
  }

  return state;
};

export default function (state: StoreState = defaultState(), action: GenericAction): StoreState {
  switch (action.type) {
    case UPDATE_SETTINGS: {
      const { key, value } = action.data;
      const updatedSettings = { ...state.settings, [key]: value };
      setStorageItem("settings", updatedSettings, true, true);
      return { ...state, settings: updatedSettings }
    }

    case UPDATE_MULTIPLE_SETTINGS: {
      const updatedSettings = { ...state.settings, ...action.data };
      setStorageItem("settings", updatedSettings, true, true);
      return { ...state, settings: updatedSettings }
    }

    case SIGN_OUT:
      const updatedSettings = {
        ...state.settings,
        pin: null,
        rating: null,
        securityQuestion: null,
        securityAnswer: null
      };
      setStorageItem("settings", updatedSettings, true, true);
      User.clearData();
      Analytics.userStatusLoggedOut();
      return { ...defaultState(), settings: updatedSettings }

    case SET_COUNTRY:
      const country = action.data;
      setStorageItem("country", country, true);
      return { ...state, country };

    case PLAY_VIDEO:
      return { ...state, video: action.data };

    case DISMISS_VIDEO:
      return { ...state, video: undefined };

    case PLAY_LIVE_VIDEO:
      return { ...state, video: action.data };

    case DISMISS_LIVE_VIDEO:
      return { ...state, video: undefined };

    case SIGN_IN:
      const { accessToken, tokenType, expiresIn } = action.data;
      User.setTokens(accessToken, tokenType, expiresIn);
      if(!state.signedIn) {
        Analytics.userStatusLoggedIn(User.id());
      }
      return { ...state, signedIn: true };

    case SET_DESKTOP_FLAG:
      return { ...state, onDesktop: action.data === "true" };
    case SET_ISABROAD_FLAG: {
      console.log('SET_ISABROAD_FLAG => ', action.data);
      return{ ...state, isAbroad: action.data }
    }      
    case SET_SUPPORTED_SUBTITLE_LANGUAGES_FLAG:
      return { ...state, subtitlelanguages: action.data };       
    case SET_SUPPORTED_AUDIO_LANGUAGES_FLAG:
      return { ...state, audiolanguages: action.data };
    default:
      return state;
  }
};

import React from "react";
import { withRouter } from "react-router-dom";
import { getUrl, Constants } from "../../utilities";
import { SVG } from "../../assets/images";
import classnames from "classnames";
import "./MenuTab.scss";

const MenuTab: React.FunctionComponent<any> = ({ navItem, location, history, className: customClassName, toggle, hideToggle }) => {
  const { name, target, image, children, showImage, onClick: customOnClick } = navItem;
  const className = classnames(
    "MenuTab",
    { selected: location && location.pathname.includes(target) },
    customClassName
  );

  const getLiveDetails = async() => {
    let url = `${process.env.REACT_APP_MIDDLEWARE_URL}/channels/schedule/`; 
    try {
      let res = await fetch( url,{
        method: "GET",
        headers: {
          "content-type": "application/json",
          "x-platform": "WEB"
        }
      } );
      if ( res && res.ok ) {
        let data = await res.json()
        return data
      } else {
        console.log( "error occured" )
      }
    } catch ( error ) {
      console.log( error )
    }
  }

  const onClick = async() => {
    if(name === Constants.Menu) {
      let data = await getLiveDetails()
      let liveIndex = navItem.children.findIndex(ele => ele.name === Constants.Live);
      if(liveIndex > -1) {
        navItem.children[liveIndex].children = data;
      }
    }
    if (name === Constants.Live) {
      let data = await getLiveDetails()
      navItem.children = data;
      toggle(navItem)
    } else {
      customOnClick ?
      customOnClick() :
      children && children.length ?
        toggle(navItem):
        history.push(getUrl(target));
    }
  }

  return (
    <div className={className} onClick={onClick}>
      { name === "Settings" ? <SVG.Settings></SVG.Settings> :
        showImage && image ?
        <img src={image.url} alt="icon" /> :
        <span>{name}</span>
      }
      { ((children && !hideToggle) || name === Constants.Live) && <span className="MenuTab__arrow" dangerouslySetInnerHTML={{__html: "&#9660;"}} /> }
    </div>
  )
};

export default withRouter(MenuTab);

import React from "react";
import Button from "./Button";
import { SVG } from "../../assets/images";
import { withAuthorization } from "../../utilities";
import classnames from "classnames";
import { withStore } from "../../store";

const PlayButton: React.FunctionComponent<any> = ({ small, className, contentItem, enableAbroadBanner, store: { isAbroad } }) => {
  const AuthorizedButton = withAuthorization(Button);
  const handlePlay = () => {
    if(isAbroad){
      enableAbroadBanner();
      window.scrollTo(0, 0);//For scroll to the top
    }  
  }
  return <AuthorizedButton text="Play" className={classnames("play", { small }, [className])} outlined={false} svgIcon={SVG.Play} contentItem={contentItem}  onClick={handlePlay}/>
};

export default withStore(PlayButton);

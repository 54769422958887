import React from "react";
import { withStore } from "../../../store";
import { withRouter } from "react-router";
import { compose } from "redux";
import PinInputModal from "./PinInputModal";
import { getStorageItem, getCertifications } from "../../../utilities";
import ParentalLockModal from "./ParentalLockModal";

class RatingCheckModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      showPinInput: false,
      showLock: false
    }
  };

  async componentDidMount() {
    const { store, contentItem, onClose } = this.props;
    const certifications = (await getCertifications()).map(certification => certification.name);
    const { settings: { pin, rating: allowedRating } } = store;
    const contentRating = (contentItem.certification && contentItem.certification.name) || certifications[0];
    const unrestrictedContent = contentRating === certifications[0];
    const restrictedContent = contentRating === certifications[certifications.length - 1];
    const hideParentalLock = getStorageItem("hidePLM") === "true";

    let showPinInput, showLock, onComplete;

    if(unrestrictedContent) {
      this.playVideo();
    }
    else if(restrictedContent) {
      if(pin) {
        showPinInput = true;
        onComplete = () => {
          this.setState({ showPinInput: false }, this.playVideo);
        };
      }
      else {
        showLock = true;
        onComplete = () => {
          this.setState({ showLock: false }, onClose);
        };
      }
    }
    else {
      if(pin) {
        if(certifications.indexOf(contentRating) >= certifications.indexOf(allowedRating)) {
          showPinInput = true;
          onComplete = () => {
            this.setState({ showPinInput: false }, this.playVideo);
          };
        }
        else {
          this.playVideo();
        }
      }
      else {
        if(hideParentalLock) {
          this.playVideo();
        }
        else {
          showLock = true;
          onComplete = () => {
            this.setState({ showLock: false }, this.playVideo);
          };
        }
      }
    }

    if(showPinInput || showLock) {
      this.setState({
        showPinInput,
        onComplete,
        showLock,
        isRestricted: restrictedContent
      });
    }
  }

  playVideo = () => {
    const { onClose, onPlay, playVideo, contentItem } = this.props;
    if(onPlay) {
      onPlay();
    }
    else {
      onClose && onClose();
      playVideo(contentItem);
    }
  }

  render() {
    const { onClose } = this.props;
    const { showPinInput, showLock, isRestricted, onComplete } = this.state;

    return (
      showPinInput ?
        <PinInputModal onComplete={onComplete} onClose={onClose} />
      : showLock ?
        <ParentalLockModal isRestricted={isRestricted} onContinue={onComplete} onClose={onClose} />
      : null
    )
  }
};

export default compose<any>(withRouter, withStore)(RatingCheckModal);

import React from "react";
import TermsMalaysia from "./TermsMalaysia";
import TermsSingapore from "./TermsSingapore";
import { withStore } from "../../../store";
import { Analytics } from "../../../utilities";
import "../Basic.scss";

class Terms extends React.Component<any, any> {
  componentDidMount() {
    Analytics.pageViewBasic();
  }
  render() {
    const { store: { country } } = this.props;
    return country === "MY" ?
    <TermsMalaysia /> : <TermsSingapore />
  }
}

export default withStore(Terms);

import React from 'react'
import ReactDOM from "react-dom";
import { compose } from "redux";
import { withStore } from "../../store";
import { withRouter } from "react-router";
import { SVG, Image } from "../../assets/images";
import ReactPlayerLoader from '@brightcove/react-player-loader';
import HeaderBar from "../Overlays/PlayerOverlay/HeaderBar/HeaderBar";
import { Browser, Analytics, isLive } from "../../utilities";
import ErrorPortal from "../Error/Error";
import PlayerLoadingOverlay from "../Overlays/PlayerOverlay/PlayerLoadingOverlay/PlayerLoadingOverlay";
import '../Overlays/PlayerOverlay/Player.scss'
import _ from "lodash";


const inactiveTime = 4000;

class LivePlayer extends React.Component<any, any> {
  player;
  firstClick;
  subtitlesInitialized
  loadingContainer;
  setMouseMoveTimeout;
  mouseMoveCheckerTimeout;
  clearMouseMoveTimeout;
  resetMouseMoveTimeout;
  removeMouseMoveTimeout;
  constructor(props) {
    super(props);
    this.state = {
      video: props.store.video
    }
    this.firstClick = false;

  }

  /*******************************
 * LIFECYCLE EVENTS
 *******************************/

  componentDidMount() {
    this.toggleScrollLock(true);
    this.showLoading();
    if(this.state.video.error) {
      this.hideLoading();
      this.onError();
    }
    Analytics.pageViewLivePlayer()
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    this.cleanUp();
  }

  /*******************************
   * HELPER FUNCTIONS
   *******************************/

  endTime = (epoch) => {
    const startTime = new Date(this.state.video.startTime_utc)
    return epoch ? Math.floor( startTime.getTime() / 1000 ) + (this.state.video.duration * 60)
    : startTime.setMinutes(startTime.getMinutes() + this.state.video.duration);
  }

  completeProgress = () => {
    Analytics.mediaReportEnd(true);
  }

  isLiveComplete = () => {
    const now = Math.floor( new Date().getTime() / 1000 );
    const endTime = this.endTime(true);
    return now > endTime
  }

  showLoading = () => {
    ReactDOM.render(
      <PlayerLoadingOverlay className="Player__loading" contentItem={this.state.video} />,
      this.loadingContainer
    );
    this.loadingContainer.classList.remove("nodisplay");
  }

  hideLoading = () => {
    this.loadingContainer.classList.add("nodisplay");
  }

  cleanUp = () => {
    const { dismissLiveVideo } = this.props;
    Analytics.mediaReportStop();
    dismissLiveVideo();
    this.toggleScrollLock(false);
  }

  toggleScrollLock = (locked) => {
    const body = document.querySelector("body");
    locked ? body!.classList.add("scroll-lock-player") : body!.classList.remove("scroll-lock-player");
  }

  dismiss = () => {
    const { history } = this.props;
    history.goBack();
  }

  createDiv = (className, ...rest) => {
    return (new DOMParser()).parseFromString(`<div class="${className}" tabindex=${rest[0] ? "-1" : "0"} aria-label=${rest[1]?rest[1]:""} />`, "text/html").body.firstChild as HTMLElement;
  }

  getNextContentDetails = () => {
    const currentLiveContent = this.state.video.schedules.find( item => isLive(item) )!
    let updatedState = this.state.video
    updatedState.name = currentLiveContent.name;
    updatedState.duration = currentLiveContent.duration;
    updatedState.episodeNumber = currentLiveContent.episodeNumber;
    updatedState.episodeSynopsis = currentLiveContent.episodeSynopsis;
    updatedState.episodeTitle = currentLiveContent.episodeTitle;
    updatedState.seriesNumber = currentLiveContent.seriesNumber;
    updatedState.startTime = currentLiveContent.startTime;
    updatedState.startTime_utc = currentLiveContent.startTime_utc;
    updatedState.synopsis = currentLiveContent.synopsis;
    updatedState.extId = currentLiveContent.houseid;
    this.setState({
      video: updatedState
    })
  }

  setupSubtitles = () => {
    const { store: { settings } } = this.props;
    const { subtitles, language } = settings;
    const textTracks = this.player.textTracks();
    const subtitleSection = document.querySelector("div.vjs-subs-caps-button")!;
    const subtitleButton = subtitleSection.querySelector("button")!;
    const subtitlesOffButton = subtitleSection!.querySelector(".vjs-menu-item:nth-of-type(2)")!;

    if(!textTracks.length)
      subtitleSection.classList.add("nodisplay");
    else {
      // Change text of buttons
      subtitleButton.setAttribute("title", "Subtitles");
      if (!Browser.isSafari())
        subtitlesOffButton.querySelector("span")!.textContent = "Subtitles off";

      // Add listener so button is properly highlighted when subs are on/off
      textTracks.on("change", () => {
        let subtitlesOn = _.filter(textTracks, track => track.mode === "showing").length;

        if(subtitlesOn)
          subtitleButton.classList.add("selected");
        else
          subtitleButton.classList.remove("selected");
      });

      // Set default language
      if(subtitles) {
        for (let i = 0; i < textTracks.length; i++) {
          if (textTracks[i].language === language) {
            this.subtitlesInitialized = true;
            subtitleButton.classList.add("selected");
            textTracks[i].mode = "showing";
            break;
          }
          else {
            textTracks[i].mode = "disabled";
          }
        }
      }
    }
  }

  checkQualitySetting = () => {
    if(Browser.isSafari())
      return;

    const { store: { settings } } = this.props;
    const { quality } = settings;

    try {
      this.player.tech().hls.representations().forEach(rep => {
        const enabled = (quality === "SD" && rep.height < 720) || (quality === "HD" && rep.height >= 720);
        rep.enabled(enabled);
      });
    } catch(err) {
      console.log("Quality selection isn't supported in this browser.");
    }
  }

  getProgressHandlePosition = () => {
    const handleEl_ = document.querySelector(".vjs-progress-handle")!;
    const seekele_ = document.querySelector(".vjs-progress-holder")!;
    const { width } = seekele_.getBoundingClientRect();
    return `${(width) - (handleEl_.clientWidth / 2)}px`;
  }

  onError = () => {
    const { history } = this.props;
    ErrorPortal.error(undefined, () => history.goBack(), "Try again");
  }

  /*******************************
 * GENERAL PLAYER UI
 *******************************/

  createMouseEventListeners = () => {
    const controlBarEl_ = this.player.controlBar.el_;

    this.setMouseMoveTimeout = () => {
      this.mouseMoveCheckerTimeout = setTimeout(() => {
        controlBarEl_.classList.add("inactive");
      }, inactiveTime);
    }

    this.clearMouseMoveTimeout = () => {
      clearTimeout(this.mouseMoveCheckerTimeout);
      this.mouseMoveCheckerTimeout = null;
      controlBarEl_.classList.remove("inactive");
    }

    this.resetMouseMoveTimeout = () => {
      this.clearMouseMoveTimeout();
      this.setMouseMoveTimeout();
    }

    this.removeMouseMoveTimeout = () => {
      clearTimeout(this.mouseMoveCheckerTimeout);
      this.mouseMoveCheckerTimeout = null;
      controlBarEl_.classList.add("inactive");
    }

    this.player.controlBar.el_.addEventListener("mousemove", this.resetMouseMoveTimeout);
    this.player.controlBar.el_.addEventListener("keydown", (e) =>{
      if (e.keyCode === 9) {
        try{
          this.resetMouseMoveTimeout();
        }catch(er){
          console.warn(er, "custom error 2")
        }
      }
      if (e.keyCode === 13) {
        console.log(e);
        try{
          e.currentTarget.click();
        }catch(e){
          console.warn(e,'custom error')
        }
      }
     }
     );
    this.player.on("loadeddata", this.setMouseMoveTimeout);
  }

  createMidBlockBarComponents = () => {
    const controlBarEl_ = this.player.controlBar.el_;
    const midBlockDiv = this.createDiv("Video-mid-block");
    const buttonGroupDiv = this.createDiv("Video-mid-button-group");
    const playButton = this.createDiv("Video-mid-buttons play-button", false, "Play Button");

    ReactDOM.render(
      <SVG.Pause />,
      playButton
    );

    const animatedButton = (button, svgValue, resetRequired, callback) => {
      button.classList.add("play-button-zoom-out");
      setTimeout(() => {
        button.classList.remove("play-button-zoom-out");
        ReactDOM.render(
          <div className='play-button-boundary-circle'>{svgValue}</div>,
          button
        );
        setTimeout(() => {
          if (resetRequired) {
            ReactDOM.render(
              svgValue,
              button
            );
          }
          callback();
        }, 250);
      }, 250);
    }

    const onPlayClick = () => {
      if (this.player.paused()) {
        const callback = () => {
          this.player.play();
        }
        animatedButton(playButton, <SVG.Pause />, false, callback);
      } else {
        const callback = () => {
          this.player.pause();
        }
        animatedButton(playButton, <SVG.LargePlay />, false, callback);
      }
    };

    this.player.on("play", () => {
      playButton.classList.add("playing");

      ReactDOM.render(
        <SVG.Pause />,
        playButton
      );

      if(!this.subtitlesInitialized) {
        this.setupSubtitles();
      }
      Analytics.mediaReportPlay(true);
    });

    this.player.on("pause", () => {
      playButton.classList.remove("playing");

      ReactDOM.render(
        <SVG.LargePlay />,
        playButton
      );

      Analytics.mediaReportPause(true);
    })

    playButton.addEventListener("click", onPlayClick);

    buttonGroupDiv.appendChild(playButton);
    midBlockDiv.appendChild(buttonGroupDiv);

    controlBarEl_.insertBefore(midBlockDiv, controlBarEl_.firstChild);
  }

  createVolumeComponents = () => {
    const controlBar = this.player.controlBar;
    const volumeBarEl_ = controlBar.volumePanel.volumeControl.volumeBar.bar.el_;
    const volumeButtonEl_ = controlBar.volumePanel.muteToggle.el_
    const volumeHandleEl_ = this.createDiv("vjs-volume-handle");
    const volumeIconContainer = document.createElement("div");

    volumeBarEl_.appendChild(volumeHandleEl_);
    volumeButtonEl_.appendChild(volumeIconContainer);

    ReactDOM.render(
      <SVG.Volume />,
      volumeIconContainer
    );

    this.player.on("volumechange", () => {
      ReactDOM.render(
        (this.player.muted() || !this.player.volume()) ? <SVG.VolumeOff /> : <SVG.Volume />,
        volumeIconContainer
      );
    });
  }

  createSeekComponents = () => {
    const videoEl_ = this.player.el_;
    const progressControl = this.player.controlBar.progressControl.el_;
    const seekBarEl_ = this.player.controlBar.progressControl.seekBar.bar.el_;
    const progressHandleEl_ = this.createDiv("vjs-progress-handle");
    const LiveIcon = this.createDiv("")
    progressControl.classList.add("live-progress");
    progressControl.appendChild(LiveIcon)
    seekBarEl_.appendChild(progressHandleEl_);
    ReactDOM.render(
      <SVG.Live />,
      LiveIcon
    );

    this.player.on("timeupdate", () => {
      
      const isComplete = this.isLiveComplete();
      if(!(videoEl_.classList.contains("vjs-waiting") || videoEl_.classList.contains("vjs-seeking")) || videoEl_.classList.contains("vjs-paused")) {
        seekBarEl_.style.width= "100%";
        progressHandleEl_.style.left = this.getProgressHandlePosition();
        progressHandleEl_.style.right = "";
      }

      if(isComplete) {
        this.getNextContentDetails();
        this.createHeaderBar();
        this.completeProgress();
        Analytics.userActionPlayContent(this.state.video, true);
        Analytics.mediaReportInit(this.state.video, this.player, true);
      }
    });
  }

  createLoadingComponent = () => {
    const videoEl_ = this.player.el_;
    const controlBarEl_ = this.player.controlBar.el_;
    const loadingSpinnerEl_ = this.player.loadingSpinner.el_;

    videoEl_.insertBefore(loadingSpinnerEl_, controlBarEl_.nextSibling);

    ReactDOM.render(
      <img src={Image.Loading} alt="loading" />,
      loadingSpinnerEl_
    );
  }

  createTimeDisplayComponent = () => {
    const controlBarEl_ = this.player.controlBar.el_;
    const volumePanelEl_ = this.player.controlBar.volumePanel.el_;
    const timeDisplayContainer = this.createDiv("vjs-time-display live-player");
    controlBarEl_.querySelector(".vjs-current-time")!.style.display = 'none'
    controlBarEl_.querySelector(".vjs-time-divider")!.style.display = 'none'
    controlBarEl_.querySelector(".vjs-duration")!.style.display = 'none'
    const date = new Date(this.state.video.startTime_utc).toString().split(" ");
    const startTimeArray = date[4].split(":");
    timeDisplayContainer.textContent = startTimeArray[0] + ":" + startTimeArray[1];
    controlBarEl_.insertBefore(timeDisplayContainer, volumePanelEl_.nextSibling);
  }

  createFullscreenComponent = () => {
    const containerEl_ = document.querySelector(".rightside-controls")!;
    const controlBar = this.player.controlBar;
    const controlBarEl_ = controlBar.el_;
    const fullscreenButtonEl_ = controlBar.fullscreenToggle.el_;
    const fullscreenIconContainer = document.createElement("div");
    fullscreenButtonEl_.appendChild(fullscreenIconContainer);

    ReactDOM.render(
      <SVG.Fullscreen />,
      fullscreenIconContainer
    );

    this.player.on("fullscreenchange", () => {
      if(this.player.isFullscreen()) {
        ReactDOM.render(
          <SVG.ExitFullscreen />,
          fullscreenIconContainer
        );
      } else {
        ReactDOM.render(
          <SVG.Fullscreen />,
          fullscreenIconContainer
        );
      }
    });

    controlBarEl_.addEventListener("dblclick", () => {
      if(this.player.isFullscreen())
        this.player.exitFullscreen();
      else
        this.player.requestFullscreen();
    });

    containerEl_.append(fullscreenButtonEl_);
  }

  createSubtitlesButton = () => {
    const containerEl_ = document.querySelector(".rightside-controls")!;
    const captionsButtonDiv = document.querySelector("div.vjs-subs-caps-button")!;
    const captionsButtonEl_ = this.player.controlBar.subsCapsButton.menuButton_.el_;
    const captionsIconContainer = this.createDiv("vjs-captions-container")
    captionsButtonEl_.appendChild(captionsIconContainer);

    ReactDOM.render(
      <div>Subtitles<SVG.Subtitles /></div>,
      captionsIconContainer
    );

    containerEl_.insertBefore(captionsButtonDiv, containerEl_.firstChild);
  }

  createControls = () => {
    const rightsideControlsDiv = this.createDiv("vjs-control rightside-controls");
    const controlBarEl_ = this.player.controlBar.el_;
    controlBarEl_.append(rightsideControlsDiv);

    this.createFullscreenComponent();
    this.createSubtitlesButton();
  }

  /*******************************
   * CONTENT SPECIFIC PLAYER UI
   *******************************/

  createHeaderBar = () => {
    const controlBarEl_ = this.player.controlBar.el_;
    const headerBarDiv = document.querySelector(".Video-header-bar") || this.createDiv("Video-header-bar", true);
    controlBarEl_.insertBefore(headerBarDiv, controlBarEl_.firstChild);

    ReactDOM.render(
      <HeaderBar contentItem={this.state.video} onClose={this.dismiss} />,
      headerBarDiv
    );
  }

  /*******************************
 * GENERAL
 *******************************/

  onSuccess = (success) => {
    this.player = success.ref;

    this.createMouseEventListeners(); 
    this.createLoadingComponent();
    this.createMidBlockBarComponents();
    this.createVolumeComponents();
    this.createSeekComponents();
    this.createTimeDisplayComponent();
    this.createControls();

    this.player.on("loadstart", async () => { 
      this.hideLoading();
      if(!this.firstClick) {
        this.firstClick = true;
        this.player.play();
        await Analytics.userActionPlayContent(this.state.video, true);
        Analytics.mediaReportInit(this.state.video, this.player, true);
      } 
      this.checkQualitySetting();
    } );

    this.player.on("error", this.onError);

    this.player.on("ended", () => {
      this.completeProgress();
      this.removeMouseMoveTimeout();
    });

    window.onpopstate = () => {
      this.cleanUp();
    };
    this.createHeaderBar();
  }

  render() {
    return (
      <React.Fragment>
        <ReactPlayerLoader
          refNode="#liveplayer"
          attrs={{ className: "Player" }}
          accountId={process.env.REACT_APP_BRIGHTCOVE_LIVE_ACCOUNT_ID}
          playerId={process.env.REACT_APP_BRIGHTCOVE_LIVE_PLAYER_ID}
          videoId={this.state.video.id}
          onSuccess={this.onSuccess}
          poster={this.state.video.thumbnail}
          onFailure={this.onError}
        />
        <div ref={node => this.loadingContainer = node} className="Player__loading-container" />
      </React.Fragment>
    )
  }
    
  
}
export default compose<any>(withRouter, withStore) (LivePlayer);

import React from "react";
import { Constants } from "../../utilities";
import "./TvGuideScheduleList.scss";
import MediaQuery from "react-responsive";
import TvGuideScheduleItem from "./TvGuideScheduleItem";
import MobileTvGuideScheduleList from "./MobileTvGuideScheduleItem";

const TvGuideScheduleList: React.FunctionComponent<any> = ({channel, schedule, handleLiveClick}) => {

  return (
   <div className="TvGuideScheduleList" >
    <MediaQuery minWidth={Constants.MobileWidth + 1}>
      { schedule && schedule.map( ( item, index) => { 
        return <TvGuideScheduleItem channel={channel} key={index} item={item} index={index} firstSchedule={schedule[0]} handleLiveClick={handleLiveClick}></TvGuideScheduleItem> 
      } ) }
    </MediaQuery>
    <MediaQuery maxWidth={Constants.MobileWidth}>
      { schedule && schedule.map( ( item, index) => {    
        return <MobileTvGuideScheduleList key={index} item={item} index={index} firstSchedule={schedule[0]} handleLiveClick={handleLiveClick}></MobileTvGuideScheduleList>
      } ) }
    </MediaQuery>
   </div>
  )
}

export default TvGuideScheduleList;

import React from "react";
import Modal from "../Modal";
import { Button } from "../../Buttons";
import { User, removeStorageItem, Analytics } from "../../../utilities";
import "./EntitledModal.scss";

export default class EntitledModal extends React.Component<any, any> {
  constructor(props) {
    super(props);

    let message;
    switch(props.type) {
      case "sub":
        message = "Thank you for subscribing. You will see the RM10 charges on your monthly bill.";
        Analytics.userActionSubscribed();
        break;
      case "trial":
        const remainingDays = User.remainingFreeTrialDays();
        if(remainingDays) {
          message = `You've started your ${remainingDays}-day free trial! We will notify you through this pop-up window when your free trial is coming to an end.<br><br>Enjoy!`;
          Analytics.userActionTrialStarted();
        }
        break;
      default:
        break;
    }
    this.state = { message };
  };

  onClose = () => {
    const { onClose } = this.props;
    removeStorageItem("st", true);
    onClose && onClose();
  };

  render() {
    const { message } = this.state;
    return (
      <Modal shouldCloseOnEsc={false} shouldCloseOnOverlayClick={false} onClose={this.onClose} >
        {(onClose) => (
          <div className="EntitledModal">
            <div dangerouslySetInnerHTML={{__html: message || "" }} />
            <Button text="OK" onClick={onClose} />
          </div>
        )}
      </Modal>
    )
  }
};

import React, { useState } from "react";
import { isLive, is_00_00_AM } from "../../utilities";
import { Image } from "../../assets/images";
import { ImageWordButton } from "../../components/Buttons";
import { mobileRedirect, getTime } from "../../utilities";
import "./TvGuideScheduleList.scss";
import classnames from "classnames";

const MobileTvGuideScheduleList: React.FunctionComponent<any> = ({item, index, firstSchedule, handleLiveClick}) => {
  const now = isLive(item);
  const firstProgramme = is_00_00_AM(item, firstSchedule);
  const [ selected, setSelected ] = useState([false,false]);

  const onclick = (index) => {
    if (now) { 
      const select = [false,false]
      select[index] = true
      setSelected(select);
      mobileRedirect(process.env.REACT_APP_BANNER_ID);
    }
  }

  return (
    <div id={now ? "liveNow" : firstProgramme ? "first_programme": ""} className={classnames( "TvGuideScheduleItem", { live: now })}>
    <div className="TvGuideScheduleItem_Details">
      <div className="TvGuideScheduleItem_Time">{getTime(item)}</div>
      <img className="TvGuideScheduleItem_Divider" src={Image.PinkDivider} alt=""></img>
      <div className={classnames( "TvGuideScheduleItem_ProgramTitle", { live: now, selected: selected[0] })} onClick={() => {onclick(0)}}>{item.name}</div>
      <div className="TvGuideScheduleItem_Episode">{`Episode ${item.episodeNumber}: Series ${item.seriesNumber}`}</div>
      { now && <ImageWordButton unselect={!selected[1]} click={() => {onclick(1)}}></ImageWordButton> }
    </div> 
  </div>
  )
}

export default MobileTvGuideScheduleList;

import React from "react";
import { withStore, StoreProps } from "../../store";
import { setIsAbroadFlag } from "../../store/actions";
import { getQueryUrl, User } from "../../utilities";

interface FetchProps {
  url?: string;
  method: string;
  body?: any;
  query?: any;
  options?: any;
  children: (loading?: boolean, errors?: any[], data?: any, status?: number, refetch?: Function) => React.ReactNode;
  onSuccess?: (data?: any, status?: number) => void;
  onError?: (errors?: any[], status?: number) => void;
  onComplete?: (data?: any, errors?: any[], status?: number) => void;
  includeCredentials?: boolean;
  manual?: boolean;
  noCache?: boolean;
};

class Fetch extends React.Component<FetchProps & StoreProps, any> {
  static defaultProps = {
    method: "GET"
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errors: undefined,
      data: undefined,
      status: undefined
    };
  }

  componentDidMount() {
    const { manual } = this.props;
    if(!manual)
      this.makeRequest();
  }

  componentDidUpdate(prevProps, prevState) {
    const { url, onError, onSuccess, onComplete, manual } = this.props;
    const { loading, errors, data, status } = this.state;

    if(prevProps.url !== url && !manual) {
      this.makeRequest();
    }
    if(prevState.loading && !loading) {
      if(errors) {
        onError && onError(errors, status);
        onComplete && onComplete(undefined, errors, status);
      }
      else if(data) {
        onSuccess && onSuccess(data, status);
        onComplete && onComplete(data, undefined, status);
      }
    }
  }

  // Checks token validity and refreshes
  checkToken = async (middlewareRequest) => {
    const { signOut, signIn, store: { signedIn } } = this.props;
    

    if(!signedIn || !middlewareRequest) {
      return Promise.resolve(true);
    }
    return User.refreshToken(signIn, signOut, false);
  }

  performFetch = (url, params) => {
    const { setCountry, setDesktopFlag, setIsAbroadFlag , store: {country} } = this.props;
    fetch(url, params)
      .then(async response => {
        const status = response.status;
        const contentType = response.headers.get('content-type');
        const countryCode = response.headers.get('X-Country-Code')
        const onDesktop = response.headers.get('X-Is-Desktop-Viewer');

        let loading = false;

        if(countryCode) {
          setCountry(countryCode);
        }
        if(onDesktop) {
          setDesktopFlag(onDesktop)
        }

        if(country){
          console.log('CALLING setIsAbroadFlag FROM FETCH COMPONENT !!! ');
          setIsAbroadFlag(!["MY", "SG"].includes(country)? true : false);
          console.log('DISPATCHING COMPLETED');
        }
        

        const data = contentType && contentType.includes('application/json') ?
          await response.json() :
          await response.text()

        if(response.ok) {
          this.setState({ loading, data, status });
        }
        else {
          this.setState({
            loading,
            errors: [{ message: data }],
            ...data, // contains `data` and possibly `errors` fields
            status
          })
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          errors: [{ message: err.message }]
        })
      })
  }

  makeRequest = () => {
    const { url, method, body, query, options, includeCredentials, noCache } = this.props;
    const resolvedUrl = (query && getQueryUrl(url, query)) || url;
    const middlewareRequest = resolvedUrl && resolvedUrl.startsWith(process.env.REACT_APP_MIDDLEWARE_URL!);
    let params: any = { method, headers: {}, ...options };
    let cacheMethod = noCache || false

    if(cacheMethod) {
      params.headers["cache-control"] = "no-cache";
    }

    if(includeCredentials) {
      params.credentials = "include";
    }
    if(middlewareRequest) {
      params.headers["x-platform"] = "WEB";
      const authHeader = User.authHeader();
      if(authHeader)
        params.headers.Authorization = authHeader;
    }
    if(method === "POST") {
      params.headers["Content-Type"] = "application/json"
      if(body)
        params.body = JSON.stringify(body);
    }

    if(!resolvedUrl) {
      this.setState({ loading: false });
    }
    else {
      this.setState({ loading: true });

      this.checkToken(middlewareRequest)
        .then(() => this.performFetch(resolvedUrl, params))
        .catch(() => this.setState({ loading: false }));
    }
  }

  render() {
    const { loading, errors, data, status } = this.state;
    const { children } = this.props;
    return children(loading, errors, data, status, this.makeRequest);
  }
};

export default withStore(Fetch);

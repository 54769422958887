import React from "react";
import _ from "lodash";
import { addWindowChangeListener, removeWindowChangeListener } from "../../utilities";

// DOM sizes don't seem to change immediately after certain events (ie. mounting, resizing) so this waits before
// attempting to perform any operations
// const waitTimeMilliseconds = 0;

export default class MultiLineDiv extends React.Component<any, any> {
  container;
  el;

  constructor(props) {
    super(props);
    this.state = {
      text: props.children,
    }
  }

  componentDidMount() {
    addWindowChangeListener(this.onWindowChange);

    // Use setTimeout if issues arise
      this.calculateText(() => {
        this.props.onSetup && this.props.onSetup();
      });
  }

  componentWillUnmount() {
    removeWindowChangeListener(this.onWindowChange);
  }

  componentDidUpdate(prevProps, prevState) {
    const { children } = this.props;

    if(prevProps.maxLines !== this.props.maxLines ||
      this.getMaxHeight(prevProps.maxHeight) !== this.getMaxHeight(this.props.maxHeight) ||
      prevProps.maxCharacters !== this.props.maxCharacters) {
      this.calculateText();
    }

    // If text changes, update the component.
    if (prevProps.children !== children) {
      this.setState({
        text: children
      }, () => this.calculateText());
    }
  }

  onWindowChange = () => {
    this.setState({
      text: this.props.children
    }, this.calculateText)
  }

  getLineHeight = () => {
    let temp = document.createElement(this.container.nodeName);
    let containerStyle = window.getComputedStyle(this.container);
    temp.style.fontFamily = containerStyle.fontFamily;
    temp.style.fontSize = containerStyle.fontSize;
    temp.style.lineHeight = containerStyle.lineHeight;
    temp.style.margin = 0;
    temp.style.padding = 0;
    temp.style.whitespace = "nowrap";
    temp.innerHTML = "Test";
    this.container.parentNode.appendChild(temp);
    let height = temp.clientHeight;
    temp.parentNode.removeChild(temp);
    return height;
  }

  getMaxHeight = (maxHeightProp) => {
    let maxHeightCSS = window.getComputedStyle(this.container).maxHeight;
    return (maxHeightCSS && Number(maxHeightCSS.replace(/px|vh|vw/g, ""))) || maxHeightProp;
  }

  calculateText = (callback?) => {
    if(!this.props.children)
      return;

    let options = [
      this.truncateToMaxCharacters(),
      this.truncateToMaxLines(),
      this.truncateToMaxHeight()
    ];

    let text = options[0];
    for(let option of options) {
      if(!option)
        continue;
      if(option.length < text.length) {
        text = option;
      }
    }

    if(text !== this.state.text) {
      this.setState({ text }, callback);
    }
  }

  truncateToMaxCharacters = () => {
    const { maxCharacters, children } = this.props;

    if(!maxCharacters)
      return children as string;

    return (children as string).split("").slice(0, maxCharacters - 3).join("").trim() + "...";
  }

  truncateToMaxLines = () => {
    const { maxLines, children } = this.props;
    let lineHeight = this.getLineHeight();
    let numLines = (this.el.getBoundingClientRect().height / lineHeight);
    let words = (children as string || "").split(" ");

    if(!maxLines)
      return children as string;

    while(numLines > maxLines) {
      words.pop();
      words[words.length -1] += "...";
      this.el.textContent = words.join(" ");
      numLines = Math.round(this.el.getBoundingClientRect().height / lineHeight);
    }
    return words.join(" ");
  }

  truncateToMaxHeight = () => {
    const { children, maxHeight: maxHeightProp } = this.props;
    let maxHeight = this.getMaxHeight(maxHeightProp);
    let words = (children as string || "").split(" ");
    let height = this.el.getBoundingClientRect().height;

    if(!maxHeight)
      return children as string;

    while(height > maxHeight) {
      words.pop();
      words[words.length -1] += "...";
      this.el.textContent = words.join(" ");
      height = this.el.getBoundingClientRect().height;
    }
    return words.join(" ");
  }

  render() {
    const { children, maxLines, maxCharacters, ...props } = this.props;
    const { text } = this.state;
    const filteredProps = _.pickBy(props, (value, key) => (
      ["className", "onClick"].includes(key) || !/[A-Z]+/g.test(key)
    ))
    return (
      <React.Fragment>
        <div ref={(node) => this.container = node} {...filteredProps}>
          <span ref={(node) => this.el = node}>{text}</span>
        </div>
      </React.Fragment>
    )
  }
};

import React from "react";
import { Image } from "../../assets/images";
import "./MobileSearchHeader.scss";

const MobileSearchHeader: React.FunctionComponent<any> = ({ focused, onClose }) => {
  return (
      focused ?
        <div className="MobileSubMenu__header">
          <div className="MobileSubMenu__header-back hidden" onClick={onClose}>
            <img src={Image.Arrow} alt="arrow" />
          </div>
          <div className="MobileSubMenu__header-title">Search</div>
          <div className="MobileSubMenu__header-close" onClick={onClose}><img src={Image.Close} alt="close" /></div>
        </div>
      : null
  );
}

export default MobileSearchHeader;

import {
  updateSettings,
  updateMultipleSettings,
  signOut,
  setCountry,
  playVideo,
  dismissVideo,
  playLiveVideo,
  dismissLiveVideo,
  signIn,
  setDesktopFlag,
  setIsAbroadFlag,
  supportedsubtitlelanguages,
  supportedaudiolanguages
} from "./actions";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return { store: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSettings: (key, value) => dispatch(updateSettings(key, value)),
    updateMultipleSettings: (obj) => dispatch(updateMultipleSettings(obj)),
    signOut: () => dispatch(signOut()),
    setCountry: (country) => dispatch(setCountry(country)),
    playVideo: (video) => dispatch(playVideo(video)),
    dismissVideo: () => dispatch(dismissVideo()),
    playLiveVideo: (video) => dispatch(playLiveVideo(video)),
    dismissLiveVideo: () => dispatch(dismissLiveVideo()),
    signIn: (accessToken, tokenType, expiresIn) => dispatch(signIn(accessToken, tokenType, expiresIn)),
    setDesktopFlag: (onDesktop) => dispatch(setDesktopFlag(onDesktop)),
    setIsAbroadFlag: (isAbroad) => dispatch(setIsAbroadFlag(isAbroad)),
    supportedsubtitlelanguages: (subtitlelanguages) => dispatch(supportedsubtitlelanguages(subtitlelanguages)),
    supportedaudiolanguages: (audiolanguages) => dispatch(supportedaudiolanguages(audiolanguages)),
  };
};

const withStore = connect(mapStateToProps, mapDispatchToProps);

export default withStore;

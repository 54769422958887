import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { getUrl } from "../../utilities";
import "./Footer.scss";

const Footer: React.FunctionComponent<any> = ({ menu }) => {
  return (
    <div className={classnames("Footer", { menu })}>
      <div className="Footer__content">
        <div className="Footer__item">
          <Link to={getUrl("about")}>About BBC Player</Link>
        </div>
        <div className="Footer__item">
          <Link to={getUrl("help-and-support")}>Help & Support</Link>
        </div>
        <div className="Footer__item">
          <Link to={getUrl("terms-and-conditions")}>Terms & Conditions</Link>
        </div>
        {/* <div className="Footer__item">
          <a target="_blank" rel="noopener noreferrer" href="https://www.bbc-channels.com">BBC Channels App</a>
        </div> */}
      </div>
    </div>
  )
};

export default Footer;

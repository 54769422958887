import React from "react";
import Tile from "./Tile";
import RecommendationTile from "./RecommendationTile";
import Border from "../Border/Border";
import Scroller from "./Scroller";
import { Link } from "react-router-dom";
import { getUrl } from "../../utilities";
import _ from "lodash";
import "./Carousel.scss";


export default class Carousel extends React.Component<any, any> {
  items;

  constructor(props) {
    super(props);
    this.state = {
      showGradient: false,
    }
    
  }

  componentDidMount() {
    const scrollElement = this.items.scrollElement as HTMLElement;

    this.setState({
      showGradient: scrollElement.clientWidth < scrollElement.scrollWidth
    })
  }

  onScroll = ({ atEnd }) => {
    this.setState({ showGradient: !atEnd })
  }

  getShiftPosition = (el, direction, toEnd) => {
    const container = el.getBoundingClientRect();
    const offset = container.x;
    const maxWidth = container.width;
    const leftMargin = container.left;
    const rightMargin = container.right;
    const children = Array.from(el.children);
    let start, width = 0;

    if(toEnd) {
      let child = direction === 1 ? children[children.length - 1] : children[0];
      return Math.abs((child as Element).getBoundingClientRect().left - offset);
    }

    for(let c of children) {
      let child = (c as Element).getBoundingClientRect();
      if(child.left >= leftMargin && child.right <= rightMargin && !start) {
        start = child;
      }
      if(width + child.width < maxWidth) {
        width += child.width;
      }
    }

    if(direction === 1) {
      for(let i=0; i<children.length; i++) {
        let child = (children[i] as Element).getBoundingClientRect();
        if(i === children.length - 1 || (child.left > start.left + width)) {
          return Math.abs(child.left - offset);
        }
      }
    }
    else if(direction === -1) {
      for(let i=children.length-1; i>=0; i--) {
        let child = (children[i] as Element).getBoundingClientRect();
        if(i === 0 || (child.left < start.left - width)) {
          return Math.abs(child.left - offset);
        }
      }
    }

    return container.width;
  }

  render() {
    const { showGradient } = this.state;
    const { player, onClick, component: { header, viewAll, collection }, position, enableAbroadBanner } = this.props;
    const totalCount = _.get(collection, "items.totalCount");
    const items = _.get(collection, "items.nodes");
    const showViewAll = viewAll && totalCount && items && totalCount > items.length;
    const containerTitle = (viewAll && viewAll.replace("/viewall/", "")) || header.toLowerCase().replace(/ /g,"-");

    return (
      items ?
      <div className="Carousel">
        <div className="Carousel__header">{header}</div>
        { showViewAll && <div className="Carousel__view-all"><Link to={getUrl(viewAll)}>View All</Link></div> }
        <div className="Carousel__items">
          <Scroller
            ref={(node) => this.items = node}
            onScroll={this.onScroll}
            size={items.length}
            scrollType="shift"
            shiftPosition={this.getShiftPosition}
          >
            <div className="Carousel__items-content">
              { items.map((contentItem, index) => (
                player ?
                <RecommendationTile
                  key={index}
                  position={`${position || 0}-${index + 1}`}
                  containerTitle={containerTitle}
                  containerType="carousel"
                  contentItem={contentItem}
                  onClick={onClick}
                /> :
                <Tile
                  key={index}
                  position={`${position}-${index + 1}`}
                  containerTitle={containerTitle}
                  containerType="carousel"
                  contentItem={contentItem}
                  enableAbroadBanner={enableAbroadBanner}
                />
              ))}
            </div>
          </Scroller>
          { showGradient && <div className="Carousel__overflow-gradient" /> }
        </div>
        <Border />
      </div> : null
    )
  }
};

import React from "react";
import { withRouter, RouteChildrenProps } from "react-router";
import { withStore, StoreProps } from "../../store";
import { compose } from "redux";
import Fetch from "./Fetch";
import { Player } from "../Overlays";
import LivePlayer from "../LivePlayer/LivePlayer";
import ErrorPortal, { ErrorType } from "../Error/Error";
import { cleanUrl, getDateString } from "../../utilities";
import _ from "lodash";
import Banner from "../Banner/Banner";

interface ContentFetchProps {
  pathname?: string;
  onComplete?: (data?: any) => void;
  children: (errors?: any[], screen?: any) => React.ReactNode;
};

class ContentFetch extends React.Component<ContentFetchProps & RouteChildrenProps & StoreProps, any> {
  refetch;
  private isTvGuideEventSent: boolean = false;

  componentDidUpdate(prevProps) {
    const { store: { video: prevVideo } } = prevProps;
    const { store: { video, isAbroad }, history, location: { search, pathname, state = {} } } = this.props;

 

    // Navigate to player route if not already at it
    if(!prevVideo && video && !search.includes("player") ) {
      if (isAbroad)
        history.push(`${pathname}`);
      else if (video.openLivePlayer) history.push(`${pathname}?liveplayer`);
      else history.push(`${pathname}?player`);
    }
    // Reload content screen after playback has ended to get the latest progress info
    else if(prevVideo && !video && !state.fromRecommendation) {
      this.refetch && this.refetch();
    }
  }

  formatData = (res) => {
    return this.isTvGuide() ? res : _.get(res, "data.contentItem");
  }

  onComplete = async (data) => {  
    const { onComplete, store: { country, isAbroad }, setIsAbroadFlag } = this.props;
    if(country){
      setIsAbroadFlag(!["MY", "SG"].includes(country)? true : false);
    }
    let isTvGuidePage = this.isTvGuide();
    if(isTvGuidePage && data  ) {
      //ErrorPortal.error(ErrorType.SomethingWrong);
      if (!this.isTvGuideEventSent) {
        onComplete && onComplete(this.formatData(data));
        this.isTvGuideEventSent = true;
      }
    } else if (!isTvGuidePage && data  && data.data.contentItem === null ) {

    }
    else {
      onComplete && onComplete(this.formatData(data));
    }
  }

  isTvGuide = () => { 
    const { pathname = window.location.pathname } = this.props;
    return pathname.includes( '/live/guide')
  }

  componentWillUnmount() {
    this.isTvGuideEventSent = false;
  }
  
  render() {
    const { pathname = window.location.pathname, children, store: { video, country, isAbroad }, setIsAbroadFlag, location: { search } } = this.props;
    const path = `/contentitem/${cleanUrl(pathname)}`.replace(/\/{2,}/g,"/");
    const url = this.isTvGuide() ? `${process.env.REACT_APP_MIDDLEWARE_URL}/channels/schedule/${getDateString()}`:`${process.env.REACT_APP_MIDDLEWARE_URL}${path}`; 

    return (
      !video ?
        <Fetch url={url} onComplete={this.onComplete}>
          {(_, errors, data, status, refetch) => {
            this.refetch = refetch;
            return children(errors, this.formatData(data));
          }}
        </Fetch>
      : search.includes("liveplayer") ? !isAbroad && <LivePlayer /> 
      : search.includes("player") ?
      !isAbroad && <Player />
      : null
    )
  }
};

export default compose<any>(withRouter, withStore)(ContentFetch);

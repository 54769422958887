import React from "react";
import Tile from "../Carousel/Tile";
import Border from "../Border/Border";
import { Analytics, User } from "../../utilities";
import _ from "lodash";
import "./SearchResults.scss";

export default class SearchResults extends React.Component<any, any> {
  getSearchResults;

  constructor(props) {
    super(props);
    this.state = {
      results: null
    }
    this.getSearchResults = _.debounce(this._getSearchResults, 750);
    Analytics.pageViewSearch();
  }

  componentDidMount() {
    this.getSearchResults();
  }

  componentWillUnmount() {
    this.getSearchResults.cancel();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.query !== this.props.query) {
      this.getSearchResults();
    }
  }

  _getSearchResults = async () => {
    this.setState({
      results: null
    },
    async () => {
      const { query } = this.props;
      const url = `${process.env.REACT_APP_MIDDLEWARE_URL}/search/${query}`;
      const authHeader = User.authHeader();
      let headers = {};
      if (authHeader) {
        headers = {
          "Authorization" : authHeader,
          ...headers
        };
      }

      const response = await fetch(url, {
        method: "GET",
        headers: headers
      });
      let results = [];
      try {
        const data = await response.json();
        results = _.get(data, "data.search.nodes");
      } catch(err) {
        console.log(err);
      }
      this.setState({ results })
    })
  }

  render() {
    const { query } = this.props;
    const { results } = this.state;
    return (
      <div className="SearchResults">
        <div className="SearchResults__content">
          { (results && results.length === 0) &&
            <div className="SearchResults__error">
              <div>{`Sorry, we couldn't find anything for ${query}`}</div>
              <div>Please check and try again.</div>
              <Border />
            </div>
          }
          { (results && results.length > 0) &&
            <React.Fragment>
              <div className="SearchResults__header">
                Showing search results for <span>{query}</span>
              </div>
              <div className="SearchResults__grid">
                { results.map((contentItem, index) => <Tile key={index} contentItem={contentItem} />) }
              </div>
            </React.Fragment>
          }
        </div>
      </div>
    )
  }
}


import React from "react";
import ContentFetch from "../../components/Fetch/ContentFetch";
import ComponentList from "../../components/ComponentList/ComponentList";
import { Helmet } from "react-helmet";
import { Analytics } from "../../utilities";


const Home: React.FunctionComponent<any> = ({ enableAbroadBanner }) => {
  let homePageURL = process.env.REACT_APP_PUBLIC_URL ? process.env.REACT_APP_PUBLIC_URL + "/en/" : "https://player.bbc.com/en/"
  return (
    <>
      <ContentFetch pathname="/" onComplete={Analytics.pageViewHome}>
        {(errors, screen) => (
          (!screen || errors) ?
            null :
            <div>
              <Helmet>
                <title>BBC Player</title>
                { window.location.pathname === "/en/" && ( <link rel="canonical" href={homePageURL} /> ) }
                { window.location.pathname === "/en/" && ( <link rel="alternate" hrefLang="en" href="https://www.bbc.co.uk/iplayer" /> ) } 
                { window.location.pathname === "/en/" && ( <link rel="alternate" hrefLang="en-sg" href={homePageURL}/> ) }
                { window.location.pathname === "/en/" && ( <link rel="alternate" hrefLang="en-my" href={homePageURL}/> ) } 
              </Helmet>
              <ComponentList components={screen.components} enableAbroadBanner={enableAbroadBanner} extraProps={{ category: screen.name }}/>
            </div>
        )}
      </ContentFetch>
    </>

  )
};

export default Home;

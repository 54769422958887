import React, { useEffect, useState } from "react";
import { Constants } from "../../utilities"
import "./TvGuideSchedule.scss"
import TvGuideScheduleList from "./TvGuideScheduleList";
import classnames from "classnames";

const TvGuideSchedule: React.FunctionComponent<any> = ({channel, handleLiveClick, channelIndex}) => {
    const currentDate = new Date();
    const [ selected, setSelected ] = useState(0) ;
    const [ schedule, setSchedule ] = useState([]) ;
    
    useEffect(() => {
      const startdate = new Date(currentDate);
      startdate.setDate(currentDate.getDate() + selected);
      const filteredSchedule = channel.schedules.filter( program => {
        const programDate = new Date(program.startTime_utc);
        return startdate.getDate() === programDate.getDate() && 
        startdate.getMonth() === programDate.getMonth() && 
        startdate.getFullYear() === programDate.getFullYear();
      } );
      setSchedule(filteredSchedule)
    }, [selected, channelIndex] );

    useEffect(() => {
      setSelected(0);
    }, [channelIndex] );

    useEffect(() => {
      if ( selected === 0 ) {
          const element = document.getElementById('liveNow');
          if (element) {
            element.scrollIntoView({ block: 'start' });
            window.scrollTo(0,0);
          }
      } else {
          const element = document.getElementById('first_programme');
          if (element) {
            element.scrollIntoView({ block: 'start' });
            window.scrollTo(0,0);
          }
      }
    }, [schedule, selected] );

    const getDays = (day, index) => {
        return index === 0 ? Constants.Today : Constants.Days[day];
    };

    const onclick = (index) => {
        setSelected(index);    
    };

  return (
   <div className="TvGuide_Schedule">
        <div className="TvGuide_Schedule_Header">
            { Array.from(Array(8).keys()).map( (index) => {
                let date = new Date(currentDate);
                date.setDate(currentDate.getDate() + index);
                return <div className={classnames("TvGuide_Schedule_Header_Flex", { selected: selected === index })} 
                    key={index} onClick={() => onclick(index)}>
                    <span className="TvGuide_Schedule_Header_Flex_Day">{getDays(date.getDay(), index)}</span>
                    <span className="TvGuide_Schedule_Header_Flex_Date">{date.getDate()}</span>
                </div>
            } ) }
        </div>
        <TvGuideScheduleList schedule={schedule} channel={channel} handleLiveClick={handleLiveClick}></TvGuideScheduleList>
   </div>
  )
}

export default TvGuideSchedule;

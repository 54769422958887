import React from "react";
import { withStore } from "../../../store";
import Modal from "../Modal";
import { Button } from "../../Buttons";
import "./SignInModal.scss";
import { getProviderInfo, Analytics } from "../../../utilities";
import Overlay from "../../Overlays/Overlay";
import { Image } from "../../../assets/images";

const SignInModal: React.FunctionComponent<any> = ({ store, onClose }) => {
  let { country, isAbroad } = store;

  const { provider, providerId, fallbackUrl, startSignIn } = getProviderInfo(country);
  const signInMessage = `Sign in with ${providerId}`;
  const laterMessage = "Sign in later";
  let noProviderMessage
  if (country === "SG") {
    noProviderMessage = `Find out more`;
  } else {
    noProviderMessage = `Find out more`;
  }


  const onStartSignIn = (providerSelected) => {
    const provider = `Sign in with ${providerSelected}`;
    Analytics.userActionSignInSelection(provider);
    startSignIn(providerSelected);
  };
  const onLater = () => {
    Analytics.userActionSignInSelection(laterMessage);
    onClose();
  };
  const onNoProvider = () => {
    Analytics.userActionSignInSelection(noProviderMessage);
  };

  Analytics.pageViewSignIn();

  return (
    <Modal onClose={onClose}>
      <div>
        {country === 'SG' &&
          <Overlay className="SignInModal" message="Sign in with your TV subscription">
            <div className="SignInModal__shortmessage">Choose your service provider:</div>
            <div className="SignInSingtel__buttons">
              <Button className="starhub-button" icon={Image.AffiliateStarhubLogo} outline-curved starhub onClick={() => onStartSignIn("starhub")} />
              <Button className="singtel-button" icon={Image.AffiliateSingtelLogo} outline-curved singtel onClick={() => onStartSignIn("singtel")} />
              <Button className="close-button" icon={Image.Close} close onClick={onLater} />
            </div>
            <a href={fallbackUrl} onClick={onNoProvider}>{noProviderMessage}</a>
          </Overlay>
        }
        {country === 'MY' &&
          <Overlay className="SignInModal" message="Sign in with your TV subscription">
            <div className="SignInModal__shortmessage">Choose your service provider:</div>
            <div className="SignInMalaysia__buttons">
              <Button className="unifi-button" icon={Image.UnifiLogo} imageClass="unifi-button-icon" outline-curved  onClick={() => onStartSignIn("tm")} />
              <Button className="astro-button" icon={Image.AstroLogo} imageClass="astro-button-icon" outline-curved  onClick={() => onStartSignIn("astro")} />
            </div>
            <a href={fallbackUrl} onClick={onNoProvider}>{noProviderMessage}</a>
          </Overlay>
        }
      </div>
    </Modal>
  )
};

export default withStore(SignInModal);

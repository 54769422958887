import React from "react";
import { Button } from "../Buttons";
import { SVG } from "../../assets/images";
import EventEmitter from "eventemitter3";
import { withRouter } from "react-router-dom";
import AbroadDropdown from "./AbroadDropdown/AbroadDropDown";
import "./Error.scss";

const emitter = new EventEmitter();

export enum ErrorType {
  PageNotFound = "Page not found",
  ContentNotAvailable = "Sorry but this content is no longer available. Can we help you find something else to watch though?",
  SomethingWrong = "Sorry, something went wrong",
  Abroad = "You seem to be abroad. Due to rights agreements you can only watch content in the country of your TV subscription. "
};

export const Error: React.FunctionComponent<any> = ({ message, retry, retryText }) => {
  return (
    <div className="Error">
      <div className="Error__contents">
        <SVG.Warning />
        <div className="Error__message">
          {message || ErrorType.SomethingWrong}
        </div>
        { ((message !== ErrorType.Abroad) || (message && retry)) &&
          <Button className="Error__retry" text={retryText || "Try Again"} onClick={retry} />
        }
        { message === ErrorType.Abroad && <AbroadDropdown /> }
      </div>
    </div>
  )
};

class _Portal extends React.Component<any, any> {
  bodyEl;
  defaultState = {
    display: false,
    message: undefined,
    allowRetry: false,
    retry: undefined,
    retryText: undefined,
    priority: 0
  }

  constructor(props) {
    super(props);
    this.state = this.defaultState;
  };

  dismiss = (cb?) => {
    this.bodyEl.classList.remove("error", "scrollable");
    this.setState(this.defaultState, cb);
  }

  componentDidMount() {
    this.bodyEl = document.querySelector("body");

    emitter.on("ERROR", (props) => {
      if(props.priority < this.state.priority)
        return;

      this.bodyEl.classList.add("error");
      window.scrollTo(0, 0);

      if(props.message === ErrorType.Abroad) {
        this.bodyEl.classList.add("scrollable");
      }

      this.setState({
        ...props,
        display: true,
        retry: props.retry && (() => this.dismiss(props.retry)),
      })
    });

    emitter.on("DISMISS", () => {
      this.dismiss();
    })
  }

  componentWillUnmount() {
    emitter.off("ERROR");
    emitter.off("DISMISS");
  }

  componentDidUpdate(prevProps) {
    if(prevProps.location.key !== this.props.location.key) {
      this.dismiss();
    }
  }

  render() {
    const { display, ...props } = this.state;
    return <div id="Error__portal">{ display && <Error {...props} /> }</div>
  }
};
const Portal = withRouter(_Portal);

export default class ErrorPortal extends React.Component<any, any> {
  static error = (message: string = ErrorType.SomethingWrong, retry?, retryText?, priority = 1) => {
    emitter.emit("ERROR", {
      message,
      retry,
      retryText,
      priority
    });
  }

  static dismiss = () => {
    emitter.emit("DISMISS");
  }

  render() {
    return <Portal />
  }
};

import React from "react";
import HeaderBar from "../HeaderBar/HeaderBar";
import { Image } from "../../../../assets/images";
import classnames from "classnames";
import "./PlayerLoadingOverlay.scss";

const PlayerLoadingOverlay: React.FunctionComponent<any> = ({ contentItem, className }) => {
  return (
    <div className={classnames("PlayerLoadingOverlay", className)}>
      <HeaderBar contentItem={contentItem} />
      <img className="PlayerLoadingOverlay__loading" src={Image.Loading} alt="loading" />
    </div>
  )
};

export default PlayerLoadingOverlay;

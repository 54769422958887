import React, {useState} from "react";
import { withStore } from "../../store";
import { SignInModal, UpgradeModal, TrialProgressModal } from "../../components/Modals";
import { Constants, AuthorizeLiveplayer } from "../../utilities";
import LiveTab from './LiveTab'
import _ from "lodash";
import "./Live.scss";

const Live: React.FunctionComponent<any> = ({ component, playLiveVideo, category, enableAbroadBanner, store: {country , isAbroad}}) => {
  const contentItems = _.get(component, "collection.items.nodes");
  const [showPrompt, setShowPrompt] = useState("");


  const handleLiveClick = async (channel) => {
    channel.extId = channel.houseid
    channel.callSchedule = true;
    channel.title = Constants.LiveChannels.find( ele => ele.toLowerCase() === category.toLowerCase());
    await AuthorizeLiveplayer(
      isAbroad,
      enableAbroadBanner,
      setShowPrompt,
      country,
      channel.title,
      channel,
      playLiveVideo
    );
  }


  return (
    <div className="Live">
      {contentItems && contentItems.map( (item, index) => {
        return <LiveTab key={index} item={item} category={category} handleLiveClick={handleLiveClick} last={contentItems.length === index + 1}></LiveTab>
      } )}
      { showPrompt === Constants.Prompts[0] && <SignInModal onClose={() => {setShowPrompt("")}} />} 
      { showPrompt === Constants.Prompts[2] && <TrialProgressModal onClose={() => {setShowPrompt("")}} /> }
      { showPrompt === Constants.Prompts[1] && <UpgradeModal onClose={() => setShowPrompt("")} /> }
    </div>
  )
}

export default withStore(Live);

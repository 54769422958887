import React from "react";
import ListItem from "./ListItem/ListItem";
import "./List.scss";

const List: React.FunctionComponent<any> = ({ season, series, enableAbroadBanner }) => {
  return (
    <div className="List">
      {
        season.episodes.map((episode, index) => {
          episode = {
            ...episode,
            series: series,
            seasonNumber: season.seasonNumber,
            channel: series.channel
          };
          return (
            <ListItem key={"episode-" + index} contentItem={episode} enableAbroadBanner = {enableAbroadBanner}/>
          )
        })
      }
    </div>
  )
}

export default List;

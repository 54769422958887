import React from "react";

const FAQ: React.FunctionComponent<any> = () => (
  <div className="FAQ BasicPage">
    <div className="BasicPage__header">Frequently Asked Questions</div>
    <div className="BasicPage__header">ABOUT BBC PLAYER</div>
    <ul>
      <li>
        <div className="FAQ__question">What is BBC Player?</div>
        <p>BBC Player is an authenticated multi-genre SVOD (Subscription Video on Demand) streaming service that allows viewers to watch the very best of British programmes anytime and anywhere. It is available online (<a target="_blank" rel="noopener noreferrer" href="https://bbcplayer.com">www.bbcplayer.com</a>) and as an app, via <a target="_blank" rel="noopener noreferrer" href="https://appstore.com/bbcplayer">Apple App Store</a> and <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.bbc.tve">Google Play Store</a> for Android devices.
        </p>
        <p><u>With BBC Player, subscribers to BBC channels on StarHub as well as Singtel CAST subscribers will be able to:</u></p>
          <p>&emsp; - &emsp; <u>Watch and download On Demand Content</u></p>
          <p>As a multi-genre subscription service, BBC Players will allow viewers to stream a curated line-up of popular British programmes from world-class documentaries, premium British dramas, popular comedy and entertainment shows to aspirational lifestyle programming and engaging children’s shows.
          </p><p>Stream and download a selection of award-winning and highly rated British programmes to watch offline for up to 30 days.
          </p>
          <p>&emsp; - &emsp; <u>Stream Live BBC TV Channels</u></p>
          <p>With BBC Player’s recent update, viewers with the eligible StarHub TV and Singtel CAST subscriptions will also be able to watch the live TV channel feed for BBC Earth, BBC Lifestyle and CBeebies on BBC Player’s online site and app.
          </p>
      </li>
      <li>
        <div className="FAQ__question">How different is BBC Player from BBC iPlayer?</div>
        <p>BBC iPlayer is the BBC’s streaming catch-up television and radio service that is only available in the UK. The service is funded by UK license fee payers and is not legally available outside the UK.</p>
        <p>BBC Player is an authenticated service currently available in Singapore to StarHub TV and Singtel CAST subscribers only.</p>
      </li>
      
      
      <li>
        <div className="FAQ__question">Can you tell me more about the brands / channels on BBC Player? What are they?</div>
        <p><strong>BBC Earth</strong> showcases the work of the world’s foremost factual filmmakers. From the smallest creatures under the microscope to the limitless expanses of space, BBC Earth  takes viewers on a thrilling journey of discovery, come face-to-face with heart pounding action, mind-blowing ideas and the sheer wonder on this extraordinary planet we call home.</p>
        <p><strong>BBC First</strong> is the home of premium British drama. Showcasing world-renowned, award-winning talent in front of and behind the camera, it delivers audiences the first chance to see stories that challenge, immerse and entertain, all with a unique British perspective.</p>
        <p><strong>BBC Brit</strong> offers premium factual entertainment that is intelligent but irreverent. It blends expertise with entertainment and fact with fun. The channel covers a rich variety of subjects: cars, science, adventure, business, life-changing moments, extreme characters, quirky eccentrics and comedy. BBC Brit is available exclusively on BBC Player.</p>
        <p><strong>BBC Lifestyle</strong> is a unique entertainment destination brimming with vibrant, fresh and exciting programmes which set trends and guide viewers into transforming their world; from revamping the home to invigorating their culinary skills and helping them to become happier and get the best out of life.</p>
        <p><strong>CBeebies</strong> is loved by preschoolers under six, and by their parents and carers. Parents and carers trust CBeebies to deliver a safe, non-violent, interactive experience designed by developmental experts, and delivered by the trusted BBC brand, to promote imaginative play, social interaction, language skills and educational values.</p>
      </li>
      
      <li>
        <div className="FAQ__question">	Is BBC Player exclusive to subscribers?</div>
        <p>The BBC Player is currently available only to StarHub TV and Singtel CAST subscribers in Singapore.</p>
        <p>For more information, please visit:</p> 
        <br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.singtel.com/personal/products-services/lifestyle-services/cast/bbc-player">Singtel CAST Subscribers </a>
        <br />

        <a target="_blank" rel="noopener noreferrer" href="https://www.starhub.com/personal/tvplus/passes/entertainmentplus/bbc-player.html">StarHub TV Subscribers</a>

      </li>
      <li>
        <div className="FAQ__question">In terms of the functions and features, is there a difference between the BBC Player app and https://bbcplayer.com?</div>
        <p>They are the same except that the download feature is only available on the BBC Player app.</p>
      </li>
      <li>
        <div className="FAQ__question">Why are some programmes from my favourite BBC channels not available on BBC Player to stream or download?</div>
        <p>Unfortunately, we are not always able to acquire BBC Player rights for every programme we broadcast; and sometimes we can only acquire the rights to stream a programme, but not to download.</p>
        <p>We are continually looking at ways to ensure a great viewing experience for you, so check back often to discover what is new on BBC Player.</p>
      </li>
      <li>
        <div className="FAQ__question">Will BBC Player be bringing in the latest BBC shows very soon after the UK telecast?</div>
        <p>We will bring select programmes express from the UK.  Do check in regularly to see what’s new on BBC Player!</p>
        <p>Also, follow us on Facebook (<a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/BBCPlayer">https://www.facebook.com/BBCPlayer</a>) to get the latest news on BBC Player.</p>
        <hr className="mobileonly" />
      </li>
      </ul>
      

    <hr className="non-mobileonly" />
    <div className="BasicPage__header">ACCESSING BBC PLAYER IN SINGAPORE</div>
    <ul>
      <li>
        <div className="FAQ__question">How do I begin watching all my favourite programmes on BBC Player?</div>
        <p>BBC Player is available online via <a target="_blank" rel="noopener noreferrer" href="https://bbcplayer.com">www.bbcplayer.com</a> and also through an app on your mobile device via Apple App Store or Google Play Store, or on your TV via the Google Play Store. </p>
      </li>
      <li>
        <div className="FAQ__question">Do I need to pay/ How much do I need to pay for BBC Player?</div>
        <p>BBC Player is available to subscribers to BBC channels on StarHub TV  as well as Singtel CAST subscribers in Singapore.</p>
        <p>For more information, please visit:</p>
        <br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.singtel.com/personal/products-services/lifestyle-services/cast/bbc-player">Singtel CAST Subscribers </a>
        <br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.starhub.com/personal/tvplus/passes/entertainmentplus/bbc-player.html">StarHub TV Subscribers</a>

      </li>
      <li>
        <div className="FAQ__question">Can I access BBC Player if I don’t have any subscription?</div>
        <p>You will be able to access the BBC Player catalogue and get a sneak peek of our programmes without an account.</p>
        <p>We hope to pique your interest there and you can sign up for BBC Player via your service provider:</p>
        <p>For more information, please visit:</p>
        <br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.singtel.com/personal/products-services/lifestyle-services/cast/bbc-player">Singtel CAST Subscribers </a>
        <br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.starhub.com/personal/tvplus/passes/entertainmentplus/bbc-player.html">StarHub TV Subscribers</a>
      </li>
      <li>
        <div className="FAQ__question">Can I access BBC Player even if I don’t have Wi-Fi or mobile data?</div>
        <p>You will require a broadband connection or data service on your smart device or on your computer to watch and download programmes on BBC Player.</p>
        <p>However, for programmes you have already downloaded to your mobile or tablet device, you will not require mobile data, Wi-Fi or a broadband connection to watch them.</p>
      </li>
      <li>
        <div className="FAQ__question">What are the system requirements to use the BBC Player on PC, Android and iOS?</div>
        <p>On a computer, your web browser and internet connection should meet the following minimum requirements for the best experience on BBC Player. (Note that these are minimums only based on our testing, and other combinations of OS and browser may also work)</p>
        <div className="requirement">
          <div className="requirement-header">Internet bandwidth minimum recommendation:</div>
          <div className="requirement-row">
            <div>Standard Definition (SD) videos:</div>
            <div>1.5mb / sec</div>
          </div>
          <div className="requirement-row">
            <div>High Definition (HD) videos:</div>
            <div>3.0mb / sec</div>
          </div>
        </div>
        <div className="requirement">
          <div className="requirement-header">Recommended Desktop computer operating systems:</div>
          <div className="requirement-row"><div>Mac OS</div></div>
          <div className="requirement-row"><div>Windows</div></div>
        </div>
        <div className="requirement">
          <div className="requirement-header">Recommended web browsers:</div>
          <div className="requirement-row"><div>Google Chrome (Latest version)</div></div>
          <div className="requirement-row"><div>Microsoft Internet Explorer 11</div></div>
          <div className="requirement-row"><div>Microsoft Edge</div></div>
          <div className="requirement-row"><div>Mozilla Firefox (Latest version)</div></div>
          <div className="requirement-row"><div>Safari (Latest version)</div></div>
        </div>
        <p className="requirement-footnote"><strong>Browser Plug-ins:</strong> To watch videos using Firefox, Internet Explorer, and Safari, you may also need to install Microsoft Silverlight version 5.1 or Adobe Flash (version 10.2.159.1 or higher).</p>
        <p className="requirement-footnote"><strong>Support for mobile devices:</strong> You can also download the BBC Player app via the <a target="_blank" rel="noopener noreferrer" href="https://appstore.com/bbcplayer">App Store</a> (for iOS devices) or <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.bbc.tve">Google Play Store</a> (for Android devices), and sign in with your credentials. This lets you download your content to an iOS or Android device.</p>
        <p className="requirement-footnote"><strong>iOS:</strong> iOS 8.0 and above, with a current Safari or Chrome browser for website use</p>
        <p className="requirement-footnote"><strong>Android:</strong> 5.0 and above, depending on device, with a current Chrome browser for website use</p>
        <p className="requirement-footnote"><strong>Note:</strong> We try to assure optimum performance on as many android devices as possible, but because of the large number of Android devices in the market, we are unable to test them all to the same level. A newer version of the Android OS as well as the latest browser (for <a target="_blank" rel="noopener noreferrer" href="https://bbcplayer.com">www.bbcplayer.com</a>) is the best way to ensure good performance with an Android device.</p>
      </li>
      <li>
        <div className="FAQ__question">How many devices can access BBC Player at any one time?</div>
        <p>A maximum of 5 devices can access BBC Player at any one time.</p>
      </li>
      <li>
        <div className="FAQ__question">Can I watch BBC Player on Apple TV?</div>
        <p>If you have an iPhone or iPad, you can Airplay BBC Player Service programmes on your Apple TV. For more details, visit <a target="_blank" rel="noopener noreferrer" href="https://support.apple.com/en-us/HT204289">Apple’s Airplay support page</a>.</p>
      </li>
      <li>
        <div className="FAQ__question">Can I watch the BBC Player with Chromecast on my TV?</div>
        <p>Yes, you can watch BBC Player programmes through your Chromecast device. For more details, visit <a target="_blank" rel="noopener noreferrer" href="https://support.google.com/chromecast/?hl=en-SG">Google’s Chromecast Support Page</a></p>
      </li>
      <li>
        <div className="FAQ__question">Can I access the BBC Player when I am overseas?</div>
        <p>You will not be able to stream or watch programmes online when you are outside of Singapore.</p>
        <p>If you have installed the iOS or Android app, you will be able to watch programmes you have already downloaded onto your device as long as the download(s) has/have not expired.</p>
      </li>
      <li>
        <div className="FAQ__question">I cannot find the BBC Player app in iTunes or Google Play stores. Why is that?</div>
        <p>In this case, you might want to check your store account settings.  If your iTunes account is not set to Singapore, you will not be able to find BBC Player on it. This is because Apple and Google stores will not approve of any apps for any storefront that will not work in that country’s geo. In the same way, if you go to Singapore’s Apple store, you will not be able to find BBC iPlayer.</p>
        <p>You can also access BBC Player via <a target="_blank" rel="noopener noreferrer" href="https://bbcplayer.com">http://www.bbcplayer.com/</a></p>
      </li>
      <li>
        <div className="FAQ__question">How do I cancel my BBC Player subscription?</div>
        <p>To cancel your BBC Player subscription, please get in touch with your service provider.</p>
        <p>For more information, please visit:</p>
        <br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.singtel.com/personal/products-services/lifestyle-services/cast/bbc-player">Singtel CAST Subscribers </a>
        <br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.starhub.com/personal/tvplus/passes/entertainmentplus/bbc-player.html">StarHub TV Subscribers</a>
        <hr className="mobileonly" />
      </li>
    </ul>

    <hr className="non-mobileonly" />
    <div className="BasicPage__header">SECURITY</div>
    <ul>
      <li>
        <div className="FAQ__question">How does the BBC Player support parental guidance and protection for children?</div>
        <p>BBC Player lets you use a PIN to control access to programmes, based on their rating, on supported devices. To set up a PIN, go to ‘Settings’ on the BBC Player app or website.</p>
        <p>Please note that you will have to do this for every new device that you download BBC Player to and for every browser you use to access <a target="_blank" rel="noopener noreferrer" href="https://bbcplayer.com">http://www.bbcplayer.com/</a>.</p>
      </li>
      <li>
        <div className="FAQ__question">Why do I need to log on again on each device and browser?</div>
        <p>The requirement to log on is part of the security feature that helps to prevent unauthorised usage of your BBC Player account/Hub iD.</p>
        <p>You will need to log into every device that you’ve downloaded the BBC Player app onto, and every new computer you’re using for the online version.</p>
        <hr className="mobileonly" />
      </li>
    </ul>

    <hr className="non-mobileonly" />
    <div className="BasicPage__header">STREAMING AND DOWNLOADING PROGRAMMES ON BBC PLAYER</div>
    <ul>
      <li>
        <div className="FAQ__question">What is the difference between streaming and downloading?</div>
        <p><strong>Streamed videos</strong> are videos that are played on one device but are stored on a centralised server on the internet. You need to have a connection to the internet in order to play the video.</p>
        <p><strong>Downloaded videos</strong> are stored on the device that they were downloaded onto. You need a connection to download the video file to get it onto your device, but after it has been downloaded you can watch the video without any internet or 3G/4G/5G connection. BBC Player allows temporary downloads for certain programmes on most iOS and Android devices.</p>
        <p>The difference between streaming and downloaded videos is like the difference between listening to a song on the radio and listening to a song that has been downloaded to your iPad. If you’re listening to a song on the radio, you need to have reception — a connection to the radio station. If you’re listening to a song on iTunes (for example) that has been downloaded on your iPad, you can listen to it anywhere, you don’t need reception or a connection.</p>
      </li>
      <li>
        <div className="FAQ__question">Will I be able to stream and download shows on BBC Player?</div>
        <p>Besides streaming, you can also download selected programmes to view offline for up to 30 days. To check which programmes can be downloaded, click on the individual programmes on the service to get the information. Downloaded programmes will be stored on your device for up to 30 days. Please note that you can only download programmes in the app. If you’re on <a target="_blank" rel="noopener noreferrer" href="https://bbcplayer.com">http://www.bbcplayer.com/</a> you will only be able to stream programmes.</p>
      </li>
      <li>
        <div className="FAQ__question">Is there a maximum number of programmes I can download onto my device/s?</div>
        <p>You can download as many programmes as you want as long as you have enough data storage in your device. </p>
      </li>
      <li>
        <div className="FAQ__question">My downloaded programmes have disappeared. What happened to them?</div>
        <p>The programme downloaded has most likely expired. Besides drama, kids and comedy titles, our catch-up titles are available for up to 30 days. Do check each title for the number of available days left before downloading, and ensure you catch up on your favourite shows before they come off the service!</p>
      </li>
      <li>
        <div className="FAQ__question">Is there a limit to the number of programmes that I can simultaneously download?</div>
        <p>You can add as many programmes as you want to the download queue, but only one download is active at a time. When the first programme has been downloaded, the app automatically proceeds to download the next item in the queue. For optimum performance, we recommend you keep this to a maximum of five.</p>
        <p>If you experience problems with your download queue try deleting all the queued items and add them again.</p>
      </li>
      <li>
        <div className="FAQ__question">Can I access a programme that I have downloaded to one device and watch it on another device?</div>
        <p>All downloads are specific to the device that they have been downloaded to. If you are logged on to BBC Player via another device, you will need to download the programmes onto the current device in order to watch offline.</p>
      </li>
      <li>
        <div className="FAQ__question">Can I download and watch a programme at the same time?</div>
        <p>Yes, this should not be a problem. However, on some devices and in some network conditions, this can cause instability in the app. If you experience issues while watching and downloading a programme, cancel the download. Or wait until the download has been completed before watching it.</p>
        <p>You will need to log into every device that you have downloaded the BBC Player app onto, and every new computer you are using for the online version.</p>
      </li>
      <li>
        <div className="FAQ__question">How long am I able to watch a programme once it's been downloaded?</div>
        <p>The availability window of each title is shown just under the programme title.  During this time, there is no limit to the number of times you can watch this programme.</p>
      </li>
      <li>
        <div className="FAQ__question">How do I find the programme that I have downloaded?</div>
        <p>The programme will be stored in the ‘Downloads’ area of the BBC Player app. To access this area, navigate using the menu on the app and select ‘Downloads’.</p>
      </li>
      <li>
        <div className="FAQ__question">I want to watch the programmes in HD. Is there an option to choose the video quality on BBC Player?</div>
        <p>
        Yes, there is an option to choose the video quality in Video Playback settings.
        </p>
      </li>
      <li>
        <div className="FAQ__question">Can I choose the quality of my downloads? If so, how do I do this?</div>
        <p>In most cases, you will be watching the HD versions. If you wish to lower the video quality (in order to save bandwidth, for example), you can set your preference in the video and audio settings under ‘settings’.</p>
      </li>
      <li>
        <div className="FAQ__question">How do I know how much time I have left to watch my downloaded programme/s?</div>
        <p>You will be able to check how many days you have left to watch the programme in the ‘Downloads’ section of the app.</p>
      </li>
      <li>
        <div className="FAQ__question">I have a data limit on my phone plan – will streaming programmes on BBC Player Service be too costly for me? Will BBC Studios ensure that streaming will be at optimal level and at a reasonable cost for viewers who are watching on their mobile phones?</div>
        <p>We do our best to ensure that technically, the streaming is at an optimal level. However, you might want to check with your mobile provider for the streaming speed and data plan that you have subscribed to ensure that your plan will allow you to get the optimum use of the BBC Player Service. If you exceed your data limit, you may be charged according to your phone plan.</p>
      </li>
      <li>
        <div className="FAQ__question">How often will you be uploading new programmes onto BBC Player Service?</div>
        <p>Programmes will be updated regularly, so please do check back often!</p>
        <hr className="mobileonly" />
      </li>
    </ul>

    <hr className="non-mobileonly" />
    <div className="BasicPage__header">LANGUAGES AND SUBTITLES</div>
    <ul>
      <li>
        <div className="FAQ__question">Will programmes be available in different languages?</div>
      </li>
      <li>
        <div className="FAQ__question">Will the programmes on BBC Player be dubbed or subtitled?</div>
        <p>All programmes are available in English closed captions. Chinese and Bahasa Malaysia subtitles are available for selected programmes. At the moment, BBC Player features content only in English audio.</p>
      </li>
      <li>
        <div className="FAQ__question">How can I switch off the subtitles?</div>
        <p>Subtitles can be switched on or off using the ‘subtitles’ button which is shown in the form of a speech bubble located at the bottom right corner of the video player.</p>
        <hr className="mobileonly" />
      </li>
    </ul>

    <hr className="non-mobileonly" />
    <div className="BasicPage__header">TECHNICAL ISSUES</div>
    <ul>
      <li>
        <div className="FAQ__question">I’m having problems playing programmes on my device. What can I do?</div>
        <p>If you’re having problems playing programmes on your device, the following troubleshooting may help resolve your problem:</p>
        <ul>
          <li><strong>Check your connection type and speed</strong><br></br>Playback quality should change based on your connection speed. If you’re having pausing/buffering problems it’s good to understand your connection performance, for more information contact your internet service provider (ISP).</li>
          <li><strong>3G/4G/5G – Poor performance over a mobile network</strong><br></br>You could try turning your data connection off and on again. However, even if it appears you have a good signal on you 3G/4G mobile network, other factors such as a congested network may affect performance. Connecting to a Wi-Fi network should improve playback and if it’s a consistent problem, consider downloading programmes over Wi-Fi beforehand.</li>
          <li><strong>Is your device trying to connect via an unknown Wi-Fi network (hotspot, work network etc.)?</strong><br></br>If your device is set to ‘connect to Wi-Fi networks automatically’, while you may think you’re connected via your 3G/4G mobile network or a known Wi-Fi network, your device may actually be trying to connect on a public/guest Wi-Fi hotspot or other Wi-Fi network. If you don’t have the password to this public/guest Wi-Fi network, you’ll experience problems. So either try and get a password for the network you’re connected to, disable ‘connect to Wi-Fi networks automatically’ on your device setting to revert back to 3G/4G, or manually select your desired Wi-Fi network.</li>
          <li><strong>Wi-Fi – Poor performance</strong><br></br>If others are using the same network, or if you have other devices connected to the internet, then all these devices are competing for available bandwidth. So try switching other devices off or offline. Also, the further away you are from the router, the worse the signal quality, so try moving closer to your router. Restarting your router may also help.
          </li>
          <li><strong>Are you using the latest version of the app?</strong><br></br>App updates frequently include bug fixes, so please update if you’re not using the latest app version. You can check for updates in your app store or Google play.
          </li>
          <li><strong>Try reinstalling the app</strong><br></br>Sometimes, reinstalling the app may resolve a playback problem. This is also a good way of ensuring you’re using the latest version. Note, however, that when you delete the app, you’ll also delete any downloaded programmes.</li>
          <li><strong>Are you using the latest Operating System version for your device?</strong><br></br>If updates are available, it’s worth considering updating as sometime bugs can only be fixed by an operating system update.</li>
        </ul>
      </li>
      <li>
        <div className="FAQ__question">I’m watching programmes at home via Wi-Fi. Yet I’m having skipping and buffering problems. Why is that?</div>
        <p>If you’re having problems with playback such as the video skipping or buffering, you might want to check the Wi-Fi connection. If the signal is strong, check if other devices on the network are downloading or uploading anything as this may slow down the service.</p>
      </li>
      <li>
        <div className="FAQ__question">I can’t download some of the programmes on BBC Player. Why is that?</div>
        <p>We aim to make as many of our programmes as possible downloadable for up to 30 days. However, because we have different rights agreements with different programme makers, this isn’t always possible.</p>
        <p>To check if your favourite programme can be downloaded, check for the ‘Download’ button shown alongside the programme.</p>
      </li>
      <li>
        <div className="FAQ__question">I am unable to access some programmes on BBC Player and I keep getting a pop-up that asks me to upgrade my subscription.</div>
        <p>This is because you are currently not subscribed to the corresponding BBC channels provided by your service provider.</p>
        <p>For more information, please visit:</p>
        <br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.singtel.com/personal/products-services/lifestyle-services/cast/bbc-player">Singtel CAST Subscribers </a>
        <br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.starhub.com/personal/tvplus/passes/entertainmentplus/bbc-player.html">StarHub TV Subscribers</a>
      </li>
    </ul>
  </div>
)

export default FAQ;

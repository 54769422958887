import React from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { getUrl, Constants } from "../../utilities";
import LiveSubMenuCard from "./LiveSubMenuCard";
import "./LiveSubMenuTab.scss";

const LiveSubMenuTab: React.FunctionComponent<any> = ({ navItem, location, history, handleLiveClick, props }) => {
  const className = classnames("TvGuideButton", {selected: location.pathname.includes(Constants.TvGuideTarget)});
  
  const onClick = () => {
    props.appBodyClassName(undefined);
    history.push(getUrl(`live/guide/${Constants.TvGuidePaths[0]}`))
  }
  
  return (
    <div className="LiveSubMenuTab">
      <div className="LiveSubMenu">
        { navItem.children && navItem.children.map((item, index) =>
          <LiveSubMenuCard props={props} handleLiveClick={handleLiveClick} key={index} index={index} item={item}></LiveSubMenuCard>
        ) }
      </div>
      <button className={className} onClick={onClick}>TV Guide</button>
    </div>
  )
};

export default withRouter(LiveSubMenuTab);

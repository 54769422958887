import React from "react";
import { Button } from "../../../../components/Buttons";

const questions = [
  "What was your childhood nickname?",
  "Name of your first pet?",
  "Name of the street your first house was on?",
  "Name of your primary school?"
];

export default class SelectSecurity extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      question: null
    };
  }

  onSelect = (question) => {
    this.setState({ question })
  }

  onNext = () => {
    const { onComplete } = this.props;
    const { question: securityQuestion } = this.state;
    onComplete && onComplete({ securityQuestion });
  }

  render() {
    const { question } = this.state;

    return (
      <div className="SelectSecurity">
        <div className="Settings__header">2 of 4: Select a security question</div>
        <div className="SelectSecurity__questions">
          { questions.map((q, index)=> (
            <Button className="select-button" key={index} text={q} outlined selected={q === question} onClick={() => this.onSelect(q)} />
          ))}
        </div>
        <Button className="next-button" text="Next" disabled={!question} onClick={this.onNext} />
      </div>
    )
  }
};

import React from "react";
import classnames from "classnames";
import { SVG } from "../../assets/images";
import "./Checkbox.scss";

export default class Checkbox extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      on: props.on
    }
  }

  onClick = () => {
    const { on } = this.state;
    const { onClick } = this.props;

    this.setState({
      on: !on
    }, () => onClick && onClick(this.state.on))
  }

  render() {
    const { on } = this.state
    return (
      <div className={classnames("Checkbox", { on })} onClick={this.onClick}>
        { on && <SVG.Check /> }
      </div>
    )
  }
};

import React, { useState } from "react";
import { isLive, is_00_00_AM, getTime } from "../../utilities";
import { Image } from "../../assets/images";
import "./TvGuideScheduleList.scss";
import classnames from "classnames";
import AnimatedLiveButton from "../../components/AnimatedIcon/AnimatedLiveButton"
import { ImageWordButton } from "../../components/Buttons";

const TvGuideScheduleItem: React.FunctionComponent<any> = ({item, index, firstSchedule, handleLiveClick, channel}) => {
  const now = isLive(item);
  const firstProgramme = is_00_00_AM(item, firstSchedule);
  const [ selected, setSelected ] = useState([false,false]);

  const onclick = (index) => {
    if (now) { 
      const select = [false,false]
      select[index] = true
      setSelected(select);
      item.id = channel.videoIdHD
      item.thumbnail = channel.logo
      item.schedules = channel.schedules
      item.extId = item.houseid
      handleLiveClick(item); 
    }
  }

  return (
    <div id={now ? "liveNow" : firstProgramme ? "first_programme": ""}  className={classnames( "TvGuideSchedule", { live: now })} key={index}>
    <div className={classnames( "TvGuideScheduleItem", { live: now, first_programme: firstProgramme })}>
      <div className="TvGuideScheduleItem_Time">{getTime(item)}</div>
      <div className={classnames( "TvGuideScheduleItem_Details", { live: now, first_programme: firstProgramme })} >
        <div onClick={() => {onclick(0)}} className={classnames( "TvGuideScheduleItem_ProgramTitle", { live: now, first_programme: firstProgramme, selected: selected[0] })}><span>{item.name}</span></div>
        <img className="TvGuideScheduleItem_Divider" src={Image.PinkDivider} alt=""></img>
        <div className="TvGuideScheduleItem_Episode">{`Episode ${item.episodeNumber}: Series ${item.seriesNumber}`}</div>
        <div className={classnames( "TvGuideScheduleItem_Synopsis", { live: now, first_programme: firstProgramme })} >{item.episodeSynopsis}</div>
        { now && <ImageWordButton className="WatchLive" unselect={!selected[1]} click={() => {onclick(1)}}></ImageWordButton> }
      </div>
    </div>
    { now && <AnimatedLiveButton ></AnimatedLiveButton> }
  </div> 
  )
}

export default TvGuideScheduleItem;

import React, { useEffect, useState } from "react";
import { Image } from "../../assets/images";
import _ from "lodash"; 
import "./TvGuide.scss"

const elongated = [
  Image.LiveCbeebies,
  Image.LiveEarth,
  Image.LiveLifestyle
];

const TvGuideImage: React.FunctionComponent<any> = ({selected, channel, index, setSelected, isMobile}) => {
  const bwLogo = _.get(channel,"imageUrls.unselected");
  const colouredLogo = _.get(channel,"imageUrls.selected");
  const [ imageUrl, setImageUrl ] = useState (isMobile ? elongated[index] : selected === index ? colouredLogo : bwLogo) ;

  useEffect(() => {
    if(!isMobile) {
      setImageUrl(selected === index ? colouredLogo : bwLogo);
    } else {
      setImageUrl(elongated[index])
    }
  } , [selected]);

  const handleMouseOver = () => {
    if(selected !== index) {
      setImageUrl(colouredLogo)
    }
  }

  const handleMouseOut = () => {
    if(selected !== index) {
      setImageUrl(bwLogo)
    }
  }
  
  return (
    <div className="Tv_Guide_Image">
    <img  
      alt={channel.title} 
      src={imageUrl}
      onClick={() => {setSelected(index)}}
      onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} 
      ></img>
    </div>

  )
}

export default TvGuideImage;

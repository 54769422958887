import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { getUrl } from "../../utilities";
import "./SubMenuTab.scss";


const SubMenuTab: React.FunctionComponent<any> = ({ navItem: { name, target, logo, image }, location, history, props }) => {
  const className = classnames("SubMenuTab", { selected: location.pathname.includes(target), "image": logo !== null });
  const [imageUrl, setImageUrl] = useState(logo ? logo.unselected : "");

  const handleMouseOver = () => {
    if (!location.pathname.includes(target)) {
      setImageUrl(logo ? logo.selected : "")
    }
  }

  const handleMouseOut = () => {
    if (!location.pathname.includes(target)) {
      setImageUrl(logo ? logo.unselected : "")
    }
  }

  useEffect( () => {
    if (location.pathname.includes(target)) {
      setImageUrl(logo ? logo.selected : "")
    }
  },[location.pathname])

  const onClick = () => {
    props.appBodyClassName(undefined);
    history.push(getUrl(target))
  }

  return (
    <div className={className} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} 
      onClick={onClick}>
      { image ? <img src={imageUrl} alt={name} /> : <span>{name}</span> }
    </div>
  )
};

export default withRouter(SubMenuTab);

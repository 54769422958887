import React from "react";
import { CheckButton, Button } from "../../../../components/Buttons";
import { Fetch } from "../../../../components/Fetch";
import _ from "lodash";

export default class SetRatingLevel extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      rating: null
    };
  }

  onSelect = (rating) => {
    this.setState({ rating })
  }

  onNext = () => {
    const { onComplete } = this.props;
    const { rating } = this.state;
    onComplete && onComplete({ rating: rating.name });
  }

  render() {
    const { rating } = this.state;

    return (
      <div className="SetRatingLevel">
        <div className="Settings__header">2 of 2: Set new rating level</div>
        <div className="SetRatingLevel__ratings">
          <Fetch url={`${process.env.REACT_APP_MIDDLEWARE_URL}/certifications`}>
            {(loading, error, data) => {
              const ratings = _.get(data, "data.certifications.nodes");
              if(!ratings) return null;
              return ratings.map(r => (
                <CheckButton
                  outlined
                  key={r.id}
                  text={r.name}
                  selected={rating && rating.id === r.id}
                  onClick={() => this.onSelect(r)}
                />
              ))
            }}
          </Fetch>
        </div>
        <Button className="next-button" text="Confirm and complete" disabled={!rating} onClick={this.onNext} />
      </div>
    )
  }
};

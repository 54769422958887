import React from "react";
import { Ol, Oli } from "../../../components/OrderedList/OrderedList";

const Terms: React.FunctionComponent<any> = () => (
  <div className="Terms BasicPage">
    <div className="BasicPage__header">BBC Player Terms & Conditions</div>
    <div className="BasicPage__header">(“BBC Player Terms”)</div>
    <p><strong>By your access and/or use of BBC Player, the BBC Player App or BBCS Content, you are deemed to have accepted the BBC Player Terms set out herein. In the event that you disagree with any of the BBC Player Terms, please immediately cease all access and use of BBC Player, the BBC Player App or BBCS Content.</strong></p>

    <Ol counterStyle={Ol.dottedStyle} start={1} increment={0.1}>
      <Oli><strong>Definitions</strong></Oli>
      <Oli><strong>"BBC Player"</strong> is an online video service that can be accessed via the web page <a target="_blank" rel="noopener noreferrer" href="https://player.bbc.com">player.bbc.com</a> or via the BBC Player App.</Oli>
      <Oli><strong>"BBC Player App"</strong> is the BBC Player software application, currently available via the iOS and Android operating systems and which can be downloaded for free from the Apple App Store (for iOS devices) or Google Play Store (for Android devices, including TV set-top boxes).</Oli>
      <Oli><strong>"BBCS Content"</strong> means all content on BBC Player including without limitation live channels, programmes, audiovisual and audio content, logos, artwork, clips, text, images and photographs.</Oli>
    </Ol>

    <Ol counterStyle={Ol.dottedStyle} start={2} increment={0.1}>
      <Oli><strong>BBC Player</strong></Oli>
      <Oli>BBC Player is operated under licence by BBC Studios Distribution Limited (<strong>"BBCS"</strong>) and in Malaysia is only made available to users under licence by TM Net and Astro.</Oli>
      <Oli>BBCS is a wholly-owned subsidiary of the British Broadcasting Corporation (<strong>"BBC"</strong>), and is incorporated in England and Wales under company number 1420028. Our registered office is at 1 Television Centre, 101 Wood Lane, London W12 7FA. Our VAT number is 333289454.</Oli>
      <Oli>The BBC Player Terms apply to your use of BBC Player. By your access or use of BBC Player, you shall be deemed to have accepted and agreed to be legally bound by these BBC Player Terms, the BBCS Website Terms and Conditions at  <a target="_blank" rel="noopener noreferrer" href="https://www.bbcstudios.com/terms-conditions">https://www.bbcstudios.com/terms-conditions</a>, as well as any other terms referred to in these BBC Player Terms. Please note that your use of BBC Player may also be subject to additional terms and conditions by TM Net or Astro (as applicable), which form separate agreements between you and TM Net or Astro respectively.</Oli>
      <Oli>Capitalised terms used in these BBC Player Terms but are not otherwise defined shall have the same meaning given to them in the BBCS Website Terms and Conditions. In the event of any conflict or inconsistency between the BBC Player Terms and the BBCS Website Terms and Conditions, the BBC Player Terms will prevail.</Oli>
    </Ol>

    <Ol counterStyle={Ol.dottedStyle} start={3} increment={0.1}>
      <Oli><strong>Using BBC Player</strong></Oli>
      <Oli> BBC Player is only available to users in Malaysia via the following:</Oli>
      <Ol>
        <Oli> TM Net</Oli>
        <Ol>
          <Oli>&emsp; Subscribers: (i) to one or more of the available BBC Channels on TM Net’s pay television service; or (ii) who have elected to subscribe to BBC Player on a standalone subscription basis.</Oli>
          <Oli>&emsp; Access to BBC Player is: (i) controlled and authenticated by TM Net; and (ii) via your TM Net unifi account number.</Oli>
          <Oli>&emsp; You are responsible for all activity under your TM Net unifi account number.</Oli>
        </Ol>
        <Oli>Astro</Oli>
        <Ol>
          <Oli>&emsp; Subscribers to one or more of the available BBC Channels on Astro’s pay television service.</Oli>
          <Oli>&emsp; Access to BBC Player is: (i) controlled and authenticated by Astro; and (ii) via your Astro ID.</Oli>
          <Oli>&emsp; You are responsible for all activity under your Astro ID.</Oli>
          <Oli>&emsp; BBC Player on your set-top box uses the react-player (Copyright © Pete Cook <a target="_blank" rel="noopener noreferrer" href="http://cookpete.com">http://cookpete.com</a>), access to which is subject to the MIT Licence. THIS SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.</Oli>
        </Ol>
      </Ol>
      <Oli>BBCS Content on BBC Player can be viewed by streaming and in some cases can be temporarily downloaded for playback at a later date (subject to the viewing periods referred to in clause 4.1 below).</Oli>
      <Oli>It is your responsibility to ensure that you have requisite internet connectivity and a compatible device in order to use BBC Player. BBCS is not responsible if you are unable to access BBC Player, view any BBCS Content or if there is any failure, interruption or degradation in the performance of BBC Player arising from any failure in or poor quality of the internet connectivity or mobile network and/or the device you are using or because you have not downloaded any necessary software.</Oli>
      <Oli>Your use of BBC Player may lead to substantial bandwidth usage, which can be expensive. Data charges remain your responsibility, so you should be careful you do not exceed any 'bandwidth cap' on your account with your internet or data service provider.</Oli>
      <Oli>Your installation and/or use of a BBC Player App may also be subject to the terms and conditions of any person(s) owning rights in the software that operates BBC Player App (if any).</Oli>
      <Oli>BBC Player is provided to you, solely for your private viewing and for personal, non-commercial use. Your rights to use it may be further governed by additional terms and conditions with TM Net or Astro (as applicable), which are separate agreements between you and TM Net or Astro respectively . You agree not to use BBC Player for any other purposes, such as public performances.</Oli>
    </Ol>
    
    <Ol counterStyle={Ol.dottedStyle} start={4} increment={0.1}>
      <Oli><strong>BBCS Content</strong></Oli>
      <Oli>Your access to BBCS Content on BBC Player will be limited by and depend on your subscription entitlements with TM Net or Astro you will only have access to BBCS Content from the applicable BBC Channels that you subscribe to on TM Net’s or Astro’s pay television service (as applicable). In respect of programmes made available on BBC Player:</Oli>
      <Ol counterStyle={Ol.dottedStyle} start={1}>
        <Oli>Downloads of BBCS Content will be removed from your device when the viewing period expires, when the BBC Player App is deleted from your device, when you delink your account with the BBC Player App, or when your subscription to the relevant TM Net or Astro service(s) (as applicable) expires or is terminated. By your use of BBC Player you are deemed to have agreed to this deletion from your device.</Oli>
        <Oli>BBCS Content on BBC Player will have differing viewing periods, meaning that each programme will be available on BBC Player for a particular period of time. The viewing period for each programme of BBCS Content will be determined by BBCS in its sole discretion. When the viewing period of a programme is, or has reached, 14 days or less, a notice will usually be shown in the information about the programme. Where BBCS Content has been temporarily downloaded, such download will be automatically deleted on the expiry of the viewing period, and by your use of BBC Player you are deemed to have agreed to this deletion from your device.</Oli>
        <Oli>Downloaded BBCS Content must not be transferred from one device to another (e.g. from your laptop to your mobile phone) and you must not make multiple copies of a single item of BBCS Content.</Oli>
        <Oli>Please see the FAQs for further information.</Oli>
      </Ol>
      <Oli>BBCS Content on BBC Player will be updated and changed regularly and BBCS reserves the right to change or remove BBCS Content at any time. BBCS Content may be removed from BBC Player (amongst other reasons) when our rights have expired, if there are legal issues, or if you breach these BBC Player Terms. </Oli>
      <Oli>BBCS Content as made available on BBC Player must always be viewed using BBC Player.</Oli>
      <Oli>You may not directly or indirectly change, edit, add to or create derivative works from any BBCS Content or other content available on BBC Player.</Oli>
      <Oli>You do not have the right to use any BBCS Content or BBCS or BBC mark/logo on your device or elsewhere except as expressly permitted under these BBC Player Terms.</Oli>
    </Ol>

    <Ol counterStyle={Ol.dottedStyle} start={5} increment={0.1}>
      <Oli><strong>Installing Update(s) to BBC Player</strong></Oli>
      <Oli>You are solely responsible for installing update(s) to BBC Player and for all data charges associated with downloading any such updates. Depending on your device operating system and settings, download of updates may be automatic.</Oli>
      <Oli>You acknowledge that you may not be able to access or use BBC Player unless you install relevant update(s).</Oli>
      <Oli>After you install update(s), you may no longer be able to access BBCS Content previously downloaded and/or the features of BBC Player may change and BBCS will have no liability to you if this occurs.</Oli>
    </Ol>

    <Ol counterStyle={Ol.dottedStyle} start={6} increment={0.1}>
      <Oli><strong>Intellectual Property Rights</strong></Oli>
      <Oli>The intellectual property rights in BBC Player and BBCS Content are either owned by BBCS or third party providers of content. When you access and view BBCS Content through BBC Player you get permission (a licence) from TM Net or Astro (as applicable) to watch the BBCS Content for your personal use, as may be further detailed in terms and conditions with TM Net or Astro (as applicable), which are separate agreements between you and TM Net or Astro respectively.</Oli>
      <Oli>All BBCS Content, including all information, data, text, documents, graphics, logos, designs, images, pictures, photographs, videos, podcasts, weblogs, RSS feeds, widgets, embeddable media players, software, interactive features, advertisements or other content, services or materials (or any part of them) accessible on BBC Player, is protected by copyright, trade marks, database rights and other intellectual property rights. Nothing contained in these BBC Player Terms grants you any licence or right to use the BBCS Content other than as expressly permitted in these BBC Player Terms.</Oli>
      <Oli>Except as specifically permitted under these BBC Player Terms or under applicable law, you agree not to copy, store in any medium (including on any other website), distribute, transmit, re-transmit, re-publish, broadcast, modify, or show in public any part of BBC Player or BBCS Content without our prior written permission (and the prior written consent of the owner of the relevant intellectual property rights if applicable).</Oli>
      <Oli>You must not:</Oli>
      <Ol>
        <Oli>&ensp; circumvent, remove, disable or attempt to bypass any content protection system, digital rights management technology or geographic restriction applied to BBC Player or BBCS Content, for example, by using technology tools to misrepresent your location;</Oli>
        <Oli>&ensp; sell BBCS Content;</Oli>
        <Oli>&ensp; modify BBCS Content in any way;</Oli>
        <Oli>&ensp; remove any copyright or other notices from BBC Player or BBCS Content;</Oli>
        <Oli>&ensp; use BBC Player or BBCS Content in an unlawful or unauthorised way, or in a way that may harm any person or infringe any third party rights;</Oli>
        <Oli>&ensp; use BBC Player or BBCS Content or in a way that suggests an association with our products, services or brands</Oli>
        <Oli>&ensp; use BBC Player or BBCS Content in a way that may bring disrepute to or disparages the BBC group or the BBCS Content; or</Oli>
        <Oli>&ensp; transfer BBCS Content to other devices.</Oli>
      </Ol>
    </Ol>

    <Ol counterStyle={Ol.dottedStyle} start={7} increment={0.1}>
      <Oli><strong>Privacy</strong></Oli>
      <Oli>BBCS and the BBC group take your privacy seriously. Find out more about the information and personal data we collect and what we do with it in the FAQs, our  <a target="_blank" rel="noopener noreferrer" href="https://www.bbcstudios.com/privacy">Privacy Policy</a> and our <a target="_blank" rel="noopener noreferrer" href="https://www.bbcstudios.com/cookies/">Cookies Policy</a>. By your access and/or use of BBC Player, you are deemed to have consented to BBCS and the BBC group collecting and processing your data as described in these policies.</Oli>
      <Oli>TM Net or Astro may also collect, use and/or disclose your personal data for the purposes of administering your access to BBC Player. Please refer to TM Net or Astro directly for more information on their personal data protection policies.</Oli>
    </Ol>

    <Ol counterStyle={Ol.dottedStyle} start={8} increment={0.1}>
      <Oli><strong>Suspension and termination of your account by us</strong></Oli>
      <Oli>If you breach any of these BBC Player Terms we may terminate or suspend your access to BBC Player and/or BBCS Content without giving you notice.</Oli>
    </Ol>

    <Ol counterStyle={Ol.dottedStyle} start={9} increment={0.1}>
      <Oli><strong>Parental Control</strong></Oli>
      <Oli>Some BBCS Content may contain things that aren’t suitable for young people, or things you feel are indecent, objectionable or offensive.</Oli>
      <Oli>If you let a child use your computer or device to access BBCS Content via BBC Player, you are responsible for deciding whether it is appropriate for that child.</Oli>
      <Oli>If you are concerned about whether BBCS Content is suitable for children you can find out more about using parental controls and parental guidance in our FAQs.</Oli>
    </Ol>

    <Ol counterStyle={Ol.dottedStyle} start={10} increment={0.1}>
      <Oli><strong>Liability</strong></Oli>
      <Oli>We take care to make content to the highest editorial, ethical and technical standards. However, BBCS is not responsible or liable for anything that happens to you:</Oli>
      <Ol>
        <Oli>&emsp; if you rely on any advice, data, commentary, opinions or other materials included in any BBCS Content or links we provide;</Oli>
        <Oli>&emsp; if there are errors, omissions, interruptions, delays, bugs or viruses in any BBCS Content or with BBC Player;</Oli>
        <Oli>&emsp; if using BBCS Content, BBC Player (including software updates) or sites we link to causes you loss of data, loss of content or damage to your device or software;</Oli>
        <Oli>&emsp; if we turn off or remove any parts of the BBCS Content, BBC Player or external links; or</Oli>
        <Oli>&emsp; if we remove BBCS Content from BBC Player or your device and or software as permitted by these BBC Player Terms.</Oli>
      </Ol>
    </Ol>

    <Ol counterStyle={Ol.dottedStyle} start={11} increment={0.1}>
      <Oli><strong>General</strong></Oli>
      <Oli>&ensp; These BBC Player Terms together with the additional terms and information set out in our FAQs, our <a target="_blank" rel="noopener noreferrer" href="https://www.bbcstudios.com/privacy">Privacy Policy</a> and <a target="_blank" rel="noopener noreferrer" href="https://www.bbcstudios.com/cookies/">Cookies Policy</a> and the <a target="_blank" rel="noopener noreferrer" href="https://www.bbcstudios.com/terms-conditions">BBCS Website Terms and Conditions</a> are all the terms and conditions between you and us in relation to your use of BBC Player, and supersede all previous agreements about your use of BBC Player.</Oli>
      <Oli>&ensp; We sometimes update these BBC Player Terms, so please review them regularly. By continuing to use BBC Player after such update, you agree to comply with the updated version of these BBC Player Terms.</Oli>
      <Oli>&ensp; These Terms and Conditions do not give any enforceable rights to any person who is not party to them.</Oli>
      <Oli>&ensp; Any dispute, controversy, proceedings or claim arising out of or in connection with these BBC Player Terms, including any question regarding their existence, validity or termination, will be governed by and construed in accordance with the laws of Singapore and the parties irrevocably submit to the exclusive jurisdiction of the courts of Singapore.</Oli>
      <Oli>&ensp; If you have any questions about BBC Player, please see the FAQs. For TM Net or Astro subscription and login issues, please contact TM Net and Astro respectively.</Oli>
    </Ol>
    <p><strong>Version dated 2 February 2024</strong></p>
  </div>
)

export default Terms;

import React, { ReactElement } from "react";
import classnames from "classnames";
import _ from "lodash";
import "./OrderedList.scss";

const Oli: React.FunctionComponent<any> = ({ counter, children, ...props }) => (
  <div className="Oli" {...props}>
    <div className="Oli__counter">{counter}</div>
    <div className="Oli__content">{children}</div>
  </div>
);

class Ol extends React.Component<any, any> {
  static defaultProps = {
    counterStyle: (index) => `${index}:`,
    start: 1,
    increment: 1,
    level: 0
  };
  static dottedStyle = (index) => {
    let parts = String(index.toFixed(3)).split(".");
    parts = _.concat(parts[0], parts.pop()!.replace(/[0]{1,3}$/,"").split(""));
    return `${parts.join(".")}.`;
  };

  render() {
    const { counterStyle, start, increment, children, level, ...props } = this.props;
    const levelClass = level ? `level${level}` : undefined;
    let i = 0;

    return (
      <div className={classnames("Ol", levelClass)} {...props}>
        {
          (Array.isArray(children) ? children : [children]).map(child => {
            const type = (child as any).type;
            let el;

            if(type === Oli) {
              el = React.cloneElement(child as ReactElement, {
                key: i,
                counter: counterStyle(start + (increment * i), increment)
              });
              i++;
            }
            else if(type === Ol) {
              i--;
              el = React.cloneElement(child as ReactElement, {
                key: `${i}l${level}`,
                start: start + (increment * i) + (increment / 10),
                counterStyle,
                increment: increment / 10,
                level: level + 1
              });
              i++;
            }
            else {
              el = null;
            }
            return el;
          })
        }
      </div>
    )
  }
};

export { Ol, Oli };

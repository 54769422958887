import React from "react";
import Modal from "../Modal";
import { Button } from "../../Buttons";
import PinInput from "../../PinInput/PinInput";
import { withStore } from "../../../store";
import { withRouter } from "react-router";
import { compose } from "redux";
import { getUrl } from "../../../utilities";
import "./PinInputModal.scss";

class PinInputModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      pin: null,
      valid: true,
      unselected: false
    };
  };

  validate = (onClose) => {
    const { onComplete, store: { settings: { pin: currentPin } } } = this.props;
    const { pin } = this.state;

    if(pin !== currentPin) {
      this.setState({ valid: false })
    }
    else {
      onClose();
      onComplete && onComplete();
    }
  }

  onPinComplete = (pin) => {
    this.setState({ pin, unselected: true }, () => (document.querySelector(".next-button") as HTMLElement).focus());
  }

  onPinIncomplete = () => {
    this.setState({ pin: null, valid: true, unselected: false });
  }

  forgotPin = () => {
    const { onClose, history } = this.props;
    onClose && onClose();
    history.push(getUrl("/settings?page=pin&f=forgotPin"));
  }

  render() {
    const { onClose } = this.props;
    const { pin, valid, unselected } = this.state;

    return (
      <Modal onClose={onClose} shouldCloseOnEsc={false} shouldShowCloseButton={true}>
        {(onClose) => (
          <div className="PinInputModal">
            <div className="PinInputModal__title">Enter your PIN to continue watching restricted content</div>
            <PinInput onComplete={this.onPinComplete} onIncomplete={this.onPinIncomplete} error={!valid} />
            <Button className="next-button" unselected={unselected} disabled={!pin} text="Next" onClick={() => this.validate(onClose)} />
            <Button text="Forgotten your PIN?" white onClick={this.forgotPin} />
          </div>
        )}
      </Modal>
    )
  }
}


export default compose<any>(withStore, withRouter)(PinInputModal);

import React from "react";
import { withRouter } from "react-router";
import {
  Constants,
  lockScroll,
  unlockScroll,
  addWindowChangeListener,
  removeWindowChangeListener
} from "../../utilities";
import _ from "lodash";
import classnames from "classnames";
import MediaQuery from "react-responsive";
import MobileSearchHeader from "./MobileSearchHeader";
import SearchBar from "./SearchBar";

class Search extends React.Component<any, any> {
  static defaultProps = {
    minCharacters: 3
  }

  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      value: props.value || "",
      manualSearch: false
    }
  }

  componentDidMount() {
    addWindowChangeListener(this.checkScrollLock);
    this.checkScrollLock();
  }

  componentWillUnmount() {
    removeWindowChangeListener(this.checkScrollLock);
  }

  checkScrollLock = () => {
    const { focused, value } = this.state;
    const { minCharacters } = this.props;
    if((window.innerWidth <= Constants.MobileWidth && focused) || value.length >= minCharacters) {
      lockScroll();
    }
    else {
      unlockScroll();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
    if(!prevState.focused && this.state.focused) {
      const input = document.querySelector(".Search__input > input") as HTMLElement;
      if(input && document.activeElement !== input) {
        input.focus();
      }
    }
    if(prevProps.location.key !== this.props.location.key) {
      this.onClose();
    }
  }

  onChange = (value) => {
    const { minCharacters, onChange, property, onSearch } = this.props;
    const { value: currentValue } = this.state;

    if(value === currentValue)
      return;

    this.setState({ value, manualSearch: false }, this.checkScrollLock);

    if(onChange && (value.length >= minCharacters || !value.length)) {
      let debouncedOnChange = _.debounce(onChange, 750);
      debouncedOnChange(value, property);
    }

    if(value.length >= minCharacters) {
      onSearch && onSearch(value)
    }
  };

  onFocus = () => {
    const { onFocus } = this.props;
    this.setState({ focused: true }, this.checkScrollLock)
    onFocus && onFocus();
  }

  onBlur = () => {
    const { value } = this.state;
    const { onBlur, minCharacters } = this.props;
    if(value.length < minCharacters) {
      this.setState({ focused: false, value: "", manualSearch: false }, this.checkScrollLock);
      onBlur && onBlur();
    }
  }

  onClose = () => {
    const { onBlur } = this.props;
    this.setState({ focused: false, value: "", manualSearch: false }, this.checkScrollLock);
    onBlur && onBlur();
  }

  search = () => {
    const { onSearch } = this.props;
    const { value } = this.state;
    if(value.length) {
      this.setState({ manualSearch: true });
      onSearch && onSearch(value)
    }
  }

  render() {
    const { className } = this.props;
    const { value, focused } = this.state;
    return (
      <div className={classnames("Search", className)}>
        <MediaQuery maxWidth={Constants.MobileWidth}>
          <MobileSearchHeader focused={focused} onClose={this.onClose} />
        </MediaQuery>
        <SearchBar
          focused={focused}
          value={value}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onClose={this.onClose}
          onManualSearch={this.search}
        />
      </div>
    )
  }
};

export default withRouter(Search);

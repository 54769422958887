import React from "react";
import Overlay from "../Overlay";
import { Image } from "../../../assets/images";
import classnames from "classnames";
import "./LoadingOverlay.scss";

const LoadingOverlay: React.FunctionComponent<any> = ({ message = "Loading", className }) => {
  return (
    <Overlay className={classnames("LoadingOverlay", className)} message={message}>
      <img className="LoadingOverlay__loading" src={Image.Loading} alt="loading" />
    </Overlay>
  )
};

export default LoadingOverlay;

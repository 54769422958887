import Logo from "./logo.svg";
import Settings from "./settings.svg";
import Search from "./search.svg";
import Close from "./close.svg";
import Arrow from "./arrow.svg";
import ArrowRaster from "./arrow-png.png"
import Play from "./play.svg";
import Warning from "./warning.svg";
import ToggleOff from "./toggle-off.svg";
import ToggleOn from "./toggle-on.svg";
import Check from "./tick.svg";
import Loading from "./loading.svg";
import StarhubLogo from "./starhub.svg";
import AffiliateStarhubLogo from "./starhublogo.svg";
import AffiliateSingtelLogo from "./singtellogo.svg";
import AstroLogo from "./astro-logo.png";
import UnifiLogo from "./unifi-logo.png";
import AboutImage from "./about-image.png";
import SmallFallback from "./fallback-small.png";
import LargeFallback from "./fallback-large.png";

import BBCBrit from "./channels/brit.svg";
import CBeebies from "./channels/cbeebies.svg";
import BBCEarth from "./channels/earth.svg";
import BBCFirst from "./channels/first.svg";
import BBCLifestyle from "./channels/lifestyle.svg";
import BBCWorldNews from "./channels/worldnews.svg";
import BBCBrit_DEF from "./channels/default/BBC-Brit.svg";
import CBeebies_DEF from "./channels/default/CBeebies.svg";
import BBCEarth_DEF from "./channels/default/BBC-Earth.svg";
import BBCFirst_DEF from "./channels/default/BBC-First.svg";
import BBCLifestyle_DEF from "./channels/default/BBC-Lifestyle.svg";
import Download from "./download.svg";
import TvGuide from "./tv-guide.svg";
import SignIn from "./sign-in.svg";
import PinkDownload from "./more/More-Downloads-Selected.svg";
import PinkTvGuide from "./more/More-TV-Schedule-Selected.svg";
import PinkSignIn from "./more/More-Sign-In-Selected.svg";
import PinkDivider from "./pink-stoke.svg";
import ChevronLeft from "./chevron-left.svg";
import ChevronRight from "./chevron-right.svg";
import DisChevronLeft from "./chevron-left-disabled.svg";
import DisChevronRight from "./chevron-right-disabled.svg";
import Live from "./live.svg";
import PlayButton from "./play-button.svg";
import PinkPlayButton from "./pink-playbutton.svg";
import LiveCbeebies from "./channels/elongated/Live-CBeebies.svg";
import LiveEarth from "./channels/elongated/Live-Earth.svg";
import LiveLifestyle from "./channels/elongated/Live-Lifestyle.svg";
import Calender from "./calender.svg";

import { ReactComponent as LogoSVG } from "./logo.svg";
import { ReactComponent as SettingsSVG } from "./settings.svg";
import { ReactComponent as SearchSVG } from "./search.svg";
import { ReactComponent as CloseSVG } from "./close.svg";
import { ReactComponent as ArrowSVG } from "./arrow.svg";
import { ReactComponent as PlaySVG } from "./play.svg";
import { ReactComponent as LargePlaySVG } from "./play-large.svg";
import { ReactComponent as PauseSVG } from "./pause.svg";
import { ReactComponent as BackwardSVG } from "./backward.svg";
import { ReactComponent as ForwardSVG } from "./forward.svg";
import { ReactComponent as CheckSVG } from "./tick.svg";
import { ReactComponent as WarningSVG } from "./warning.svg";
import { ReactComponent as SubtitlesSVG } from "./subtitles.svg";
import { ReactComponent as FullscreenSVG } from "./fullscreen.svg";
import { ReactComponent as ExitFullscreenSVG } from "./exit-fullscreen.svg";
import { ReactComponent as VolumeSVG } from "./volume-btn-white.svg";
import { ReactComponent as VolumeOffSVG } from "./volume-off-btn-white.svg";
import { ReactComponent as LockSVG } from "./lock.svg";
import { ReactComponent as DownloadSVG } from "./download.svg";
import { ReactComponent as TvGuideSVG } from "./tv-guide.svg";
import { ReactComponent as SignInSVG } from "./sign-in.svg";
import { ReactComponent as PinkDownloadSVG } from "./more/More-Downloads-Selected.svg";
import { ReactComponent as PinkTvGuideSVG } from "./more/More-TV-Schedule-Selected.svg";
import { ReactComponent as PinkSignInSVG } from "./more/More-Sign-In-Selected.svg";
import { ReactComponent as PinkDividerInSVG } from "./pink-stoke.svg";
import { ReactComponent as ChevronLeftInSVG } from "./chevron-left.svg";
import { ReactComponent as ChevronRightInSVG } from "./chevron-right.svg";
import { ReactComponent as DisChevronLeftInSVG } from "./chevron-left-disabled.svg";
import { ReactComponent as DisChevronRightInSVG } from "./chevron-right-disabled.svg";
import { ReactComponent as LiveInSVG } from "./live.svg";
import { ReactComponent as PlayButtonInSVG } from "./play-button.svg";
import { ReactComponent as PinkPlayButtonInSVG } from "./pink-playbutton.svg";
import { ReactComponent as LiveCbeebiesInSVG } from "./channels/elongated/Live-CBeebies.svg";
import { ReactComponent as LiveEarthInSVG } from "./channels/elongated/Live-Earth.svg";
import { ReactComponent as LiveLifestyleInSVG } from "./channels/elongated/Live-Lifestyle.svg";
import { ReactComponent as CalenderInSVG } from "./calender.svg";
import { ReactComponent as AudioSVG } from "./audio.svg";

const Image = {
    Logo,
    Settings,
    Search,
    Close,
    Arrow,
    ArrowRaster,
    Play,
    Warning,
    ToggleOff,
    ToggleOn,
    Check,
    Loading,
    StarhubLogo,
    AffiliateStarhubLogo,
    AffiliateSingtelLogo,
    AstroLogo,
    UnifiLogo,
    BBCBrit,
    BBCEarth,
    BBCFirst,
    BBCLifestyle,
    BBCWorldNews,
    CBeebies,
    AboutImage,
    SmallFallback,
    LargeFallback,
    BBCBrit_DEF,
    CBeebies_DEF,
    BBCEarth_DEF,
    BBCFirst_DEF,
    BBCLifestyle_DEF,
    Download,
    TvGuide,
    SignIn,
    PinkTvGuide,
    PinkDownload,
    PinkSignIn,
    PinkDivider,
    ChevronLeft,
    ChevronRight,
    Live,
    PlayButton,
    PinkPlayButton,
    LiveCbeebies,
    LiveEarth,
    LiveLifestyle,
    DisChevronLeft,
    DisChevronRight,
    Calender,
    AudioSVG
};

const SVG = {
  Logo: LogoSVG,
  Settings: SettingsSVG,
  Search: SearchSVG,
  Close: CloseSVG,
  Arrow: ArrowSVG,
  Play: PlaySVG,
  LargePlay: LargePlaySVG,
  Pause: PauseSVG,
  BackwardSVG: BackwardSVG,
  ForwardSVG: ForwardSVG,
  Check: CheckSVG,
  Warning: WarningSVG,
  Subtitles: SubtitlesSVG,
  Fullscreen: FullscreenSVG,
  ExitFullscreen: ExitFullscreenSVG,
  Volume: VolumeSVG,
  VolumeOff: VolumeOffSVG,
  Lock: LockSVG,
  Download: DownloadSVG,
  TvGuide: TvGuideSVG,
  SignIn: SignInSVG,
  PinkTvGuide: PinkTvGuideSVG,
  PinkDownload: PinkDownloadSVG,
  PinkSignIn: PinkSignInSVG,
  PinkDivider: PinkDividerInSVG,
  ChevronRight: ChevronRightInSVG,
  ChevronLeft: ChevronLeftInSVG,
  Live: LiveInSVG,
  PlayButton: PlayButtonInSVG,
  PinkPlayButton: PinkPlayButtonInSVG,
  LiveCbeebies: LiveCbeebiesInSVG,
  LiveEarth: LiveEarthInSVG,
  LiveLifestyle: LiveLifestyleInSVG,
  DisChevronLeft: DisChevronLeftInSVG,
  DisChevronRight: DisChevronRightInSVG,
  Calender: CalenderInSVG,
  AudioSVG: AudioSVG
}

export { Image, SVG };

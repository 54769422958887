import React from "react";
import classnames from "classnames";
import { Image } from "../../assets/images";

export default class ToggleButton extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      on: props.on
    }
  }

  onClick = () => {
    const { on } = this.state;
    const { onClick } = this.props;

    this.setState({
      on: !on
    }, () => onClick && onClick(this.state.on))
  }

  render() {
    const { on } = this.state
    return (
      <div className={classnames("ToggleButton", { on })} onClick={this.onClick}>
        <img src={on ? Image.ToggleOn : Image.ToggleOff} alt="toggle" />
        <span>{on ? "On" : "Off"}</span>
      </div>
    )
  }
};

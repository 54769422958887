import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { getUrl, epochToTime, Constants } from "../../utilities";
import ProgressBar from "../ProgressBar/ProgressBar";
import "./LiveSubMenuTab.scss";
import _ from "lodash"; 

const LiveSubMenuCard: React.FunctionComponent<any> = ({ item, index, location, history, handleLiveClick, props }) => {
  const [progress, setProgress] = useState(0);
  const target = Constants.LiveTarget + item.id;
  const bwLogo = _.get(item,"imageUrls.nav.blackNwhite");
  const colouredLogo = _.get(item,"imageUrls.nav.colored");
  const className = classnames("LiveSubMenu_Card", {selected: location.pathname.includes(target)});
  const [imageUrl, setImageUrl] = useState(bwLogo);
  const [hovered, setHovered] = useState(false);

  const handleMouseOver = () => {
    if (!location.pathname.includes(target)) {
      setImageUrl(colouredLogo)
      setHovered(true)
    }
  }

  const handleMouseOut = () => {
    if (!location.pathname.includes(target)) {
      setImageUrl(bwLogo)
      setHovered(false)
    }
  }

  useEffect( () => {
    if (location.pathname.includes(target)) {
      setImageUrl(colouredLogo)
      setHovered(true)
    }
  },[location.pathname])

  useEffect( () => {
    const currentTime = Date.now();
    const elapsedSeconds = Math.floor((currentTime - item.now.startTime_utc) / 1000);
    const calculatedProgress = ((elapsedSeconds / (item.now.duration*60)));
    setProgress(calculatedProgress < 1 ? calculatedProgress : 1);
  } );

   
  const onclick = async() => {
    await handleLiveClick(item);
    props.appBodyClassName(undefined);
  }

  return (
    <div className={className} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={onclick}>
      <div className="LiveSubMenu_Card_Logo_Logo">
        <div className={`LiveSubMenu_Card_Image ${imageUrl === bwLogo ? "" : "LiveSubMenu_Card_CL"}`}><img className={`LiveSubMenu_Card_${imageUrl === bwLogo ? "BW" : "CL"}Image`}
         alt={Constants.Live+"-"+index} src={imageUrl}></img></div>
        <ProgressBar className="LiveSubMenu_Card_Progress" progress={progress} ></ProgressBar>
      </div>
      <div className={classnames("LiveSubMenu_Card_Title", {hover: hovered, truncate: true})}>{item.now.name}</div>
      <div className={classnames("LiveSubMenu_Card_Schedule", {hover: hovered})}>{epochToTime(item.now.startTime_utc,item.now.duration)}</div>
    </div>
  )
};

export default withRouter(LiveSubMenuCard);

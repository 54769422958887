import React from "react";
import { Button } from "../../../../components/Buttons";
import { PlainInput } from "@brightcove/studio-components";

export default class AnswerSecurity extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      answer: ""
    };
  }

  componentDidMount() {
    const inputContainer = document.getElementById("securityanswer");
    if (inputContainer) {
      inputContainer.focus()
    }
  }

  onInput = (answer) => {
    this.setState({ answer })
  }

  onNext = () => {
    const { onComplete } = this.props;
    const { answer: securityAnswer } = this.state;
    onComplete && onComplete({ securityAnswer: securityAnswer.trim() });
  }

  render() {
    const { answer } = this.state;
    const { securityQuestion } = this.props;

    return (
      <div className="AnswerSecurity">
        <div className="Settings__header">3 of 4: Select a security answer</div>
        <div className="Settings__subheader">{`Now add your answer to: ${securityQuestion}`}</div>
        <div className="AnswerSecurity__input">
          <PlainInput id={"securityanswer"} value={answer} onChange={this.onInput} />
        </div>
        <Button className="next-button" text="Next" disabled={!answer.trim()} onClick={this.onNext} />
      </div>
    )
  }
};

import React, { ReactElement } from "react";
import { Image } from "../../assets/images";
import classnames from "classnames";
import { addWindowChangeListener, removeWindowChangeListener } from "../../utilities";
import "./Scroller.scss";

const SmoothScrollDistance = 35;
const ShiftScrollDuration = 900;
const ScrollUpdateInterval = 50;

const EasingFn = {
  easeOutQuint: (x) => {
    return 1 - Math.pow(1 - x, 5);
  },
  easeOutCirc: (x) => {
    return Math.sqrt(1 - Math.pow(x - 1, 2));
  },
  easeOutExpo: (x) => {
    return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
  },
  easeInOutQuint: (x) => {
    return x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2;
  },
  easeInOutCirc: (x) => {
    return x < 0.5
      ? (1 - Math.sqrt(1 - Math.pow(2 * x, 2))) / 2
      : (Math.sqrt(1 - Math.pow(-2 * x + 2, 2)) + 1) / 2;
    }
};

interface ScrollerProps {
  scrollType?: "smooth" | "shift"
  children: React.ReactNode | Element
  size?: number
  onScroll?: (change: any) => void
  shiftPosition?: (container: React.ReactNode | Element, direction: number, toEnd?: boolean) => number
}

export default class Scroller extends React.Component<ScrollerProps, any> {
  scrollTimeout;
  scrollInterval;
  scrollElement;

  static defaultProps = {
    scrollType: "smooth"
  }

  constructor(props) {
    super(props);
    this.state = {
      atStart: true,
      atEnd: false,
      canScroll: false
    }
  }

  determineScroll = () => {
    this.setState({ canScroll: this.scrollElement.scrollWidth > this.scrollElement.clientWidth })
  }

  getShiftPosition = (direction, toEnd = false) => {
    const { shiftPosition } = this.props;
    return (shiftPosition && shiftPosition(this.scrollElement, direction, toEnd)) || this.scrollElement.clientWidth;
  }

  componentDidMount() {
    addWindowChangeListener(this.onWindowChange);
    this.determineScroll();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.size !== this.props.size) {
      this.determineScroll();
    }
  }

  componentWillUnmount() {
    removeWindowChangeListener(this.onWindowChange);
  }

  onWindowChange = () => {
    this.setState({
      atStart: this.scrollElement.scrollLeft === 0,
      atEnd: this.scrollElement.scrollLeft === (this.scrollElement.scrollWidth - this.scrollElement.clientWidth),
      canScroll: this.scrollElement.scrollWidth > this.scrollElement.clientWidth
    });
  }

  scrollProps = (direction) => {
    const { scrollType } = this.props;
    return scrollType === "shift" ?
      {
        onClick: () => {
          if(!this.scrollTimeout) {
            this.shiftScroll(direction, 0, this.scrollElement.scrollLeft, this.getShiftPosition(direction));
          }
          else {
            clearTimeout(this.scrollTimeout);
            this.shiftScroll(direction, 0, this.scrollElement.scrollLeft, this.getShiftPosition(direction, true));
          }
        }
      } :
      {
        onClick: () => this.startScroll(direction, true),
        onMouseDown: () => this.startScroll(direction),
        onTouchStart: () => this.startScroll(direction),
        onMouseUp: this.stopScroll,
        onTouchEnd: this.stopScroll,
        onTouchCancel: this.stopScroll,
        onMouseLeave: this.stopScroll
      }
  }

  shiftScroll = (direction, time, initScrollLeft, scrollDist) => {
    const checkChange = () => {
      let change;
      if(this.scrollElement.scrollLeft === 0)
        change = { atStart: true, atEnd: false };
      else if(this.scrollElement.scrollLeft === (this.scrollElement.scrollWidth - this.scrollElement.clientWidth))
        change = { atStart: false, atEnd: true };
      else
        change = { atStart: false, atEnd: false };

      this.setState(change);
    }

    time += ScrollUpdateInterval;

    this.scrollElement.scrollLeft = initScrollLeft + EasingFn.easeInOutQuint(time/ShiftScrollDuration) * scrollDist * direction;
    this.scrollTimeout = setTimeout(() => this.shiftScroll(direction, time, initScrollLeft, scrollDist), ScrollUpdateInterval);

    if(this.scrollElement.scrollLeft === initScrollLeft + scrollDist || time >= ShiftScrollDuration) {
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = undefined;
    }
    checkChange();
  }

  startScroll = (direction, clicked: boolean = false) => {
    const { atStart, atEnd } = this.state;

    this.scrollInterval = setInterval(() => {
      let change;
      this.scrollElement.scrollLeft += SmoothScrollDistance * direction;

      if(this.scrollElement.scrollLeft === 0) {
        if(direction === -1) {
          this.stopScroll();
        }
        if(!atStart) {
          change = { atStart: true, atEnd: false };
        }
      }
      else if(this.scrollElement.scrollLeft === (this.scrollElement.scrollWidth - this.scrollElement.clientWidth)) {
        if(direction === 1) {
          this.stopScroll();
        }
        if(!atEnd) {
          change = { atStart: false, atEnd: true };
        }
      }
      else {
        if(atStart || atEnd) {
          change = { atStart: false, atEnd: false };
        }
      }

      this.setState(change);

      if(clicked) {
        this.stopScroll();
      }
    }, ScrollUpdateInterval);
  }

  stopScroll = () => {
    this.scrollInterval && clearInterval(this.scrollInterval);
  }

  onScroll = (event) => {
    const { onScroll } = this.props;
    const el = event.target;
    const change = {
      atStart: el.scrollLeft === 0,
      atEnd: el.scrollLeft === (el.scrollWidth - el.clientWidth)
    };

    onScroll && onScroll(change);
    this.setState(change);
  }

  render() {
    const { atStart, atEnd, canScroll } = this.state;
    const { children } = this.props;

    return (
      <React.Fragment>
        { React.cloneElement(children as ReactElement, { ref: (node) => this.scrollElement = node, onScroll: this.onScroll }) }
        <div className="Scroller">
          { canScroll &&
            <React.Fragment>
              <div className={classnames("Scroller__left", { disabled: atStart })} {...this.scrollProps(-1)}>
                <img alt="" src={Image.ArrowRaster}/>
              </div>
              <div className={classnames("Scroller__right", { disabled: atEnd })} {...this.scrollProps(1)}>
                <img alt="" src={Image.ArrowRaster}/>
              </div>
            </React.Fragment>
          }
        </div>
      </React.Fragment>
    )
  }
};

import React from "react";
import { ChoosePin, SelectSecurity, AnswerSecurity, SetRatingLevel } from  "./ChoosePin";
import TurnOffPin from "./TurnOffPin/TurnOffPin";
import { ChangePin, ConfirmPin } from "./ChangePin";
import { Button, CheckButton } from "../../../components/Buttons";
import { ConfirmRatingPin, ChangeRatingLevel } from "./ChangeRating";
import { CheckSecurityQuestion, AddPin } from "./ForgotPin";
import { SettingsSuccessModal } from "../../../components/Modals";
import { withStore } from "../../../store";
import { withRouter } from "react-router";
import { compose } from "redux";
import { Analytics } from "../../../utilities";
import "./PinManagement.scss";

const flows = {
  "choosePin": [
    ChoosePin,
    SelectSecurity,
    AnswerSecurity,
    SetRatingLevel
  ],
  "turnOffPin": [
    TurnOffPin
  ],
  "changePin": [
    ConfirmPin,
    ChangePin
  ],
  "changeRating": [
    ConfirmRatingPin,
    ChangeRatingLevel
  ],
  "forgotPin": [
    CheckSecurityQuestion,
    AddPin
  ]
};

const PinManagementHome: React.FunctionComponent<any> = ({ startFlow, pin, rating }) => {
  return (
    <React.Fragment>
      { !pin &&
        <Button className="menu-button" text="Choose PIN" outlined onClick={startFlow("choosePin")} />
      }
      { pin &&
        <React.Fragment>
          <Button className="menu-button" text="Turn off PIN" outlined onClick={startFlow("turnOffPin")} />
          <Button className="menu-button" text="Change PIN" outlined onClick={startFlow("changePin")} />
          <Button className="menu-button" text="Forgotten your PIN?" outlined onClick={startFlow("forgotPin")} />
          <div className="PinManagement__rating">
            { rating && <CheckButton className="rating-button" outlined selected text={rating} /> }
            <Button className="change-rating-button" text="Change" outlined onClick={startFlow("changeRating")} />
        </div>
        </React.Fragment>
      }
    </React.Fragment>
  )
};

class PinManagement extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidMount() {
    Analytics.pageViewSettings();
  }

  getInitialState = () => {
    const { store: { settings } } = this.props;
    const { location } = window;
    const flow = location.search.match(/f=([a-zA-Z-]+)/);

    if(flow && flows[flow[1]] !== undefined) {
      return {
        flow: flow[1],
        step: 0,
        flowState: settings
      }
    }
    return {
      flow: null,
      step: null,
      flowState: {}
    };
  }

  startFlow = (flow, step = 0) => {
    return () => {
      const { store: { settings } } = this.props;

      this.setState({
        flow,
        step,
        flowState: settings
      });
    }
  }

  nextInFlow = (updates = {}) => {
    const { updateMultipleSettings } = this.props;
    const { flow, step, flowState } = this.state;
    const updatedFlowState = {
      ...flowState,
      ...updates
    };

    if(step === flows[flow].length - 1) {
      updateMultipleSettings(updatedFlowState);
      this.setState({
        showConfirmation: true
      })
    }
    else {
      this.setState({
        step: step + 1,
        flowState: updatedFlowState
      });
    }
  }

  onConfirmation = () => {
    const { store: { settings } } = this.props;

    this.setState({
      flow: null,
      step: null,
      flowState: settings,
      showConfirmation: false
    });
  }

  render() {
    const { flow, step, flowState, showConfirmation } = this.state;
    const { toSignOut, store: { settings } } = this.props;
    const { pin, rating } = settings;
    const FlowStepComponent = flow && flows[flow][step];

    return (
      <div className="PinManagement">
      { flow ?
          <React.Fragment>
            <FlowStepComponent {...flowState} toSignOut={toSignOut} startFlow={this.startFlow} onComplete={this.nextInFlow}/>
            { showConfirmation && <SettingsSuccessModal onClose={this.onConfirmation} /> }
          </React.Fragment> :
          <PinManagementHome startFlow={this.startFlow} pin={pin} rating={rating} toSignOut={toSignOut} />
      }
      </div>
    )
  }
}

export default compose<any>(withRouter, withStore)(PinManagement);

import { version } from '../../package.json';
import { getAnalyticsPage, User, analyticsSeriesName, getStorageItem } from './';

const Echo = (window as any).echo_js; // TODO: Switch to Echo import

const EchoClient = Echo.EchoClient,
  Media = Echo.Media,
  Enums = Echo.Enums,
  LabelKeys = Echo.Enums.EchoLabelKeys,
  ConfigKeys = Echo.ConfigKeys;

const sequentialEventTimeSpacing = 100;
const AppName = "bbcplayer";
const AdvertiserId = "BBCPlayer~Web";
const Language = "en-uk";
const Destination = process.env.REACT_APP_WEB_ENVIRONMENT === "production" ? Enums.Destinations.BBC_STUDIOS_PLAYER : Enums.Destinations.BBC_STUDIOS_PLAYER_TEST;
const DebugMode = process.env.NODE_ENV !== "production" && "info";

enum EventType {
  Impression = 1,
  Click = 0
}

enum ActionType {
  Select = "select",
  Error = "error",
  UserStateChange = "user_state_change",
  Module = "module",
  Complete = "complete",
  Next = "next",
  None = "none"
}

enum CampaignID {
  Application = "Application",
  Error = "Error",
  Page = "Page",
  Player = "Player"
};

enum Label {
  CampaignID = "container",
  Variant = "personalisation",
  Format = "metadata",
  AdvertiserId = "source",
  Url = "result",
  IsBackground = "is_background"
};

enum UserStatus {
  Unauthenticated = "unauth",
  FreeTrail = "freetrial",
  Subscribed = "subscribed",
  Unsubscribed = "unsubscribed",
  FreeTrialExpired = "freetrial_expired"
};

enum ContentType {
  Account = "account",
  Channel = "channels",
  Home = "index-home",
  Category = "index-category",
  Admin = "admin",
  Brand = "brand",
  Search = "search",
  List = "list-curated",
  Episode = "episode",
  TvGuide = "live",
  LivePlayer = "live"
};

enum CustomOperatorName {
  starhub = "STARHUB",
  singtel = "SINGTEL",
  tm = "TM",
  astro = "ASTRO",
  accOrg = "account_organisation"
}

const getUserStatus = async () => {
  if(!User.authenticated())
    return UserStatus.Unauthenticated;
  else if(User.hasActiveSubscription())
    return UserStatus.Subscribed;
  else if(await User.hasExpiredFreeTrial())
    return UserStatus.FreeTrialExpired;
  else if(User.hasFreeTrial())
    return UserStatus.FreeTrail;
  else if(!User.hasActiveSubscription())
    return UserStatus.Unsubscribed;
};

const wait = async (fn) => setTimeout(fn, sequentialEventTimeSpacing);

class Analytics {
  private echo;
  private player;
  private startTime;

  constructor() {
    this.echo = new EchoClient(AppName, Enums.ApplicationType.WEB, {
      [ConfigKeys.DEBUG_MODE]: DebugMode,
      [ConfigKeys.DESTINATION]: Destination
    });
    this.echo.setAppVersion(version);
  }

  private setMediaPlayerAttributes = () =>{
    var mediaPlayer = new Echo.MediaPlayer();
    this.echo.setMediaPlayer(mediaPlayer);
    this.echo.setPlayerName(AppName);
    this.echo.setPlayerVersion(version);
  }

  private getCustomOrgName = (provider) =>{
    switch (User.providerCountry()){
      case "MY":
        return (provider === "BBC_ASTRO") ? CustomOperatorName.astro : CustomOperatorName.tm;
      case "SG":
        return (provider === "STARHUB") ? CustomOperatorName.starhub : CustomOperatorName.singtel;
      default:
        return provider;
    }
  }

  private setAccountOrgnaisation =()=>{
    if (User.authenticated() && User.id()){
      try {
        this.echo.addProperty(CustomOperatorName.accOrg, this.getCustomOrgName(User.provider()));
      }catch(err) { 
        console.log(err) 
      }
    }else{
      try {
        this.echo.removeProperty(CustomOperatorName.accOrg);
        this.echo.removeProperty('user_id');
      }catch(err) { 
        console.log(err) 
      }
    }
  }

  private setAvChannel =(isLiveVideo, channel = "")=>{
    if (isLiveVideo){
      try {
        this.echo.addProperty("av_channel", channel);
      }catch(err) { 
        console.log(err) 
      }
    }else{
      try {
        this.echo.removeProperty("av_channel");
      }catch(err) { 
        console.log(err) 
      }
    }
  }

  // STANDARD EVENTS

  private userActionEvent = (actionName: string, type: ActionType, eventType: EventType = EventType.Click, eventLabels = {}, includeStandardLabels = true) => {
    eventLabels[Label.IsBackground] = Boolean(eventType);
    if(includeStandardLabels) eventLabels[Label.AdvertiserId] = AdvertiserId;
    const actionType = type !== ActionType.None && type;
    this.setAccountOrgnaisation();
    try { this.echo.userActionEvent(actionType, actionName, eventLabels);}
    catch(err) { console.log(err) }
  }

  private pageViewEvent = async (page: String, contentType: ContentType, metadata = {}) => {
    metadata = {
      ...metadata,
      [LabelKeys.LANGUAGE]: Language,
      [LabelKeys.CONTENT_TYPE]: contentType,
      [LabelKeys.AV_CONTENT_TYPE]:contentType,
      [LabelKeys.CUSTOM_VAR_1]: await getUserStatus(),
      "product_platform": Enums.ApplicationType.WEB,
      [LabelKeys.CUSTOM_VAR_3]: getStorageItem("operator", true)
};
    this.setAccountOrgnaisation()
    try { this.echo.viewEvent(page, metadata) }
    catch(err) { console.log(err) }
  }

  private setCounterName = (name) => {
    try { this.echo.setCounterName(name) }
    catch(err) { console.log(err) }
  }

  // USER STATUS EVENTS

  userStatusLoggedIn = (id) => {
    this.echo.setLoggedInToBBCId(id);
  }

  userStatusLoggedOut = () => {
    this.echo.setLoggedOutOfBBCId();
  }

  // PAGE VIEW EVENTS
  pageViewHome = () => {
    this.pageViewEvent(getAnalyticsPage(), ContentType.Home);
  }

  pageViewBasic = () => {
    this.pageViewEvent(getAnalyticsPage(), ContentType.Admin);
  }

  pageViewChannel = (channel) => {
    this.pageViewEvent(getAnalyticsPage(), ContentType.Channel, {
      [LabelKeys.CHAPTER_1]: channel
    });
  }

  pageViewCategory = (category) => {
    this.pageViewEvent(getAnalyticsPage(), ContentType.Category, {
      [LabelKeys.CHAPTER_1]: category
    });
  }

  pageViewList = () => {
    this.pageViewEvent(getAnalyticsPage(), ContentType.List);
  }

  pageViewSettings = () => {
    this.pageViewEvent(getAnalyticsPage(), ContentType.Account);
  }

  pageViewPinSuccess = () => {
    this.pageViewEvent("player.settings_pin_success.page", ContentType.Account);
  }

  pageViewSignIn = () => {
    this.pageViewEvent("player.sign_in.page", ContentType.Account);
  }

  pageViewTvGuide = () => {
    this.pageViewEvent("player.tvguide.page", ContentType.TvGuide);
  }

  pageViewLivePlayer = () => {
    this.pageViewEvent("player.live.page", ContentType.LivePlayer);
  }

  private pageViewSubscribed = () => {
    this.pageViewEvent("player.signed_in.page", ContentType.Account);
  }

  private pageViewTrialStarted = () => {
    this.pageViewEvent("player.free_trial_start.page", ContentType.Account);
  }

  private pageViewTrialEnding = () => {
    this.pageViewEvent("player.free_trial_ending.page", ContentType.Account);
  }

  pageViewSearch = () => {
    this.pageViewEvent("player.search.page", ContentType.Account);
  }

  pageViewSeries = (series, season) => {
    const { extId, seasonNumber } = season;
    const seriesName = analyticsSeriesName(series.path);

    this.pageViewEvent(getAnalyticsPage(), ContentType.Brand, {
      [LabelKeys.SECTION]: `${seriesName}::s${seasonNumber}`,
      [LabelKeys.CONTENT_ID]: extId || "",
    });
  }

  private pageViewEpisodePlayback = async (episode, isLive) => {
    const { extId, series, episodeNumber, seasonNumber, seriesNumber } = episode;
    const seriesName = isLive ? episode.name.toLowerCase() : analyticsSeriesName(series.path);
    const page = `player.${seriesName}.season_${seasonNumber || seriesNumber}.episode_${episodeNumber}.page`;

    await this.pageViewEvent(page, ContentType.Episode, {
      [LabelKeys.CHAPTER_1]: isLive ? episode.name : series.name,
      [LabelKeys.SECTION]: `${seriesName}::s${seasonNumber || seriesNumber}::e${episodeNumber}`,
      [LabelKeys.CONTENT_ID]: extId || "",
      [LabelKeys.AV_BROADCASTING_TYPE]: isLive ? Enums.avBroadcastingType.LIVE: Enums.avBroadcastingType.CLIP,
    });
  }

  // USER ACTION EVENTS

  userActionSelectContentFromRail = (title, position, url) => {
    const labels = {
      [Label.CampaignID]: CampaignID.Application,
      [Label.Format]: `POS=${position}`,
      [Label.Url]: url
    };
    this.userActionEvent(title, ActionType.Module, EventType.Click, labels, true);
  }

  userActionPlayContent = async (episode, isLive = false) => {
    this.startTime = episode.startTime_utc
    await this.pageViewEpisodePlayback(episode, isLive)

    wait(() => {
      const { extId, name } = episode;
      const labels = {
        [Label.CampaignID]: CampaignID.Player,
        [Label.Url]: `${extId}:${name}`
      }
      this.userActionEvent("play", ActionType.Select, EventType.Click, labels, true);
    });
  }

  userActionSelectContentFromList = (position, url) => {
    const labels = {
      [Label.CampaignID]: CampaignID.Page,
      [Label.Format]: `ROW=${position}`,
      [Label.Url]: url // TODO: Ask. Docs say <link_text>
    }
    this.userActionEvent("contentItem", ActionType.Select, EventType.Click, labels, true);
  }

  userActionTrialProgressAlert = (remainingNumDays = 0) => {
    this.pageViewTrialEnding();

    wait(() => {
      const name = `free_trial_ending_${remainingNumDays}`;
      const labels = {
        [Label.CampaignID]: CampaignID.Application
      };
      this.userActionEvent(name, ActionType.None, EventType.Impression, labels);
    });
  }

  userActionTrialStarted = () => {
    this.pageViewTrialStarted();

    wait(() => {
      const labels = {
        [Label.CampaignID]: CampaignID.Application,
        [Label.Url]: "free_trial_success"
      };
      this.userActionEvent("free_trial_success", ActionType.None, EventType.Impression, labels, true);
    });
  }

  userActionSubscribedFromTrial = (remainingNumDays = 0) => {
    this.pageViewTrialEnding();

    wait(() => {
      const name = `free_trial_ending_${remainingNumDays}`;
      const labels = {
        [Label.CampaignID]: CampaignID.Application,
        [Label.Url]: "subscribe_now"
      };
      this.userActionEvent(name, ActionType.None, EventType.Click, labels, true);
    });
  }

  userActionSignedIn = () => {
    this.pageViewHome();

    wait(() => {
      const labels = {
        [Label.CampaignID]: CampaignID.Application
      };
      this.userActionEvent("sign-in", ActionType.UserStateChange, EventType.Impression, labels, true);
    });
  }

  userActionSignInError = (errorCode, errorMessage) => {
    this.pageViewHome();

    wait(() => {
      const labels = {
        [Label.CampaignID]: CampaignID.Error,
        [Label.Url]: `${errorCode}:${errorMessage}`
      }
      this.userActionEvent("sign-in", ActionType.Error, EventType.Impression, labels, true);
    });
  }

  userActionSignInSelection = (text) => {
    const name = text.replace(/ /g, "_").replace(/starhub/gi, "starhub");
    const labels = {
      [Label.CampaignID]: CampaignID.Application,
      [Label.Url]: name
    };
    this.userActionEvent(name, ActionType.Select, EventType.Click, labels);
  }

  userActionSubscribed = () => {
    this.pageViewSubscribed();

    wait(() => {
      const labels = {
        [Label.CampaignID]: CampaignID.Application,
        [Label.Url]: "subscribe_success"
      };
      this.userActionEvent("subscribe_success", ActionType.None, EventType.Impression, labels, true);
    });
  }

  userActionAdvancedToNextEpisode = (episode) => {
    this.setCounterName("next episode");

    wait(() => {
      const { extId, name } = episode;
      const labels = {
        [Label.CampaignID]: CampaignID.Player,
        [Label.Url]: `${extId}:${name}`
      };
      this.userActionEvent("autoplay", ActionType.Next, EventType.Click, labels, true);
    });
  }

  userActionWatchedContentPastThreshold = (episode) => {
    const { extId, name } = episode;
    const labels = {
      [Label.CampaignID]: CampaignID.Player,
      [Label.Url]: `${extId}:${name}`
    };
    this.userActionEvent("play_90", ActionType.Complete, EventType.Click, labels, true);
  }

  userActionRecommendationsImpression = (event_title, position) => {
    const labels = {
      [Label.CampaignID]: CampaignID.Page,
      [Label.Format]: `POS=${position}`,
      [Label.Url]: event_title
    }
    this.userActionEvent("Recommended for you", ActionType.Module, EventType.Impression, labels, true);
  }

  userActionSelectRecommendationFromRail = (position, url) => {
    const labels = {
      [Label.CampaignID]: CampaignID.Page,
      [Label.Format]: `POS=${position}`,
      [Label.Url]: url
    };
    this.userActionEvent("Recommended for you", ActionType.Module, EventType.Click, labels, true);
  }

  // MEDIA EVENTS

  private getPlayPosition = () => this.player && this.player.currentTime() * 1000;

  private getLivePlayPosition = (starttime) => {
    return (new Date().getTime() - starttime);
  }

  mediaReportInit = (episode, player, isLive = false) => {
    this.setMediaPlayerAttributes();
    const { extId, series, episodeNumber, seasonNumber, seriesNumber, duration, channelTitle } = episode;
    const seriesName = isLive ? episode.name.toLowerCase() : analyticsSeriesName(series.path);
    this.setAvChannel(isLive, channelTitle);
    const media = new Media(Enums.AvType.VIDEO, isLive ? Enums.MediaConsumptionMode.LIVE :Enums.MediaConsumptionMode.ON_DEMAND);
    media.setEpisodeId(extId);
    media.setAppName(AppName);
    media.setAppType(Enums.ApplicationType.WEB);
    media.setName(`${seriesName}.s${seasonNumber || seriesNumber}.e${episodeNumber}`);
    media.setProducer(Enums.Producers.BBC_STUDIOS);
    media.setLength(isLive ? (duration * 60 * 1000) : (player.duration() * 1000));

    const playerDelegate = {
      getPosition: () => (isLive ? this.getLivePlayPosition(episode.startTime_utc) : (this.player && this.player.currentTime() * 1000) || ""),
      getTimestamp: () => (new Date()).getTime()
    };

    this.player = player;
    this.echo.setPlayerDelegate(playerDelegate);
    this.echo.setMedia(media);
  }

  mediaReportPlay = (isLive = false) => {
    if(!this.player) return;
    this.echo.avPlayEvent( isLive ? this.getLivePlayPosition(this.startTime) : this.getPlayPosition());
  }

  mediaReportPause = (isLive = false) => {
    if(!this.player) return;
    this.echo.avPauseEvent( isLive ? this.getLivePlayPosition(this.startTime) : this.getPlayPosition());
  }

  mediaReportMove = () => {
    if(!this.player) return;
    this.echo.avSeekEvent(this.getPlayPosition());
  }

  mediaReportEnd = (isLive = false) => {
    if(!this.player) return;
    this.setAvChannel(false);
    this.echo.avEndEvent( isLive ? this.getLivePlayPosition(this.startTime) : this.getPlayPosition());
    wait(this.mediaReportStop);
  }

  mediaReportStop = () => {
    if(!this.player) return;
    this.player = undefined;
    this.setAvChannel(false);
    wait(() => {
      this.echo._clearMedia();
    })
  }
};

const analytics = new Analytics();

// if(process.env.NODE_ENV !== "production") {
  (window as any).analytics = analytics;
  (window as any).user = User;
// }


export default analytics;

import React, {useState} from 'react';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer"
import { StatusModals } from "./components/Modals";
import Home from "./routes/Home/Home";
import Series from "./routes/Series/Series";
import List from "./routes/List/List";
import Settings from "./routes/Settings/Settings";
import Category from "./routes/Category/Category";
import TvGuide from "./routes/TvGuide/TvGuide";
import Channel from "./routes/Channel/Channel";
import Authentication from "./routes/Authentication/Authentication";
import ScrollDetector from "./components/ScrollDetector/ScrollDetector";
import ErrorPortal from "./components/Error/Error";
import Terms from "./routes/Basic/Terms";
import FAQ from "./routes/Basic/FAQ";
import About from "./routes/Basic/About";
import { Provider } from "react-redux";
import createStore, { withStore } from "./store";
import { Constants } from "./utilities";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  Redirect,
} from "react-router-dom";
import classnames from "classnames";
import { compose } from "redux";
import './App.scss';
import Banner from './components/Banner/Banner';

const store = createStore();

class _AppRouter extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      path: this.classFromPath(props.location.pathname),
      isEnable: false,
      appBodyClass: "app-body"
    };
  }

  enableAbroadBanner = () => {
    const { store: { isAbroad } } = this.props;

    if(isAbroad){
      this.setState({
        isEnable: true
      }, () => console.log("isEnable value => ", this.state.isEnable));      
      window.scrollTo(0, 0);//For scroll to the top
      setTimeout(() => {
        this.setState({
          isEnable: false
        }, () => console.log("SETTIMEOUT REACHED FOR REMOVING BANNER IMAGE=> "));
      }, 3000)
    }else{
      this.setState({
        isEnable: false
      }, () => console.log("isEnable value => ", this.state.isEnable));
    }    
  
  }


  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.shouldScroll(prevProps.location.pathname, this.props.location.pathname))
        window.scrollTo(0, 0);
      this.setState({ path: this.classFromPath(this.props.location.pathname) })
    }
    if (!this.props.onDesktop) {
      this.initBanner();
    }
  }

  shouldScroll = (prevPathname: string, pathname: string) => {
    if (!pathname.startsWith("/en/brand/"))
      return true;

    const prevSeries = prevPathname.match(/^\/en\/brand\/[a-z-]+/);
    const currSeries = pathname.match(/^\/en\/brand\/[a-z-]+/);

    return prevSeries && currSeries && prevSeries[0] !== currSeries[0];
  }

  classFromPath = (pathname) => {
    return ((pathname.replace("/en", "").match(/\/[a-zA-Z-]*(\/)*/g) || "")[0] || "").replace(/\//g, "") || "homepage"
  }

  initBanner = () => {
    if (document.querySelector("#branch-banner-iframe"))
      return;
    const branch = (window as any).branch;
    branch.init(process.env.REACT_APP_BRANCH_KEY, {}, () => {
      branch.addListener('didCloseJourney', function (event, data) {
        if ((window as any).location.search.includes(`${process.env.REACT_APP_BANNER_ID}`)) {
          (window as any).history.go(-1)
        }
      });
    });
  }

  appBodyClassName = (className) => { 
    if (className) {
      this.setState({ appBodyClass: className });
    } else {
      this.setState({ appBodyClass: "app-body" });
    }
  }

  render() {
    const { path, isEnable } = this.state;
    const { store: { onDesktop, isAbroad, country }, setIsAbroadFlag } = this.props;
    const scrollSelector = onDesktop ? undefined : "#root";

    return (
      <React.Fragment>
        <ScrollDetector continuousUpdates selector={scrollSelector}>
          {(scrollingUp, scrollingDown) => (
            <div className={classnames("App", path, { "mobile": !onDesktop, "scroll-up": scrollingUp, "scroll-down": scrollingDown })}>
              <Header enableAbroadBanner={this.enableAbroadBanner} appBodyClassName={this.appBodyClassName} />
              { window.innerWidth > Constants.MobileWidth && this.state.appBodyClass && this.state.appBodyClass.includes('submenu') && 
              <div className={classnames('app-body-overlay', {live:this.state.appBodyClass.includes('live')}) } ></div>}
              <div className={this.state.appBodyClass}>
                {isEnable && <Banner />}
                <Switch> 
                  <Route path="/en/live/guide/:path" exact render={() => <TvGuide enableAbroadBanner={this.enableAbroadBanner} isAbroad={isAbroad} />} />  
                  <Route path={["/en", "/en/categories", "/en/channel"]} exact render={() => <Home enableAbroadBanner={this.enableAbroadBanner} />} />
                  {/* <Route path={["/en", "/en/categories", "/en/channel"]} exact component={Home} /> */}
                  <Route path="/en/category/:path*" exact render={() => <Category enableAbroadBanner={this.enableAbroadBanner} />} />
                  <Route path="/en/categories/:path*" exact component={Category} />
                  <Route path="/en/channel/:path*" exact render={() => <Channel enableAbroadBanner={this.enableAbroadBanner} />} />
                  <Route path="/en/channels/:path*" exact component={Channel} />
                  <Route path="/en/watchlist" exact component={Home} />
                  <Route path="/en/viewall/:id" exact render={() => <List enableAbroadBanner={this.enableAbroadBanner} />}/>
                  <Route path="/en/brand/:seriesPath/:seasonPath?" component={Series} />
                  {/* <Route path="/en/brand/:seriesPath/:seasonPath?" render={() => <Series enableAbroadBanner={this.enableAbroadBanner} />} />                   */}
                  <Route path="/en/settings" exact component={Settings} />
                  <Route path="/authentication/upgrade/web/callback" exact component={Authentication} />
                  <Route path="/en/terms-and-conditions" component={Terms} />
                  <Route path="/en/help-and-support" component={FAQ} />
                  <Route path="/en/about" component={About} />
                </Switch>
              </div>
              <Footer />
            </div>
          )}
        </ScrollDetector>
        <StatusModals />
        <ErrorPortal />
      </React.Fragment>
    )
  }
};
const AppRouter = compose<any>(withRouter, withStore)(_AppRouter);

function App() {
  return (
    <Router>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </Router>
  );
}

export default App;

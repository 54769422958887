import React from "react";
import { Link } from "react-router-dom";
import { Image } from "../../../assets/images";
import { getUrl } from "../../../utilities";
import { version } from '../../../../package.json';

const channels = [
  Image.BBCEarth,
  Image.BBCFirst,
  Image.BBCBrit,
  Image.BBCLifestyle,
  Image.CBeebies,
];

const About: React.FunctionComponent<any> = () => (
  <div className="About BasicPage">
    <div className="BasicPage__header">About BBC Player</div>
    <p>
    BBC Player service is an authenticated multi-genre SVOD (Subscription Video On Demand) streaming platform  that allows viewers to watch the very best of British programmes anytime, anywhere. It is available online (<a href="https://www.bbcplayer.com">www.bbcplayer.com</a>), and as an app on <a href="https://appstore.com/bbcplayer">Apple App Store</a> for iOS devices and <a href="https://play.google.com/store/apps/details?id=com.bbc.tve">Google Play Store</a> for Android devices.
    </p>

    <div className="BasicPage__header">What do I get ?</div>
    <div><u>On Demand Content</u></div>
    <p>As a multi-genre subscription service, BBC Players will allow viewers to stream a curated line-up of popular British programmes from world-class documentaries, premium British dramas, popular comedy and entertainment shows to aspirational lifestyle programming and engaging children’s shows.</p>
    <div><u>Live BBC TV Channels</u></div>
    <p>With BBC Player’s recent update, viewers with eligible Astro TV or Unifi TV subscriptions will also be able to watch the live TV channel for BBC Earth, BBC Lifestyle and CBeebies on BBC Player’s online site and app. </p>
  
  
    <div className="BasicPage__header">What are the BBC channels available on BBC Player? </div>
    <div className="About__channels">
    {
      channels.map((channel, index) => (<img key={index} src={channel} alt={`channel-${index}`} />))
    }
    </div>
    <p>
    <strong>BBC Earth</strong> showcases the work of the world’s foremost factual filmmakers. From the smallest creatures under the microscope to the limitless expanses of space, BBC Earth  takes viewers on a thrilling journey of discovery, come face-to-face with heart pounding action, mind-blowing ideas and the sheer wonder on this extraordinary planet we call home.
    </p><p>
    <strong>BBC First</strong> is the home of premium British drama. Showcasing world-renowned, award-winning talent in front of and behind the camera, it delivers audiences the first chance to see stories that challenge, immerse and entertain, all with a unique British perspective.
    </p><p>
    <strong>BBC Brit</strong> offers premium factual entertainment that is intelligent but irreverent. It blends expertise with entertainment and fact with fun. The channel covers a rich variety of subjects: cars, science, adventure, business, life-changing moments, extreme characters, quirky eccentrics and comedy. BBC Brit is available exclusively on BBC Player.
    </p><p>
    <strong>BBC Lifestyle</strong> is a unique entertainment destination brimming with vibrant, fresh and exciting programmes which set trends and guide viewers into transforming their world; from revamping the home to invigorating their culinary skills and helping them to become happier and get the best out of life.
    </p><p>
    <strong>CBeebies</strong> is loved by preschoolers under six, and by their parents and carers. Parents and carers trust CBeebies to deliver a safe, non-violent, interactive experience designed by developmental experts, and delivered by the trusted BBC brand, to promote imaginative play, social interaction, language skills and educational values.
    </p><p>
    You will need to have an eligible Astro TV or Unifi TV subscription in order to access BBC Player.
    </p><p>
    For more information, please refer to the <Link to={getUrl("/help-and-support")}>FAQs</Link>.
    </p>
    <p>Version : {version}</p>
  </div>
);

export default About;

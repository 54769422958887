import React from "react";
import ContentFetch from "../../components/Fetch/ContentFetch";
import ComponentList from "../../components/ComponentList/ComponentList";
import { Analytics } from "../../utilities";
import { Helmet } from "react-helmet";

const List: React.FunctionComponent<any> = ({ enableAbroadBanner }) => {
  return (
    <ContentFetch onComplete={Analytics.pageViewList}>
      {(errors, screen) => (
        (!screen || errors) ?
          null :
          <React.Fragment>
            <Helmet>
              <title>{(screen && `BBC Player | ${screen.name}`) || "BBC Player"}</title>
            </Helmet>
            <ComponentList components={screen.components} extraProps={{ category: screen.name }} enableAbroadBanner={enableAbroadBanner}/>
          </React.Fragment>
        )}
    </ContentFetch>
  )
};

export default List;


import React, {useState} from "react";
import { ImageWordButton } from "../Buttons";
import { Image } from "../../assets/images";
import { Constants, getUrl, getTime } from "../../utilities";
import Border from "../Border/Border";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import "./Live.scss";

const LiveTab: React.FunctionComponent<any> = ({ item, history, category, location, handleLiveClick, last}) => {

  const truncatelimit = window.innerWidth <= Constants.MobileWidth ? 100 : 180;
  const truncatedDesc = item.episodeSynopsis && item.episodeSynopsis.length > truncatelimit ?
  `${item.episodeSynopsis.substring(0,truncatelimit)}...`  : item.episodeSynopsis;
  const button = ["PT", "WL", "SH"];
  const [selected, setSelected] = useState("");

  const onclick = async (value) => {
    if( value === button[0] && item.__typename === "now") {
      setSelected(value);
      await handleLiveClick(item);
    } else if ( value === button[1] ) {
      setSelected(value);
      await handleLiveClick(item);
    }
    else if ( value === button[2] ) {
      setSelected(value);
      let path = location.pathname.split('/');
      path = path[path.length-1];
      history.push(getUrl(`${Constants.TvGuideTarget}${path}`));
    }
  }

  return (
    <div className={classnames("Live_Border", {last: last})} >
      <div className="Live_Card">
        <div className="Live_Heading">
          <div className="Live_Type">{`ON ${item.__typename.toUpperCase()} `}</div>
          <div className="Live_Time">{`- ${getTime(item)}`}</div>
        </div>
        <img className="Live_Divider" src={Image.PinkDivider} alt=""></img>
        <div onClick={()=>onclick(button[0])} 
          className={classnames("Live_PrgrammeTitle", {selected: selected === button[0], hover: item.__typename === "now"})} >
          <span>{item.name}</span>
        </div>
        <div className="Live_Episode">{item.__typename === "now" ? item.seriesNumber + " Season  |  " + item.episodeNumber + " Episodes" 
        : "Series " + item.seriesNumber + ": " + item.episodeNumber + ". " + item.episodeTitle}</div>
        {item.__typename === "now" && <div className="Live_Description"><span>{truncatedDesc}</span></div>}
        { item.__typename === "now" && 
          <ImageWordButton unselect={!(selected === button[1])} click={()=>onclick(button[1])} border 
            className={classnames('Live_Play_Button', {selected: selected === button[1]})} ></ImageWordButton> }
        { (window.innerWidth <= Constants.MobileWidth && item.__typename === "next") && <Border className="Live_BlackDivider"></Border>}
        { item.__typename === "next" && <ImageWordButton className={classnames('Live_Schedule_Button', {selected: selected === button[2]})} 
        click={()=>{onclick(button[2])}} schedule={category} ></ImageWordButton> }
      </div>
    </div>
  )
}

export default withRouter(LiveTab);

import React from "react";
import MultiLineDiv from "../MultiLineDiv/MultiLineDiv";
import { Link } from "react-router-dom";
import { getContentItemMetadata, withAuthorization, Analytics, getFullUrl } from "../../utilities";
import ProgressBar from "../ProgressBar/ProgressBar";
import { PlayImage, ReactImage } from "../ReactImage";
import "./Tile.scss";
import { withStore } from "../../store";

const AuthorizedMultiLineDiv = withAuthorization(MultiLineDiv);

const Tile: React.FunctionComponent<any> = ({ store, contentItem, ...props }) => {
  const { __typename, progress = -1, tile: { image } } = contentItem;
  const { header, meta1, meta2, url } = getContentItemMetadata(contentItem, "Tile");
  const { containerTitle, containerType, position, enableAbroadBanner } = props;
  
  const reportSelection = () => {
    console.log("__typename =>", __typename);
    if(__typename === "Episode"){
      enableAbroadBanner();
      console.log("Enabled banner component !!!");
    }else{
      console.log("__typename not Episode !!!", __typename)
    }
       
    switch(containerType) {
      case "carousel":
        Analytics.userActionSelectContentFromRail(containerTitle, position, getFullUrl(url));
        break;
      case "grid":
        Analytics.userActionSelectContentFromList(position, getFullUrl(url));
        break;
      default:
        break;
    }
  };

  return (
    <div className="Tile">
      <div className="Tile__image">
        {
          __typename === "Episode" ?
          <PlayImage src={image && image.url} contentItem={contentItem} onClick={reportSelection}/> :

          <Link to={url!} onClick={reportSelection}>
            <ReactImage src={image && image.url} />
          </Link>

        }
        { (progress !== null && progress >= 0) && <ProgressBar progress={progress} /> }
      </div>
      {
        __typename === "Episode" ?
        <AuthorizedMultiLineDiv
          contentItem={contentItem}
          className="Tile__header episode"
          maxLines={3}
          onClick={reportSelection}
        >
          {header}
        </AuthorizedMultiLineDiv> :

        <Link to={url!} onClick={reportSelection}>
          <MultiLineDiv className="Tile__header" maxLines={3}>{header}</MultiLineDiv>
        </Link>
      }
      <div className="Tile__meta1">{meta1}</div>
      <div className="Tile__meta2">{meta2}</div>
    </div>
  )
}

export default withStore(Tile);
